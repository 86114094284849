import { ANALYTICS_EVENTS, ROOT_PATH, SIGN_IN_PATH } from '../../utils/constants';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import Logo from '../../../assets/images/FullLogo.svg';
import SnackbarContext from '../../contexts/SnackbarContext';
import { persistUserCredentials } from '../../utils/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useHistory } from 'react-router-dom';
import { useOAuth2 } from '../../contexts/OAuth2Context';
import { useOAuth2Login } from './hooks/useOAuth2Login';
import { useStyles } from './styles';

export const OAuth2Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const { provider, code, error, called, setCalled } = useOAuth2();
  const { refreshProvider } = useCurrentUser();

  const { oAuth2Login } = useOAuth2Login({ provider, code });

  const shouldLogin = useMemo(() => code && !called && !error, []);

  const onError = useCallback(
    (error) => {
      setSnack({
        open: true,
        message: error,
        severity: 'error',
      });
      // Redirect to login page
      history.push(SIGN_IN_PATH);
    },
    [history, setSnack],
  );

  useEffect(async () => {
    if (shouldLogin) {
      const response = await oAuth2Login();
      setCalled(true);
      const { oAuth2Login: data } = response.data;

      if (data.errors) {
        onError(data.errors);
        return;
      }

      persistUserCredentials(
        data.credentials.accessToken,
        data.credentials.client,
        data.credentials.expiry,
        data.tokenType,
        data.authenticatable.email,
        data.authenticatable.id,
      );
      await refreshProvider();
      const route =
        history.location.state && history.location.state.from.pathname !== SIGN_IN_PATH
          ? history.location.state.from.pathname
          : ROOT_PATH;
      gtag('event', ANALYTICS_EVENTS.login, { userId: data.authenticatable.id });
      history.push(route);
    }
  }, []);

  if (error) {
    console.error('OAuth2 Provider Error:', error);
    onError('An error occurred while trying to log in. Please try again.');
    return null;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.login}>
        <Box sx={{ height: '100%' }}>
          <img
            className={classes.logo}
            src={Logo}
            alt="Welligence-Logo"
            width="200px"
            height="75px"
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 'calc(100% - 200px)' }}
          >
            <Typography variant="string">One moment, please...</Typography>
          </Box>
        </Box>
        <LinearProgress color="secondary" className={classes.footerProgress} />
      </Paper>
    </div>
  );
};
