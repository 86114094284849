import makeStyles from '@mui/styles/makeStyles';

export const usePageActionsStyles = makeStyles((theme) => ({
  buttonSection: {
    display: 'flex',
    flexShrink: 0,
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: '10px',
    rowGap: '1rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  iconSection: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    columnGap: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 0',
    },
  },
  button: {
    ...theme.palette.button,
    paddingLeft: '8px',
    paddingRight: '8px',
    lineHeight: 1,
    marginLeft: '10px',
    [theme.breakpoints.down('lg')]: {
      padding: '5px 10px',
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.baseColors.deepSeaNavy.c75,
    },
  },
  buttonOutlined: {
    border: '1px solid ' + theme.palette.baseColors.deepSeaNavy.c100,
    color: theme.palette.baseColors.deepSeaNavy.c100,
    paddingLeft: '8px',
    paddingRight: '8px',
    lineHeight: 1,
    marginLeft: '10px',
    [theme.breakpoints.down('lg')]: {
      padding: '5px 10px',
      marginLeft: 0,
    },
    '&:hover': {
      color: theme.palette.baseColors.deepSeaNavy.c75,
      borderColor: theme.palette.baseColors.deepSeaNavy.c75,
    },
  },
  buttonWithIcon: {
    padding: '1px 10px',
  },
  icon: {
    [theme.breakpoints.down('lg')]: {
      width: '24px',
      height: '24px',
    },
  },
  iconButton: {
    padding: 0,
    color: theme.palette.baseColors.deepSeaNavy.c100,
  },
  pageActions: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    columnGap: '.75rem',
  },
}));
