import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { FilterKeys } from '../../components/Filters';
import { MaFilterKeys } from './components/Filters/types';

const MaContext = createContext();

export const MA_CONTEXT = 'MA';
export const INITIAL_YEARS = [2010, new Date().getFullYear()];
const INITIAL_RANGE = [0, 100];

const INITIAL_VALUES = {
  [FilterKeys.assets]: [],
  [FilterKeys.basins]: [],
  [FilterKeys.countries]: [],
  [FilterKeys.companiesBuyer]: [],
  [FilterKeys.companiesSeller]: [],
  [FilterKeys.eventType]: [],
  [FilterKeys.hydrocarbons]: [],
  [FilterKeys.shoreStatuses]: [],
  [FilterKeys.developmentStatuses]: [],
  [MaFilterKeys.dates]: INITIAL_YEARS,
  [MaFilterKeys.valuation]: INITIAL_RANGE,
  [MaFilterKeys.reserves]: INITIAL_RANGE,
  [MaFilterKeys.production]: INITIAL_RANGE,
  [MaFilterKeys.emissions]: INITIAL_RANGE,
};

const getInitialFilters = () => {
  const sessionFilters = JSON.parse(sessionStorage.getItem(MA_CONTEXT) || '{}');
  const filtersToLoad = [
    FilterKeys.assets,
    FilterKeys.basins,
    FilterKeys.countries,
    FilterKeys.companiesBuyer,
    FilterKeys.companiesSeller,
    FilterKeys.eventType,
  ];

  return {
    ...INITIAL_VALUES,
    ...filtersToLoad.reduce((acc, key) => {
      acc[key] = sessionFilters[key] || INITIAL_VALUES[key];
      return acc;
    }, {}),
  };
};

const MaProvider = ({ children }) => {
  // start with no tab selected to avoid rendering the first tab by default
  const [currentTab, setCurrentTab] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [yearsRange, setYearsRange] = useState(INITIAL_YEARS);
  const [valuationRange, setValuationRange] = useState(INITIAL_RANGE);
  const [reservesRange, setReservesRange] = useState(INITIAL_RANGE);
  const [productionRange, setProductionRange] = useState(INITIAL_RANGE);
  const [emissionsRange, setEmissionsRange] = useState(INITIAL_RANGE);
  const [filters, setFilters] = useState(getInitialFilters());

  useEffect(() => {
    // remove advanced filters before saving values to session
    const filtersToSave = {
      [FilterKeys.assets]: filters[FilterKeys.assets],
      [FilterKeys.basins]: filters[FilterKeys.basins],
      [FilterKeys.countries]: filters[FilterKeys.countries],
      [FilterKeys.companiesBuyer]: filters[FilterKeys.companiesBuyer],
      [FilterKeys.companiesSeller]: filters[FilterKeys.companiesSeller],
      [FilterKeys.eventType]: filters[FilterKeys.eventType],
    };

    sessionStorage.setItem(MA_CONTEXT, JSON.stringify(filtersToSave));
  }, [filters]);

  const resetFilters = () => {
    setLoading(true);
    setFilters({
      ...INITIAL_VALUES,
      [MaFilterKeys.dates]: [Math.max(INITIAL_YEARS[0], yearsRange[0]), yearsRange[1]], // Keep 2010 as the default start year
      [MaFilterKeys.valuation]: valuationRange,
      [MaFilterKeys.reserves]: reservesRange,
      [MaFilterKeys.production]: productionRange,
      [MaFilterKeys.emissions]: emissionsRange,
    });
  };

  useEffect(() => {
    // Had to create a loading state to force the dateSlider component to re-render
    // when resetting the filters, otherwise the component was showing the old values,
    // even though the filters state was updated.
    // I think this is an issue with the library.
    // TODO: in the future, after updating MUI, we need to revisit this to see if it can be removed.
    setLoading(false);
  }, [filters]);

  const updateTab = (tag) => {
    setCurrentTab((previousTab) => {
      // if changing from/to opportunities tab to another tab, reset the filters
      if ((previousTab === 'opportunities' || tag === 'opportunities') && previousTab !== tag) {
        resetFilters();
      }
      return tag;
    });
  };

  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.[MaFilterKeys.dates] || [];
    const [minValuation, maxValuation] = filters?.[MaFilterKeys.valuation] || [];
    const [minReserves, maxReserves] = filters?.[MaFilterKeys.reserves] || [];
    const [minProduction, maxProduction] = filters?.[MaFilterKeys.production] || [];
    const [minEmissions, maxEmissions] = filters?.[MaFilterKeys.emissions] || [];

    // Extract the assets/blocks/fields ids from the assets filter
    const { assets, blocks, fields } = (filters[FilterKeys.assets] || []).reduce(
      (acc, entity) => {
        // If entity has no entityType, it's an asset
        if (!entity.entityType) acc.assets?.push(Number(entity.id));

        acc[`${entity.entityType}s`]?.push(Number(entity.entityId));
        return acc;
      },
      { assets: [], blocks: [], fields: [] },
    );

    return {
      assets: assets,
      blocks: blocks,
      fields: fields,
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      basins: filters[FilterKeys.basins]?.map((b) => Number(b.id)) || [],
      hydrocarbonTypes: [...new Set(filters[FilterKeys.hydrocarbons]?.map((h) => h.type))] || [],
      oilTypes: [...new Set(filters[FilterKeys.hydrocarbons]?.map((h) => h.oil))] || [],
      developmentStatuses: filters[FilterKeys.developmentStatuses]?.map((d) => Number(d.id)) || [],
      shoreStatuses: filters[FilterKeys.shoreStatuses]?.map((s) => Number(s.id)) || [],
      // Only filter if the value is different from the default
      startYear: startYear !== yearsRange[0] ? startYear : null,
      endYear: endYear !== yearsRange[1] ? endYear : null,
      minValuation: minValuation !== valuationRange[0] ? minValuation : null,
      maxValuation: maxValuation !== valuationRange[1] ? maxValuation : null,
      minReserves: minReserves !== reservesRange[0] ? minReserves : null,
      maxReserves: maxReserves !== reservesRange[1] ? maxReserves : null,
      minProduction: minProduction !== productionRange[0] ? minProduction : null,
      maxProduction: maxProduction !== productionRange[1] ? maxProduction : null,
      minEmissions: minEmissions !== emissionsRange[0] ? minEmissions : null,
      maxEmissions: maxEmissions !== emissionsRange[1] ? maxEmissions : null,
    };
  }, [filters]);

  return (
    <MaContext.Provider
      value={{
        currentTab,
        updateTab,
        filters,
        setFilters,
        resetFilters,
        yearsRange,
        setYearsRange,
        valuationRange: valuationRange,
        setValuationRange: setValuationRange,
        reservesRange,
        setReservesRange,
        productionRange,
        setProductionRange,
        emissionsRange,
        setEmissionsRange,
        loading,
        setLoading,
        refresh,
        setRefresh,
        filterVariables,
      }}
    >
      {children}
    </MaContext.Provider>
  );
};

const useMaContext = () => useContext(MaContext);

export { MaProvider, useMaContext };
