import Button from '@mui/material/Button';
import ErrorBoundary from '../../../components/ErrorBoundary';
import FilterItem from './filterItem';
import Grid from '@mui/material/Grid2';
import React from 'react';
// import { analytics, dimensions } from '../../../utils/analytics';
import { filterTypes } from './filterTypes';
import makeStyles from '@mui/styles/makeStyles';

// import { GA_DIMENSION, GA_PAGE } from '../constants';

const useStyles = makeStyles(() => ({
  root: {
    width: 'calc(100% - 20px)',
    margin: '10px',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 0,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}));

export default ({ filters, setFilters, clearFilters }) => {
  const classes = useStyles();

  const setFilter = (value, key) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
    // TODO Review analytics tracking if shiny downloads enabled
    // gtag(
    //   'event',
    //   ANALYTICS_EVENTS.event, {
    //      'event_category': GA_PAGE,
    //      'event_action': 'SingleFilter',
    //      'event_label': analytics({ [key]: newFilters[key] }),
    //      ...dimensions(newFilters, GA_DIMENSION),
    //    }
    // );
    // gtag(
    //   'event',
    //   ANALYTICS_EVENTS.event, {
    //     'event_category': GA_PAGE,
    //     'event_action': 'Filter',
    //     'event_label': analytics(newFilters),
    //     ...dimensions(newFilters, GA_DIMENSION),
    //    }
    // );
  };

  const downloadType = filters?.downloadType?.name;

  return (
    <ErrorBoundary>
      <Grid className={classes.root} container direction="row">
        {downloadType ? (
          filterTypes.map((filter, idx) => (
            <FilterItem
              key={`data-downloads-filter-${filter.name}`}
              filter={filter}
              propVariables={{ filters }}
              setFilter={setFilter}
              value={filters[filter.name]}
              firstItem={idx === 0}
            />
          ))
        ) : (
          <FilterItem
            key={`data-downloads-filter-downloadType`}
            filter={filterTypes[0]}
            propVariables={{ filters }}
            setFilter={setFilter}
            value={filters.downloadType}
            firstItem
          />
        )}
        {downloadType && (
          <Button className={classes.button} variant="contained" onClick={clearFilters}>
            Clear
          </Button>
        )}
      </Grid>
    </ErrorBoundary>
  );
};
