import React from 'react';
import { TableCell, TableRow, Box, Table } from '@mui/material';
import { useStyles } from './ParticipationHistoryTable.style';

const TABLE_HEADERS = ['Event', 'Date', 'Operator', 'Partners', 'Comments'];

const getDateNoTimezone = (date) => {
  const dateParsed = date.split('-');
  const year = dateParsed[0];
  const month = dateParsed[1] - 1;
  const day = dateParsed[2];
  return new Date(year, month, day);
};

const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  })
    .format(getDateNoTimezone(date))
    .replace(/ /, '-');
};

const getCompanyName = (participant, effectiveDate) => {
  const dates = Object.keys(participant.historicalNames).sort();
  if (dates.length === 0 || dates[dates.length - 1] <= effectiveDate) return participant.name;

  // Find the first date that is greater than the effective date
  const earliestDate = dates.find((date) => date > effectiveDate) || dates[dates.length - 1];

  return participant.historicalNames[earliestDate];
};

const getParticipant = (name, percent) => {
  if (name && percent) return `${name} (${percent.toFixed(2)}%)`;
  if (name) return name;
  return '';
};

export const ParticipationHistoryTable = ({
  historicalParticipation,
  moreParticipationThanDisplayed,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.participationTableBlock}>
      <Table className={classes.participationTable}>
        <TableRow className={classes.participationRow}>
          {TABLE_HEADERS.map((header) => (
            <TableCell className={classes.participationHeaderCell} key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
        {historicalParticipation.map((row) => (
          <TableRow className={classes.participationRow} key={row.date}>
            <TableCell className={classes.participationCell}>{row.eventType}</TableCell>
            <TableCell className={classes.participationCell}>
              {formatDate(row.effectiveDate)}
            </TableCell>
            <TableCell className={classes.participationCell}>
              {getParticipant(
                row.companyOperatorHistoricalNames ? getCompanyName(row.companyOperatorHistoricalNames[0], row.effectiveDate) : '',
                row.companyOperatorPercentStake,
              )}
            </TableCell>
            <TableCell className={classes.participationCell}>
              {row.partnerCompanies
                ?.map((p) => getParticipant(getCompanyName(p, row.effectiveDate), p.percentStake))
                ?.join(', ')}
            </TableCell>
            <TableCell className={classes.participationCell}>{row.comment}</TableCell>
          </TableRow>
        ))}
      </Table>
      {moreParticipationThanDisplayed && (
        <span>
          * Please refer to the Asset Report to access the comprehensive list of historical
          participation.
        </span>
      )}
    </Box>
  );
};
