import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import { analytics, dimensions } from '../../../utils/analytics';

import Button from '@mui/material/Button';
import ErrorBoundary from '../../../components/ErrorBoundary';
import FilterItem from './filterItem';
import { GA_PAGE } from '../constants';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { filterTypes } from './filterTypes';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.altButton,
    height: '100%',
    width: 'fit-content',
    minWidth: 'none',
  },
}));

export default ({ filters, setFilters, clearFilters }) => {
  const classes = useStyles();

  const setFilter = (value, key) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: GA_PAGE,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [key]: newFilters[key] }),
      userId: getUserId(),
      ...dimensions(newFilters, 'countryOverviews'),
    });
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: GA_PAGE,
      event_action: ANALYTICS_EVENT_ACTIONS.filter,
      event_label: analytics(newFilters),
      userId: getUserId(),
      ...dimensions(newFilters, 'countryOverviews'),
    });
  };

  return (
    <ErrorBoundary>
      <Grid
        container
        size={10}
        sx={{
          justifyContent: 'space-evenly',
        }}
      >
        {filterTypes.map((filter, idx) => (
          <FilterItem
            key={`core-filter-${filter.name}`}
            filter={filter}
            propVariables={{ filters }}
            setFilter={setFilter}
            value={filters[filter.name]}
            xs={idx === 0 ? 7 : 5}
          />
        ))}
      </Grid>
      <Grid
        direction="row"
        size={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <Button className={classes.button} variant="contained" onClick={clearFilters}>
          Clear
        </Button>
      </Grid>
    </ErrorBoundary>
  );
};
