import { MA_DEALS_QUERY } from '../operations';
import { useMaContext } from '../../../MaContext';
import { useQuery } from '@apollo/client';

export const useGetDeals = () => {
  const { filterVariables } = useMaContext();
  const { data, loading, error } = useQuery(MA_DEALS_QUERY, {
    variables: { filters: filterVariables },
  });

  return {
    data: data?.maDeals || [],
    loading,
    error,
  };
};
