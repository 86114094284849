import summaryItems, { PROJECT_KPI_LABEL } from './summaryItems';

import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import React from 'react';
import SummaryListItem from '../../../../components/SummaryList/summaryListItem';
import Typography from '@mui/material/Typography';
import { getLabelUnitValueFromTemplate } from '../../../../components/SummaryList/SummaryItems/utils';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  assetListItemChip: {
    backgroundColor: '#5f91cc',
    color: '#fff',
  },
  assetListItemChipContainer: {
    paddingLeft: '12px',
  },
  assetSummaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  assetSummaryColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    marginRight: '25px',
    '&:last-child': {
      marginRight: 0,
    },
    marginBottom: '40px',
  },
  assetSummarySubColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0',
  },
  assetSummaryItem: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  assetSummaryLabel: {
    whiteSpace: 'pre-line',
    color: '#507180',
  },
  companyLabel: {
    alignSelf: 'center',
  },
  participation: {
    flexDirection: 'row',
    display: 'flex',
  },
  projectKPIsLabel: {
    textTransform: 'none',
  },
}));

export default ({ asset, context, disabled }) => {
  const classes = useStyles();

  const MAndAIcon = ({ enabled }) => {
    if (enabled) {
      return (
        <div className={classes.assetListItemChipContainer}>
          <Chip className={classes.assetListItemChip} label="M&A" />
        </div>
      );
    } else {
      return null;
    }
  };

  const Item = ({ column }) => {
    const { label, unit, value, tooltip } = getLabelUnitValueFromTemplate(column, asset);
    const itemValue = disabled ? null : value;
    if (!itemValue) return null;
    return (
      <SummaryListItem
        Icon={MAndAIcon}
        label={label}
        value={itemValue}
        unit={unit}
        labelUnitSeparator={`\n`}
        labelValueSeparator={`\n`}
        tooltip={tooltip}
      />
    );
  };

  const SubCol = ({ columnName, subColumnName }) => (
    <div className={classes.assetSummarySubColumn}>
      <Typography
        variant="overline"
        // Specific treatment for project KPIs to escape uppercase text-transform
        className={subColumnName === PROJECT_KPI_LABEL && classes.projectKPIsLabel}
      >
        {subColumnName}
      </Typography>
      <Divider />
      {Object.keys(summaryItems[columnName].subColumns[subColumnName]).map((subColumn) => (
        <Item
          key={`${columnName}-${subColumn}`}
          column={summaryItems[columnName].subColumns[subColumnName][subColumn]}
        />
      ))}
    </div>
  );

  const Col = ({ columnName }) => (
    <div className={classes.assetSummaryColumn}>
      <Typography variant="overline">{columnName}</Typography>
      <Divider />
      {Object.entries(summaryItems[columnName]).map(([key, column]) => {
        if (key === 'subColumns') {
          return Object.keys(summaryItems[columnName].subColumns).map((subColumnName) => (
            <SubCol
              key={`${context}-${columnName}-${subColumnName}`}
              columnName={columnName}
              subColumnName={subColumnName}
            />
          ));
        } else {
          return <Item key={`${context}-${column.label}`} column={column} />;
        }
      })}
    </div>
  );

  return (
    <div className={classes.assetSummaryContainer}>
      {Object.keys(summaryItems).map((columnName) => (
        <Col key={`${context}-${columnName}`} columnName={columnName} />
      ))}
    </div>
  );
};
