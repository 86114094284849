import { OpportunitiesChart } from './components/OpportunitiesChart';
import { OpportunitiesTable } from './components/OpportunitiesTable';
import React from 'react';
import { useStyles } from './styles';

export const Opportunities = () => {
  const classes = useStyles();

  return (
    <div className={classes.graphWrapper}>
      <OpportunitiesChart />
      <OpportunitiesTable />
    </div>
  );
};
