import { MA_TRANSACTIONS_QUERY } from '../operations';
import { useMaContext } from '../../../MaContext';
import { useQuery } from '@apollo/client';

export const useGetMaTransactions = (page, perPage, order, orderBy) => {
  const { filterVariables } = useMaContext();
  const { data, loading, error } = useQuery(MA_TRANSACTIONS_QUERY, {
    variables: { filters: filterVariables, page: page + 1, perPage, order, orderBy },
  });

  return {
    data: data?.maTransactions?.transactions || [],
    totalCount: data?.maTransactions?.totalCount || undefined,
    loading,
    error,
  };
};
