import React, { useContext, useEffect, useRef, useState } from 'react';

import { ASSET_QUERY } from '../AssetList/operations';
import { ASSET_TEMPLATE_CONTEXT } from '../constants';
import Driver from 'driver.js';
import Grid from '@mui/material/Grid2';
import Loading from '../../../components/Loading';
import Sidebar from '../Sidebar';
import SnackbarContext from '../../../contexts/SnackbarContext';
import ValuationReports from '../ValuationReports';
import WalkthroughContext from '../../../contexts/WalkthroughContext';
import { assetPageDriver } from '../driver';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPage,
    flexWrap: 'nowrap',
  },
  valuationsMain: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  valuationsCenterContainer: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    width: '80%',
    ...theme.palette.primaryBackground,
    ...theme.palette.scrollbar,
  },
  valuationsContainer: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    width: '20%',
    ...theme.palette.scrollbar,
  },
}));

const AssetTemplate = () => {
  const classes = useStyles();
  const valuationsRef = useRef();
  const { walkthrough, setWalkthrough } = useContext(WalkthroughContext);
  const { setSnack } = useContext(SnackbarContext);

  const [selectedAsset, setAsset] = useState(null);
  const { legacyId, countryIsoCode } = useParams();

  const { loading } = useQuery(ASSET_QUERY, {
    variables: { legacyId, countryIsoCode },
    errorPolicy: 'all',
    onCompleted: (data) => setAsset(data.asset),
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (selectedAsset && walkthrough) {
      const driver = new Driver({
        allowClose: false,
        onReset: () => setWalkthrough(false),
      });
      assetPageDriver(driver);
    }
  }, [selectedAsset, walkthrough]);

  return (
    <Grid className={classes.root} container direction="column">
      <div className={classes.valuationsMain}>
        <div ref={valuationsRef} className={classes.valuationsCenterContainer}>
          {loading ? (
            <Loading />
          ) : (
            <ValuationReports
              asset={selectedAsset}
              context={ASSET_TEMPLATE_CONTEXT}
              valuationsRef={valuationsRef}
              setSnack={setSnack}
            />
          )}
        </div>
        <div className={classes.valuationsContainer}>
          <Sidebar asset={selectedAsset} context={ASSET_TEMPLATE_CONTEXT} setSnack={setSnack} />
        </div>
      </div>
    </Grid>
  );
};

export default AssetTemplate;
