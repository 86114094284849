import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import ConfettiGenerator from 'confetti-js';
import Modal from '../../../components/Modals';
import Typography from '@mui/material/Typography';
import { USER_WELCOME_MUTATION } from './operations';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    ...theme.sizes.fullPage,
  },
  button: {
    ...theme.palette.button,
  },
  bulletPoints: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 0',
  },
  confetti: {
    width: '100%',
    height: '100%',
  },
  modal: {
    maxHeight: '550px',
    maxWidth: '800px',
    height: '90%',
    width: '90%',
  },
  modalContent: {
    boxSizing: 'border-box',
    margin: '15px 0',
    padding: '0 50px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    ...theme.palette.scrollbar,
  },
}));

export default ({ refreshProvider, setWalkthrough }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [seenWelcome] = useMutation(USER_WELCOME_MUTATION, {
    onCompleted: () => {
      refreshProvider();
    },
  });

  const handleClose = () => {
    seenWelcome();
    setOpen(false);
    setWalkthrough(true);
  };

  useEffect(() => {
    const confettiSettings = { target: 'confetti' };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []);

  if (open)
    return (
      <div className={classes.root}>
        <Modal
          title="CONGRATULATIONS - You’ve been upgraded!"
          styles={classes}
          open={open}
          handleClose={() => setOpen(false)}
        >
          <div className={classes.modalContent}>
            <Typography variant="caption">
              Welcome to the new Welligence platform. This new user experience has been designed in
              direct response to client feedback. Here’s what to expect:
            </Typography>
            <div className={classes.bulletPoints}>
              <Typography variant="caption">• Enhanced (even easier) navigation.</Typography>
              <Typography variant="caption">• Targeted content retrieval.</Typography>
              <Typography variant="caption">
                • Greater and easier aggregation of portfolio data.
              </Typography>
              <Typography variant="caption">
                • A new, improved, and insanely fast mapping interface.
              </Typography>
              <Typography variant="caption">• Cross-country analysis.</Typography>
              <Typography variant="caption">
                • Efficiency! Less time finding and more time analyzing.
              </Typography>
            </div>
            <Typography variant="caption">
              While we’ve worked hard to make the platform an intuitive experience, we encourage you
              to reach out for an analyst led platform overview focused around getting the most out
              of these new features. As always, we appreciate you and thank you for your
              partnership.
            </Typography>
            <Typography variant="overline" style={{ margin: '15px 0' }}>
              The Welligence Team
            </Typography>
            <Button className={classes.button} onClick={handleClose}>
              Start Tour
            </Button>
          </div>
        </Modal>
        <canvas className={classes.confetti} id="confetti"></canvas>
      </div>
    );
  else return null;
};
