import React, { useContext, useState } from 'react';

import Grid from '@mui/material/Grid2';
import Modal from '../../../../components/Modals';
import MonetaryLineGraph from './monetaryLineGraph';
import MonetaryLineGraphContext from './MonetaryLineGraphContext';
import ProductionLineGraph from './productionLineGraph';
import ProductionLineGraphContext from './ProductionLineGraphContext';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  graph: {
    display: 'flex',
    flex: '1 1 auto',
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
  half: {
    height: '50%',
  },
}));

export default ({ portfolios, consolidateAssetsProps }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    range: prodRange,
    modalRange: prodModalRange,
    setModalRange: prodSetModalRange,
  } = useContext(ProductionLineGraphContext);
  const {
    range: cashRange,
    modalRange: cashModalRange,
    setModalRange: cashSetModalRange,
  } = useContext(MonetaryLineGraphContext);

  const closeFullscreen = () => {
    setOpen(false);
    prodSetModalRange({ x: [], y: [] });
    cashSetModalRange({ x: [], y: [] });
  };

  return (
    <>
      <Grid container columnSpacing={2} spacing={2} size={12}>
        <Grid id="portfolio-analysis-production-line-graphs" size={6}>
          <ProductionLineGraph
            open={open}
            setOpen={() => setOpen(true)}
            range={prodRange}
            appBarId="portfolio-analysis-graph-app-bar"
          />
        </Grid>
        <Grid id="portfolio-analysis-cashflow-line-graphs" size={6}>
          <MonetaryLineGraph
            open={open}
            setOpen={() => setOpen(true)}
            range={cashRange}
            consolidateAssetsProps={consolidateAssetsProps}
            portfolios={portfolios}
          />
        </Grid>
      </Grid>
      <Modal
        handleClose={closeFullscreen}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose={true}
        styles={classes}
        disablePortal
      >
        <Grid container className={classes.root}>
          <Grid container className={classes.graph} direction="column">
            <div className={classes.half}>
              <ProductionLineGraph
                open={open}
                setClose={closeFullscreen}
                showLegend
                range={prodModalRange}
              />
            </div>
            <div className={classes.half}>
              <MonetaryLineGraph
                open={open}
                setClose={closeFullscreen}
                showLegend
                range={cashModalRange}
                consolidateAssetsProps={consolidateAssetsProps}
                portfolios={portfolios}
              />
            </div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
