import PipelineThroughputGraphs from '../../../pages/OilAndGasMap/Info/graphs/pipelineThroughputGraphs';
import React from 'react';
import { formatDateShort } from '../../../utils/helpers';

export const PIPELINE_NAME_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Common Pipeline Name',
  accessor: (p) => p?.pipeline?.name,
};

export const OPERATOR_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Operator',
  accessor: (p) => p?.pipeline?.operator,
};

export const STATUS_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Status',
  accessor: (p) => p?.pipeline?.status?.name,
};

export const DIAMETER_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Diameter',
  unit: '(inches)',
  accessor: (p) => p?.pipeline?.formattedDiameterInches,
};

export const HYDROCARBON_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Hydrocarbon',
  accessor: 'hydrocarbon',
};

export const PRODUCTION_DATE_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Most Recent Date of Production',
  accessor: (p) => formatDateShort(p?.mostRecentProductionDate),
};

export const THROUGHPUT_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  label: 'Most Recent Throughput',
  unit: (p) => (p.hydrocarbon === 'Oil' ? '(bbl/d)' : '(MMcf/d)'),
  accessor: 'mostRecentPdp',
};

export const GRAPH_THROUGHPUT_PIPELINE_SEGMENT_SUMMARY_ITEM = {
  type: 'graph',
  accessor: (p) =>
    p.id && p.hasThroughputs ? <PipelineThroughputGraphs pipelineSegmentId={p.id} /> : null,
};
