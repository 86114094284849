import {
  ACREAGE_SUMMARY_ITEM,
  BLOCK_DISPLAY_NAME_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  PARTICIPATION_SUMMARY_ITEM,
} from '../../../../../../components/SummaryList/SummaryItems/base';

import { PARTICIPATION_LEASE_SUMMARY_ITEM } from '../../../../../../components/SummaryList/SummaryItems/lease';

export default (entityType) => [
  BLOCK_DISPLAY_NAME_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  ACREAGE_SUMMARY_ITEM,
  entityType === 'lease' ? PARTICIPATION_LEASE_SUMMARY_ITEM : PARTICIPATION_SUMMARY_ITEM,
];
