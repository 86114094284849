
import { useCallback } from 'react';
import { EXPORT_MA_OPPORTUNITIES_ENDPOINT } from '../../../operations';
import { useMaContext } from '../../../../../MaContext';
import { downloadXlsx } from '../../../../../../../components/Downloads/helpers';

export const useDownloadMaOpportunities = () => {
  const { filterVariables } = useMaContext();

  const downloadMaOpportunities = useCallback(async () => {
    const downloadName = `M_A_Opportunities_Data.xlsx`;
    const result = await downloadXlsx(
      EXPORT_MA_OPPORTUNITIES_ENDPOINT,
      filterVariables,
      downloadName,
    );

    let errors = [];
    if (!result.ok) {
      const json = await result.json();
      errors = json?.errors || [];
    }

    return { ok: result.ok, status: result.status, errors };
  }, [filterVariables]);

  return { downloadMaOpportunities };
};
