import { AutoComplete } from '../AutoComplete';
import { AutoCompleteExcelLike } from '../AutoComplete';
import React from 'react';
import VerticalChipList from '../ChipList/verticalChipList';
import clsx from 'clsx';
import { identifyChipLabel } from './utils';
import makeStyles from '@mui/styles/makeStyles';
import { Slider } from '../basic/Slider';
import { Box, Skeleton } from '@mui/material';

const useStyles = makeStyles(() => ({
  filterItemContainer: {
    height: '100%',
  },
  selects: {
    height: '36px',
    backgroundColor: '#fafafa',
    boxSizing: 'border-box',
    borderRadius: '5px',
    paddingLeft: 0,
  },
  selectLabel: {
    top: '-9px',
  },
  sliderWrapper: {
    paddingRight: '1rem',
    paddingLeft: '1.5rem',
    flex: 1,
  },
  slider: {
    width: '100%',
    height: '2px',

    '& .MuiSlider-thumb': {
      width: '10px',
      height: '10px',
    },
  },
  sliderLabel: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
}));

const SliderSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.slider}>
      <Box sx={{ marginTop: 1 }}>
        <Skeleton variant="rectangular" width={200} height={13} />
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <Skeleton variant="rectangular" width="100%" height={10} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
        <Skeleton variant="rectangular" width={50} height={10} />
        <Skeleton variant="rectangular" width={50} height={10} />
      </Box>
    </div>
  );
};

function getFilterElement(
  filter,
  setFilter,
  value,
  disabled,
  propVariables,
  classes,
  controlValues,
  context,
  inModal,
) {
  if (filter.type === 'slider') {
    return (
      <div className={classes.sliderWrapper}>
        {filter.loading ? (
          <SliderSkeleton />
        ) : (
          <Slider
            className={classes.slider}
            classNameLabel={classes.sliderLabel}
            label={filter.name}
            getAriaLabel={filter.getAriaLabel}
            value={value}
            onChange={filter.onChange}
            marks={filter.marks}
            min={filter.marks.length ? filter.marks[0].value : undefined}
            max={filter.marks.length ? filter.marks[filter.marks.length - 1].value : undefined}
            step={filter.step}
          />
        )}
      </div>
    );
  }
  if (filter.excelLike) {
    return (
      <AutoCompleteExcelLike
        getData={filter.query}
        menuItems={filter.menuItems}
        mapQueryData={filter.mapQueryData}
        groupOptionKey={filter.groupOptionKey}
        groupByName={filter.groupByName}
        groupSorter={filter.groupSorter}
        entitiesSorter={filter.entitiesSorter}
        onChange={(value) => setFilter(value, filter.key)}
        value={value}
        label={filter.name}
        disabled={disabled}
        optionKey={filter.optionKey}
        propVariables={propVariables}
        runQueryAfterOnChange={filter.runQueryAfterOnChange}
        queryVariable={filter.queryVariable}
        multiple={filter.multiple}
        styles={{ label: classes.selectLabel, input: classes.selects }}
        control={{
          ...filter.control,
          ...controlValues,
          onChange: (value, key) => setFilter(value, key),
        }}
        context={context}
        popoverHeightReduction={inModal ? '8vh' : undefined}
      />
    );
  }
  return (
    <AutoComplete
      getData={filter.query}
      menuItems={filter.menuItems}
      menuItemsCallback={filter.menuItemsCallback}
      onChange={(value) => setFilter(value, filter.key)}
      value={value}
      label={filter.name}
      optionKey={filter.optionKey}
      groupOptionKey={filter.groupOptionKey}
      searchKey={filter.searchKey}
      propVariables={propVariables}
      runQueryAfterOnChange={filter.runQueryAfterOnChange}
      queryVariable={filter.queryVariable}
      multiple={filter.multiple}
      styles={{
        label: classes.selectLabel,
        input: classes.selects,
      }}
      disabled={disabled}
      note={filter.note}
      responseCallback={filter.responseCallback}
      hideItem={filter.hideItem}
      control={{
        ...filter.control,
        ...controlValues,
        onChange: (value, key) => setFilter(value, key),
      }}
      context={context}
    />
  );
}

export default ({
  context,
  filter,
  setFilter,
  propVariables,
  withChipList,
  value = [],
  deleteFilter = () => {},
  disabled,
  controlValues = {},
  inModal,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, { [classes.filterItemContainer]: withChipList })}>
      {getFilterElement(
        filter,
        setFilter,
        value,
        disabled,
        propVariables,
        classes,
        controlValues,
        context,
        inModal,
      )}
      {withChipList && (
        <VerticalChipList
          chips={value.map((v) => ({
            parent: filter.key,
            label: identifyChipLabel(filter, v),
            value: v,
          }))}
          deleteChip={deleteFilter}
        />
      )}
    </div>
  );
};
