import {
  ABANDONMENT_LIABILITY_LEASE_SUMMARY_ITEM,
  BID_AMOUNT_LEASE_SUMMARY_ITEM,
  EFFECTIVE_DATE_LEASE_SUMMARY_ITEM,
  EXPIRATION_DATE_LEASE_SUMMARY_ITEM,
  GAS_PRODUCTION_LEASE_SUMMARY_ITEM,
  LIQUIDS_PRODUCTION_LEASE_SUMMARY_ITEM,
  NAME_LEASE_SUMMARY_ITEM,
  PARTICIPATION_LEASE_SUMMARY_ITEM,
  PRIMARY_TERM_LEASE_SUMMARY_ITEM,
  STATUS_LEASE_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/lease';
import {
  COUNTRY_SUMMARY_ITEM,
  WATER_DEPTH_M_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/base';

export default () => {
  return [
    NAME_LEASE_SUMMARY_ITEM,
    COUNTRY_SUMMARY_ITEM,
    WATER_DEPTH_M_SUMMARY_ITEM,
    PARTICIPATION_LEASE_SUMMARY_ITEM,
    ABANDONMENT_LIABILITY_LEASE_SUMMARY_ITEM,
    BID_AMOUNT_LEASE_SUMMARY_ITEM,
    EFFECTIVE_DATE_LEASE_SUMMARY_ITEM,
    PRIMARY_TERM_LEASE_SUMMARY_ITEM,
    EXPIRATION_DATE_LEASE_SUMMARY_ITEM,
    STATUS_LEASE_SUMMARY_ITEM,
    LIQUIDS_PRODUCTION_LEASE_SUMMARY_ITEM,
    GAS_PRODUCTION_LEASE_SUMMARY_ITEM,
  ];
};
