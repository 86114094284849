import { gql } from '@apollo/client';

export const GET_OAUTH2_PROVIDERS = gql`
  {
    oAuth2Providers {
      id
      name
      clientId
      redirectUri
      authUrl
      scope
    }
  }
`;
