import {
  COUNTRY_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
} from '../../../../../../components/SummaryList/SummaryItems/base';
import {
  DESIGN_WELL_SUMMARY_ITEM,
  DRILL_DAYS_WELL_SUMMARY_ITEM,
  DRILL_END_WELL_SUMMARY_ITEM,
  DRILL_START_WELL_SUMMARY_ITEM,
  NAME_WELL_SUMMARY_ITEM,
  RESULT_WELL_SUMMARY_ITEM,
  STATUS_WELL_SUMMARY_ITEM,
  WEA_WELL_SUMMARY_ITEM,
} from '../../../../../../components/SummaryList/SummaryItems/well';

export default [
  NAME_WELL_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
  WEA_WELL_SUMMARY_ITEM,
  RESULT_WELL_SUMMARY_ITEM,
  DESIGN_WELL_SUMMARY_ITEM,
  STATUS_WELL_SUMMARY_ITEM,
  DRILL_START_WELL_SUMMARY_ITEM,
  DRILL_END_WELL_SUMMARY_ITEM,
  DRILL_DAYS_WELL_SUMMARY_ITEM,
];
