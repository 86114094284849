import { DATA_DOWNLOADS_PATH, VALUATIONS_PATH } from '../../../../utils/constants';

import AssessmentIcon from '@mui/icons-material/Assessment';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import React from 'react';
import { ShinyLink } from '../../../../components/Shiny';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { isUserEntitled } = useCurrentUser();

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={2} />
      <Grid container spacing={2} size={8}>
        <Grid>
          <Button
            id="landing-page-valuations-button"
            component={Link}
            to={VALUATIONS_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(VALUATIONS_PATH)}
          >
            <AssessmentIcon fontSize="small" />
            &nbsp;valuations and reports
          </Button>
        </Grid>
        <Grid>
          <ShinyLink
            startAdornment={<WhatshotIcon fontSize="small" />}
            link={{ title: 'technical analysis', page: 'fields' }}
            styles={{ link: classes.button }}
            variant="contained"
            disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
