import React, { createContext, useContext, useEffect, useState } from 'react';

import { GET_OAUTH2_PROVIDERS } from './operations';
import { useQuery } from '@apollo/client';

const OAuth2Context = createContext();

export const MICROSOFT_PROVIDER_ID = 'entra_id';
export const OKTA_PROVIDER_ID = 'okta';

const OAUTH_PROVIDER = 'provider';
const OAUTH_STATE = 'state';
const OAUTH_CODE = 'code';
const OAUTH_ERROR = 'error';

const getUrlParams = (key) => {
  // Not sure why, but Microsoft sometimes sends the code in the hash and sometimes in the query string
  return (
    new URLSearchParams(window.location.hash.substring(1)).get(key) ||
    new URLSearchParams(window.location.search).get(key)
  );
};

const getInitialState = () => {
  return localStorage.getItem(OAUTH_STATE) || Math.random().toString(36).substring(2, 15); // generate a random state;
};

const OAuth2Provider = ({ children }) => {
  const { data } = useQuery(GET_OAUTH2_PROVIDERS);

  // for checking against CRSF
  const [state, setState] = useState(getInitialState());
  const [provider, setProvider] = useState(null);
  const [code, setCode] = useState(null);
  const [returnState, setReturnState] = useState(null);
  const [error, setError] = useState(null);

  const [called, setCalled] = useState(false);

  const refreshState = () => {
    const value = Math.random().toString(36).substring(2, 15); // generate a random state
    setState(value);
  };

  useEffect(() => {
    if (state) {
      // save to local storage
      localStorage.setItem(OAUTH_STATE, state);
    }
  }, [state]);

  useEffect(() => {
    // Refresh the state every 30 minutes
    const interval = setInterval(() => refreshState(), 30 * 60 * 1000); // 30 minutes
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // If there is no state set, don't bother looking for the code
    if (!state) return;

    // Look for the code and state in the URL
    const provider = getUrlParams(OAUTH_PROVIDER);
    const error = getUrlParams(OAUTH_ERROR);
    const code = getUrlParams(OAUTH_CODE);
    const returnState = getUrlParams(OAUTH_STATE);

    if (returnState !== state) {
      setError('Invalid state. Possible CSRF attack.');
      return;
    }

    if (provider) setProvider(provider);
    if (code) setCode(code);
    if (returnState) setReturnState(returnState);
    if (error) setError(error);

    // Update url to remove code and state, to prevent them from being used again
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  return (
    <OAuth2Context.Provider
      value={{
        providers: data?.oAuth2Providers || [],
        state,
        setState,
        provider,
        setProvider,
        code,
        setCode,
        returnState,
        setReturnState,
        error,
        setError,
        called,
        setCalled,
      }}
    >
      {children}
    </OAuth2Context.Provider>
  );
};

const useOAuth2 = () => useContext(OAuth2Context);

export { OAuth2Provider, useOAuth2 };
