import {
  CCUS_PATH,
  COUNTRY_OVERVIEWS_PATH,
  CUSTOMER_DOWNLOADS_PATH,
  DATA_DOWNLOADS_PATH,
  DEFAULT_PATH,
  GHG_PATH,
  M_A_BASIC_PATH,
  OIL_AND_GAS_MAP_PATH,
  OIL_AND_GAS_MAP_PATH_WITH_LATLONG,
  OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM,
  PORTFOLIO_ANALYSIS_PATH,
  RESERVES_DEFINITION_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  VALUATIONS_ASSET_PATH,
  VALUATIONS_ASSET_REPORT_PATH,
  VALUATIONS_PATH,
  VIDEO_TUTORIALS_PATH,
  WEA_ASSUMPTIONS_PATH,
} from './constants';

import AssetTemplate from '../pages/Valuations/AssetTemplate';
import CCUS from '../pages/CCUS';
import CountryOverviews from '../pages/CountryOverviews';
import GHG from '../pages/GHG';
import GuidanceDocumentation from '../pages/GuidanceDocumentation';
import LandingPage from '../pages/LandingPage';
import { MaPage } from '../pages/M&A';
import OilAndGasMap from '../pages/OilAndGasMap';
import PortfolioAnalysis from '../pages/PortfolioAnalysis';
import Settings from '../pages/Settings';
import ShinyDownloads from '../pages/ShinyDownloads';
import SubscriberDownload from '../pages/SubscriberDownload';
import Valuations from '../pages/Valuations';
import { VideoTutorials } from '../pages/VideoTutorials';
import { useCurrentUser } from '../contexts/CurrentUserContext';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';
import { useMemo } from 'react';

export const useProtectedRoutes = () => {
  const featureFlags = useFeatureFlags();
  const { currentUser } = useCurrentUser();

  const routes = [
    // all protected routes paths and components
    {
      path: ROOT_PATH,
      Page: LandingPage,
    },
    {
      path: CCUS_PATH,
      Page: CCUS,
      forceNewTab: true,
    },
    {
      path: GHG_PATH,
      Page: GHG,
      forceNewTab: true,
    },
    {
      path: PORTFOLIO_ANALYSIS_PATH,
      Page: PortfolioAnalysis,
      forceNewTab: true,
    },
    {
      path: OIL_AND_GAS_MAP_PATH,
      Page: OilAndGasMap,
      forceNewTab: true,
    },
    {
      path: OIL_AND_GAS_MAP_PATH_WITH_LATLONG,
      Page: OilAndGasMap,
    },
    {
      path: OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM,
      Page: OilAndGasMap,
    },
    {
      path: VALUATIONS_PATH,
      Page: Valuations,
      forceNewTab: true,
    },
    {
      path: VALUATIONS_ASSET_PATH,
      Page: AssetTemplate,
    },
    {
      path: VALUATIONS_ASSET_REPORT_PATH,
      Page: AssetTemplate,
    },
    {
      path: COUNTRY_OVERVIEWS_PATH,
      Page: CountryOverviews,
    },
    ...(featureFlags.inLocalEnv
      ? [
        {
          path: DATA_DOWNLOADS_PATH,
          Page: ShinyDownloads,
        },
      ]
      : []),

    {
      path: M_A_BASIC_PATH,
      Page: MaPage,
    },
    {
      path: CUSTOMER_DOWNLOADS_PATH,
      Page: SubscriberDownload,
    },
    {
      path: RESERVES_DEFINITION_PATH,
      Page: GuidanceDocumentation,
    },
    {
      path: WEA_ASSUMPTIONS_PATH,
      Page: GuidanceDocumentation,
    },
    {
      path: SETTINGS_PATH,
      Page: Settings,
    },
    {
      path: VIDEO_TUTORIALS_PATH,
      Page: VideoTutorials,
    },
  ];

  // The default route must be the last one in the array since the path is a catch-all route:
  routes.push({
    path: DEFAULT_PATH,
    Page: LandingPage,
  });

  return useMemo(() => routes, [featureFlags, currentUser]);
};
