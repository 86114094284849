import {
  CCUS_PATH,
  COUNTRY_OVERVIEWS_PATH,
  FEATURE_NOT_AVAILABLE_TOOLTIP,
  GHG_PATH,
  PORTFOLIO_ANALYSIS_PATH,
} from '../../../../utils/constants';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import LeafIcon from '../../../../components/Icons/LeafIcon';
import { Link } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PublicIcon from '@mui/icons-material/Public';
import React from 'react';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { isUserEntitled } = useCurrentUser();

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={2} />
      <Grid container spacing={2} size={8}>
        <Grid>
          <Button
            id="landing-page-country-overviews-button"
            component={Link}
            to={COUNTRY_OVERVIEWS_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(COUNTRY_OVERVIEWS_PATH)}
          >
            <PublicIcon fontSize="small" />
            &nbsp;country overviews
          </Button>
        </Grid>
        <Grid>
          <Button
            id="landing-page-portfolio-analysis-button"
            component={Link}
            to={PORTFOLIO_ANALYSIS_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(PORTFOLIO_ANALYSIS_PATH)}
          >
            <MenuBookIcon fontSize="small" />
            &nbsp;portfolio analysis
          </Button>
        </Grid>
        <Grid>
          <Button
            id="landing-page-ghg-button"
            component={Link}
            to={GHG_PATH}
            color="inherit"
            underline="none"
            variant="contained"
            className={classes.button}
            disabled={!isUserEntitled(GHG_PATH)}
          >
            <LeafIcon fontSize="small" />
            &nbsp;greenhouse gas analytics
          </Button>
        </Grid>
        <Grid>
          <Tooltip
            title={
              isUserEntitled(CCUS_PATH)
                ? 'Carbon capture, utilization, and storage (CCUS)'
                : FEATURE_NOT_AVAILABLE_TOOLTIP
            }
          >
            <span>
              <Button
                id="landing-page-ccus-button"
                component={Link}
                to={CCUS_PATH}
                color="inherit"
                underline="none"
                variant="contained"
                className={classes.button}
                disabled={!isUserEntitled(CCUS_PATH)}
              >
                <MenuBookIcon fontSize="small" />
                &nbsp;ccus
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
