import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  download: {
    marginLeft: theme.spacing(2), // Optional: Add spacing if needed
  },
  container: {
    flex: 1,
    position: 'relative',
    height: 'calc(100vh - 300px)',
    width: '100%',
    overflowX: 'auto',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.baseColors.cloudGray.c100}`,
    backgroundColor: theme.palette.baseColors.white,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    height: '1px',
    border: 'solid rgba(0, 0, 0, 0.12) thin',
  },
  tableWrapper: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    inset: 0,
  },
  table: {
    '&.MuiDataGrid-root': {
      marginBottom: theme.spacing(2),
      borderTopLeftRadius: 0,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle':
      {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1,
      },
    '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
      backgroundColor: theme.palette.baseColors.iceBlue.c50,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.baseColors.skyBlue.c25,
    },
    '&.MuiDataGrid-root .MuiDataGrid-overlay .MuiCircularProgress-root': {
      color: theme.palette.accentText.color,
    },
  },
}));
