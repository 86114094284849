import {
  GET_AVAILABLE_DOWNLOADS_QUERY,
  SECURE_COUNTRY_DOWNLOAD_QUERY,
} from '../../../components/Downloads/operations';
import React, { useContext, useEffect, useState } from 'react';
import {
  createCountryDownloadLinks,
  createCountryNewsLinks,
  createDeepDiveLinks,
  createDefaultLinks,
} from './utils';
import { useLazyQuery, useQuery } from '@apollo/client';

import ButtonList from '../../../components/ButtonList';
import { SHINY_URL } from '../../../components/Shiny/operations';
import SnackbarContext from '../../../contexts/SnackbarContext';

export default ({ country }) => {
  const [links, setLinks] = useState([]);
  const { setSnack } = useContext(SnackbarContext);

  const { data } = useQuery(GET_AVAILABLE_DOWNLOADS_QUERY, {
    variables: {
      countryIsoCode: country?.isoCode,
    },
  });

  const [getSecureCountryDownload] = useLazyQuery(SECURE_COUNTRY_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, downloadMessage } = data.secureCountryDownload;
      if (url) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        window.location.href = url;
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const [getShinyUrl] = useLazyQuery(SHINY_URL, {
    onCompleted: ({ shinyUrl }) => {
      window.open(shinyUrl.url, '_blank');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(async () => {
    const newLinks = [];
    const countryDownloadLinks = createCountryDownloadLinks(
      country,
      data?.availableCountryDownloads?.titles,
      getSecureCountryDownload,
    );
    if (countryDownloadLinks?.list?.length > 0) {
      newLinks.push(countryDownloadLinks);
    }

    const defaultLinks = createDefaultLinks(country, getShinyUrl);
    if (defaultLinks?.list?.length > 0) {
      newLinks.push(defaultLinks);
    }

    const countryNews = await createCountryNewsLinks(country);
    if (countryNews?.list?.length > 0) {
      newLinks.push(countryNews);
    }

    const deepDives = await createDeepDiveLinks(country);
    if (deepDives?.list?.length > 0) {
      newLinks.push(deepDives);
    }

    setLinks(newLinks);
  }, [country, data]);

  return <ButtonList items={links} />;
};
