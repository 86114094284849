import {
  DISPLAY_NAME_FIELD_SUMMARY_ITEM,
  HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM,
} from '../../../../../../components/SummaryList/SummaryItems/field';

import { COUNTRY_SUMMARY_ITEM } from '../../../../../../components/SummaryList/SummaryItems/base';

export default [
  DISPLAY_NAME_FIELD_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM,
];
