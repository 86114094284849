import {
  ADMINS_PATH,
} from './constants';

import { AdminsPage } from '../pages/Admins/AdminsPage';
import { useMemo } from 'react';
import { useAdminsPageOptions } from '../pages/Admins/AdminsPageItems';
import { useCurrentUser } from '../contexts/CurrentUserContext';

export const useAdminRoutes = () => {
  const { currentUser } = useCurrentUser();
  const adminsPageOptions = useAdminsPageOptions();

  const routes = [];

  if (currentUser?.admin) {
    routes.push({
      path: ADMINS_PATH,
      Page: AdminsPage,
    });
  }

  // Map over the options and return the path and page component.
  // Some options may have an iframeSrc, so we include that as well.
  // To add a new option, add it to the adminsPageOptions array.
  const options = adminsPageOptions.map((option) => ({
    path: option.route,
    Page: option.pageComponent,
    iframeSrc: option.iframeSrc,
  }));
  routes.push(...options);

  return useMemo(() => routes, [adminsPageOptions]);
};
