import Button from '@mui/material/Button';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  wider: {
    paddingLeft: '2.75rem',
    paddingRight: '2.75rem',
  },
  buttonsWrapper: {
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    padding: '10px 20px',
    borderTop: 'solid rgba(0, 0, 0, 0.12) thin',
  },
}));

export const CloseButtons = ({ apply, clear }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonsWrapper}>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" onClick={clear}>
          Clear
        </Button>
        <Button variant="contained" color="secondary" className={classes.wider} onClick={apply}>
          Apply
        </Button>
      </div>
    </div>
  );
};
