import { gql } from '@apollo/client';

export const OAUTH2_LOGIN_MUTATION = gql`
  mutation oAuth2Login($provider: String!, $code: String!) {
    oAuth2Login(provider: $provider, code: $code) {
      authenticatable {
        email
        id
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
      errors
    }
  }
`;
