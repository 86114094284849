import { PLOTLY_DEFAULT_YAXIS_NTICKS } from '../../../../components/Graphs/constants';
import { truncate } from '../../helpers';

const getXAxis = (data, graphLayout, isInModal, xAxisRange = [], maxXAxisTicks = 50) => {
  const maxXAxisTickChars = 20;

  let xAxisTickValues = data[0]?.x || [];
  // truncate x-axis values to `maxXAxisTickChars`` characters
  let xAxisTickTexts = xAxisTickValues.map((value) => truncate(value, maxXAxisTickChars));
  // if there are more than `maxXAxisTicks`` x-axis values, we only show every other value
  // if x-axis range is provided, we only show the x-axis values within the range
  if (xAxisTickTexts.length > maxXAxisTicks && xAxisRange.length === 0 && maxXAxisTicks > 0) {
    const step = Math.ceil(xAxisTickTexts.length / maxXAxisTicks);
    xAxisTickValues = xAxisTickValues.filter((_, index) => index % step === 0);
    xAxisTickTexts = xAxisTickTexts.filter((_, index) => index % step === 0);
  }

  // if x-axis range is provided, we only show the x-axis values within the range
  if (xAxisRange.length > 0) {
    const [start, end] = xAxisRange;
    const startIndex = Math.floor(start);
    const endIndex = Math.ceil(end);
    xAxisTickValues = xAxisTickValues.slice(startIndex, endIndex + 1);
    xAxisTickTexts = xAxisTickTexts.slice(startIndex, endIndex + 1);
  }

  return {
    xaxis: {
      ...graphLayout.xaxis,
      range: xAxisRange,
      // if there are more than 80 x-axis values, we need to reduce the font size to avoid overlapping
      ...(xAxisTickValues.length > 80 && {
        tickfont: { size: 9 },
      }),
      // if in modal or x-axis not categorical, let Plotly handle the x-axis ticks
      ...(!isInModal &&
        graphLayout.xaxis.type === 'category' && {
          tickvals: xAxisTickValues,
          ticktext: xAxisTickTexts,
        }),
    },
  };
};

export const getAdjustedLayout = ({
  data,
  graphLayout,
  isInModal,
  showLegend,
  xAxisRange = [],
  maxXAxisTicks = 50,
}) => {
  if (data[0]?.x?.length === 1) {
    // if there is only one x-axis value, set x-axis type to category to avoid showing time on x-axis ticks
    graphLayout.xaxis.type = 'category';
  }

  const maxY = data.reduce((sum, d) => sum + Math.max(...d.y), 0);
  const layout = {
    showlegend: showLegend,
    ...graphLayout,
    yaxis: {
      ...graphLayout.yaxis,
      // if the maxY values is lower than 5 (plotly default), reduce the number of nticks to the maxY value,
      // but at least 2 (to avoid showing just 0)
      nticks: maxY < PLOTLY_DEFAULT_YAXIS_NTICKS ? Math.max(maxY, 2) : null,
    },
    // if not in modal and x-axis is categorical, we need to truncate the x-axis values
    ...getXAxis(data, graphLayout, isInModal, xAxisRange, maxXAxisTicks),
    // if is in modal, place legend outside of the graph
    ...(isInModal && {
      legend: {
        x: 1.05,
        y: 0.5,
        traceorder: 'normal',
      },
    }),
  };

  return layout;
};
