import { Collapse, Skeleton } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { SidePageButton } from '../../../../components/SidePageButton';
import { formatDate } from '../../../../utils/helpers';
import { useGetNotes } from './hooks/useGetNotes';
import { useStyles } from './styles';

const SESSION_STORAGE_KEY = 'NotesVisiblePreference';

const getInitialIsOpen = () => {
  const sessionPreference = sessionStorage.getItem(SESSION_STORAGE_KEY);
  return sessionPreference ? JSON.parse(sessionPreference) : true;
};

const NoteBoxSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.noteBoxSkeleton}>
      <Skeleton variant="rectangular" height={15} width={80} />
      <Skeleton variant="rectangular" height={80} width={120} />
      <Skeleton variant="rectangular" height={20} width={50} />
    </div>
  );
};

const NoteBox = ({ dateString, title, url }) => {
  const classes = useStyles();

  const { decodedString, formattedDate } = useMemo(() => {
    const div = document.createElement('div');
    div.innerHTML = title;
    const decodedString = div.firstChild.nodeValue;
    const formattedDate = formatDate(dateString, false);

    return { decodedString, formattedDate };
  }, [dateString, title]);

  return (
    <div className={classes.noteBox}>
      <div className={classes.date}>{formattedDate}</div>
      <div className={classes.noteTitle}>{decodedString}</div>
      <a className={classes.link} href={url} target="_blank" rel="noreferrer">
        Read More
      </a>
    </div>
  );
};

export const Notes = () => {
  const [isOpen, setIsOpen] = useState(getInitialIsOpen());
  const classes = useStyles();
  const { notes, error, loading } = useGetNotes();

  const setIsOpenLocal = useCallback((v) => {
    setIsOpen(v);
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(v));
  }, []);

  if (error) return null;

  return (
    <div className={classes.notes}>
      <SidePageButton
        onClick={() => setIsOpenLocal(!isOpen)}
        isOpen={isOpen}
        label="HIDE NOTES"
        labelClosed="SHOW NOTES"
        className={classes.button}
        showIcon
      />
      <Collapse in={isOpen} sx={{ maxWidth: '150px' }} orientation="horizontal" timeout="auto">
        <h4 className={classes.title}>Latest Deal Notes</h4>
        <div className={classes.boxes}>
          {loading
            ? Array.from({ length: 10 }).map((_, i) => <NoteBoxSkeleton key={i} />)
            : notes?.map(({ date, title, link }) => (
                <NoteBox key={link} dateString={date} title={title.rendered} url={link} />
              ))}
        </div>
      </Collapse>
    </div>
  );
};
