import * as htmlToImage from 'html-to-image';

import { Button, Typography } from '@mui/material';
import { CameraAlt, Check, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { SS_HEIGHT, SS_WIDTH } from '../../../components/Map/Utils/constants.js';

import Loading from '../../../components/Loading/index.js';
import { SCREENSHOT_BOX_COLOR } from '../../../components/Map/Utils/colors.js';
import Slide from '@mui/material/Slide';
import { countryBoundingBoxes } from './countryBoundingBoxes';
import { getCsrfToken } from '../../../utils/auth.js';
import { getLatLongFromPoint } from '../../../utils/helpers.js';
import { getQuery } from '../Info/operations.js';
import logo from '../../../../assets/images/welligence-transparent-white.png';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext';
import { useQuery } from '@apollo/client';
import { useStyles } from './ScreenshotMode.style.js';
import wellsLegendsSrc from '../../../../assets/images/wells-legend.png';
import windroseSrc from '../../../../assets/images/windrose-gray.png';

const ScreenshotModeInner = ({ item, map }) => {
  const { screenshotMode } = useFeatureFlags();
  const [assetViewUploadSuccess, setAssetViewUploadSuccess] = useState(false);
  const [countryViewUploadSuccess, setCountryViewUploadSuccess] = useState(false);
  const classes = useStyles();
  const [assetViewImg, setAssetViewImg] = useState('');
  const [countryViewImg, setCountryViewImg] = useState('');
  const parsedBoundingBoxes = JSON.parse(JSON.stringify(countryBoundingBoxes));
  const blackSquare =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/akXKAAAAABJRU5ErkJggg==';

  useEffect(() => {
    setAssetViewUploadSuccess(false);
    setCountryViewUploadSuccess(false);
  }, [item]);

  const { data: assetData } = useQuery(getQuery('asset'), {
    variables: {
      legacyId: item.legacyId,
      countryIsoCode: item.countryIsoCode,
    },
  });

  const initMapEventListeners = () => {
    if (!map) return;

    map.on('load', () => {
      const event = new CustomEvent('mapLoaded');
      console.log('mapEvent Map loaded');
      window.dispatchEvent(event);
    });

    map.on('zoomend', () => {
      const event = new CustomEvent('mapZoomCompleted');
      console.log('mapEvent mapZoomCompleted');
      window.dispatchEvent(event);
    });

    map.on('render', () => {
      const event = new CustomEvent('mapRendered');
      console.log('mapEvent Map rendered');
      window.dispatchEvent(event);
    });

    map.on('idle', () => {
      const event = new CustomEvent('mapIdle');
      console.log('mapEvent Map idle');
      window.dispatchEvent(event);
    });
  };

  const cleanupMapEventListeners = () => {
    if (window.map) {
      map.off('load');
      map.off('zoomend');
      map.off('render');
      map.off('idle');
    }
  };

  useEffect(() => {
    initMapEventListeners();

    // Cleanup when the component is unmounted
    return () => {
      cleanupMapEventListeners();
    };
  }, []);

  const hasPermissions = () => {
    return item?.entityType === 'asset' && screenshotMode;
  };

  const createBlobImage = (imgElement) => {
    const imageData = imgElement.src;

    const base64Data = imageData.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return blob;
  };

  const saveImageToS3 = async (imageType) => {
    const pictureType =
      imageType === 'assetViewImg' ? 'asset_view_picture' : 'country_view_picture';
    const imgElement = document.querySelector(`#${imageType}`);
    const imgBlob = createBlobImage(imgElement);
    const formData = new FormData();
    formData.append(imageType, imgBlob, `${imageType}.png`);
    try {
      const response = await fetch(
        `${window.location.origin}/data-upload/send_image_to_s3?legacy_id=${item.legacyId}&asset_name=${item.name}&country=${item.countryIsoCode}&image_type=${pictureType}`,
        {
          method: 'POST',
          headers: {
            'X-CSRF-Token': getCsrfToken(),
          },
          body: formData,
        },
      );

      if (response.status !== 200) {
        const errorResponse = response.json();
        throw new Error(errorResponse.errors);
      }
      if (imageType === 'assetViewImg') {
        setAssetViewUploadSuccess(true);
        window.dispatchEvent(new CustomEvent('assetViewImgSaved'));
      } else {
        setCountryViewUploadSuccess(true);
        window.dispatchEvent(new CustomEvent('countryViewImgSaved'));
      }
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      alert('Failed to upload image to S3.');
    }
  };

  const drawSquare = () => {
    const assetPoint = getLatLongFromPoint(assetData.asset.geom);
    const countryBoundingBox = parsedBoundingBoxes[item.countryIsoCode];

    if (!countryBoundingBox || !countryBoundingBox.sw || !countryBoundingBox.ne) {
      console.error('Country bounding box not found or incomplete.');
      return;
    }

    const countryWidthInDegrees = Math.abs(countryBoundingBox.sw.lon - countryBoundingBox.ne.lon);

    // Adjust scale factors for width and height
    const widthScaleFactor = 0.14; // Set to a larger value for more width
    const heightScaleFactor = 0.07; // Set to a smaller value for less height

    const rectangleWidthInDegrees = countryWidthInDegrees * widthScaleFactor;
    const rectangleHeightInDegrees = countryWidthInDegrees * heightScaleFactor;

    let squareCoordinates;

    if (assetPoint && assetPoint.length === 2) {
      const centerLon = parseFloat(assetPoint[0]);
      const centerLat = parseFloat(assetPoint[1]);
      const squareDiagonalPoints = [
        {
          lon: centerLon + rectangleWidthInDegrees,
          lat: centerLat + rectangleHeightInDegrees,
        },
        {
          lon: centerLon - rectangleWidthInDegrees,
          lat: centerLat - rectangleHeightInDegrees,
        },
      ];
      squareCoordinates = [
        [squareDiagonalPoints[0]['lon'], squareDiagonalPoints[0]['lat']],
        [squareDiagonalPoints[0]['lon'], squareDiagonalPoints[1]['lat']],
        [squareDiagonalPoints[1]['lon'], squareDiagonalPoints[1]['lat']],
        [squareDiagonalPoints[1]['lon'], squareDiagonalPoints[0]['lat']],
      ];
    } else {
      console.error('No valid asset bounding box or point available.');
      return null;
    }

    squareCoordinates.push(squareCoordinates[0]);

    const geoJSONData = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [squareCoordinates],
          },
          properties: {
            type: 'square',
          },
        },
      ],
    };

    if (map.getSource('square-and-line')) {
      map.getSource('square-and-line').setData(geoJSONData);
    } else {
      map.addSource('square-and-line', {
        type: 'geojson',
        data: geoJSONData,
      });

      map.addLayer({
        id: 'square-and-line-layer',
        type: 'line',
        source: 'square-and-line',
        layout: {},
        paint: {
          'line-color': SCREENSHOT_BOX_COLOR,
          'line-width': 2,
          'line-opacity': 1,
        },
      });

      map.addLayer({
        id: 'square-fill-layer',
        type: 'fill',
        source: 'square-and-line',
        paint: {
          'fill-color': SCREENSHOT_BOX_COLOR,
          'fill-opacity': 0.1,
        },
        filter: ['==', 'type', 'square'],
      });
    }
  };

  const zoomOut = () => {
    const countryData = parsedBoundingBoxes[item.countryIsoCode];

    if (countryData) {
      const sw = [countryData.sw.lon, countryData.sw.lat]; // Southwest coordinates
      const ne = [countryData.ne.lon, countryData.ne.lat]; // Northeast coordinates

      // Fit the map to the bounding box
      map.fitBounds([sw, ne], {
        padding: {
          top: 0,
          bottom: window.innerHeight - SS_HEIGHT,
          left: 0,
          right: window.innerWidth - SS_WIDTH,
        },
      });
    } else {
      console.log('Country not found in bounding box data.');
    }
  };

  const captureScale = () => {
    const node = document.getElementsByClassName('mapboxgl-ctrl-scale')[0];
    if (!node) {
      console.error('Scale element not found');
      return null;
    }
    const scaleText = node.textContent || node.innerText;
    return scaleText;
  };

  const drawScale = (scaleText) => {
    const scaleCanvas = document.createElement('canvas');
    const ctx = scaleCanvas.getContext('2d');

    const canvasWidth = 100;
    const canvasHeight = 30;
    scaleCanvas.width = canvasWidth;
    scaleCanvas.height = canvasHeight;

    ctx.fillStyle = 'rgba(205, 215, 222, .75)'; // match the wells legend color
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 1;
    ctx.strokeRect(0, 0, canvasWidth, canvasHeight);

    // Draw the scale text (e.g., "300 mi")
    ctx.fillStyle = '#000000';
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(scaleText, canvasWidth / 2, canvasHeight / 2);

    return scaleCanvas;
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });
  };

  const captureScreenshot = (isAssetViewImg, scaleText) => {
    const node = document.getElementById('mapContainer');

    const filter = (node) => {
      const exclusionClasses = ['mapboxgl-ctrl-bottom-right', 'mapboxgl-ctrl-bottom-left'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    };

    htmlToImage
      .toCanvas(node, {
        pixelRatio: 1,
        height: SS_HEIGHT,
        width: SS_WIDTH,
        filter: filter,
      })
      .then((canvas) => {
        const screenshotWidth = canvas.width;
        const screenshotHeight = canvas.height;

        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = screenshotWidth;
        finalCanvas.height = screenshotHeight;
        const ctx = finalCanvas.getContext('2d');

        ctx.drawImage(canvas, 0, 0);

        // Load all images (logo, scale reference, wells legend, and windrose)
        Promise.all([
          loadImage(logo),
          drawScale(scaleText),
          loadImage(wellsLegendsSrc),
          loadImage(windroseSrc),
        ])
          .then(([logoImage, scaleCanvas, wellsLegendImage, windroseImage]) => {
            // Draw the logo
            const padding = 10;
            const legendsHeight = 22;
            const logoWidth = screenshotWidth * 0.25;
            const logoHeight = (logoImage.naturalHeight / logoImage.naturalWidth) * logoWidth;
            const xLogoPosition = padding;
            const yLogoPosition = screenshotHeight - logoHeight - padding;
            ctx.drawImage(logoImage, xLogoPosition, yLogoPosition, logoWidth, logoHeight);

            // Draw the wells legend (for asset view)
            const wellsLegendWidth = 350;
            const wellsLegendY = screenshotHeight - legendsHeight - padding;
            if (isAssetViewImg) {
              const wellsLegendX = screenshotWidth - wellsLegendWidth - padding;
              ctx.drawImage(
                wellsLegendImage,
                wellsLegendX,
                wellsLegendY,
                wellsLegendWidth,
                legendsHeight,
              );
            }

            // Draw the scale reference
            const scaleSize = { width: 66, height: legendsHeight };
            const scaleX = screenshotWidth - scaleSize.width - padding;
            const scaleY = isAssetViewImg
              ? wellsLegendY - scaleSize.height - padding
              : screenshotHeight - scaleSize.height - padding; // Stack above wells legend if asset view, else use starting position
            ctx.drawImage(scaleCanvas, scaleX, scaleY, scaleSize.width, scaleSize.height);

            // Draw the windrose
            const windroseSize = 100;
            const windroseX = screenshotWidth - windroseSize - padding;
            const windroseY = scaleY - windroseSize - padding;
            ctx.drawImage(windroseImage, windroseX, windroseY, windroseSize, windroseSize);

            const finalDataUrl = finalCanvas.toDataURL('image/png');

            if (isAssetViewImg) {
              setAssetViewImg(finalDataUrl);
              setAssetViewUploadSuccess(false);

              const event = new CustomEvent('assetScreenshotReady');
              window.dispatchEvent(event);
            } else {
              setCountryViewImg(finalDataUrl);
              setCountryViewUploadSuccess(false);

              if (map.getLayer('square-and-line-layer')) {
                map.removeLayer('square-and-line-layer');
              }

              if (map.getLayer('square-fill-layer')) {
                map.removeLayer('square-fill-layer');
              }

              if (map.getSource('square-and-line')) {
                map.removeSource('square-and-line');
              }

              const event = new CustomEvent('countryScreenshotReady');
              window.dispatchEvent(event);
            }
          })
          .catch((error) => {
            console.error('Error loading images:', error);
          });
      })
      .catch((error) => {
        console.error('Oops, something went wrong!', error);
      });
  };

  const takePicture = (isAssetViewImg) => {
    const scaleText = captureScale();
    if (isAssetViewImg) {
      captureScreenshot(isAssetViewImg, scaleText);
      setTimeout(() => {
        zoomOut();
      }, 2000);
    } else {
      drawSquare();
      setTimeout(() => {
        captureScreenshot(isAssetViewImg, scaleText);
      }, 1000);
    }
  };

  const imgAssetViewLoaded = () => {
    const imgElement = document.querySelector('#assetViewImg');
    return imgElement && imgElement.src && imgElement.src.split(',').length > 1;
  };

  const imgCountryViewLoaded = () => {
    const imgElement = document.querySelector('#countryViewImg');
    return (
      imgElement &&
      imgElement.src &&
      imgElement.src.split(',').length > 1 &&
      imgElement.src !== blackSquare
    );
  };

  return (
    <div>
      <div className={classes.scroll}>
        {hasPermissions() && (
          <div className={classes.modal}>
            <div>
              <div>
                <Typography className={classes.rootLabel} variant="body1" component="p">
                  <Typography className={classes.label} variant="body1" component="span">
                    Asset Name:
                  </Typography>
                  {item.name}
                </Typography>
                <Typography className={classes.rootLabel} variant="body1" component="p">
                  <Typography className={classes.label} variant="body1" component="span">
                    Country / Area:
                  </Typography>
                  {item.countryIsoCode}
                </Typography>
              </div>

              <div className={classes.imagesContainer}>
                <div>
                  <Typography className={classes.title} variant="subtitle1" component="h5">
                    Asset View
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      id="assetViewImg"
                      src={assetViewImg || blackSquare}
                      alt="Asset View Image"
                      className={classes.screenshot}
                    />
                  </div>
                  <div className={classes.buttonsBar}>
                    <div className={classes.countryViewButtons}>
                      <Button
                        id="asset-view-button"
                        className={classes.button}
                        onClick={() => takePicture(true)}
                      >
                        <CameraAlt
                          fontSize="small"
                          color="primary"
                          className={classes.orangeIcon}
                        />
                        <div>{imgAssetViewLoaded() ? `Retake` : `Take Screenshot`}</div>
                      </Button>
                      {imgAssetViewLoaded() && (
                        <Button
                          className={classes.button}
                          disabled={assetViewUploadSuccess}
                          id="save-asset-view-button"
                          onClick={() => saveImageToS3('assetViewImg')}
                        >
                          {assetViewUploadSuccess ? (
                            <Check />
                          ) : (
                            <Save fontSize="small" color="primary" className={classes.orangeIcon} />
                          )}
                          <div>Save</div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <Typography className={classes.title} variant="subtitle1" component="h5">
                    Country View
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      id="countryViewImg"
                      src={countryViewImg || blackSquare}
                      alt="Country View Image"
                      className={classes.screenshot}
                    />
                  </div>
                  <div className={classes.buttonsBar}>
                    {imgAssetViewLoaded() && (
                      <div className={classes.countryViewButtons}>
                        <Button
                          id="country-view-button"
                          className={classes.button}
                          onClick={() => takePicture(false)}
                        >
                          <CameraAlt
                            fontSize="small"
                            color="primary"
                            className={classes.orangeIcon}
                          />
                          <div>{imgCountryViewLoaded() ? `Retake` : `Take Screenshot`}</div>
                        </Button>
                        {imgAssetViewLoaded() && (
                          <Button
                            className={classes.button}
                            disabled={countryViewUploadSuccess}
                            id="save-country-view-button"
                            onClick={() => saveImageToS3('countryViewImg')}
                          >
                            {countryViewUploadSuccess ? (
                              <Check />
                            ) : (
                              <Save
                                fontSize="small"
                                color="primary"
                                className={classes.orangeIcon}
                              />
                            )}
                            <div>Save</div>
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.screenshotPreview} />
    </div>
  );
};

export const ScreenshotMode = ({ isOpen, item, map }) => {
  const classes = useStyles();

  return (
    <Slide
      direction="left"
      in={isOpen}
      mountOnEnter
      unmountOnExit
      className={classes.screenshotMode}
    >
      <div>
        {item ? (
          <ScreenshotModeInner item={item} map={map} />
        ) : (
          <div className={classes.loading}>
            <Loading />
          </div>
        )}
      </div>
    </Slide>
  );
};
