import React, { useMemo } from 'react';

import { Button } from '@mui/material';
import { MICROSOFT_PROVIDER_ID } from '../../contexts/OAuth2Context/OAuth2Context';
import { useOAuth2 } from '../../contexts/OAuth2Context';

export const MicrosoftLoginButton = (props) => {
  const { state, providers } = useOAuth2();

  const microsoftProvider = providers?.find((provider) => provider.id === MICROSOFT_PROVIDER_ID);

  const clientId = microsoftProvider?.clientId;
  const redirectUri = microsoftProvider?.redirectUri;
  const scope = microsoftProvider?.scope;
  const authUrl = microsoftProvider?.authUrl;
  const params = useMemo(
    () =>
      new URLSearchParams({
        client_id: clientId,
        response_type: 'code',
        redirect_uri: redirectUri,
        scope: scope,
        state: state,
      }),
    [clientId, redirectUri, scope, state],
  );

  const onClick = () => {
    window.location.href = `${authUrl}?${params.toString()}`;
  };

  if (!microsoftProvider) return null;

  return (
    <Button {...props} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30">
        <path fill="#f25022" d="M13 5h9v9h-9z" />
        <path fill="#00a4ef" d="M13 16h9v9h-9z" />
        <path fill="#7fba00" d="M23 5h9v9h-9z" />
        <path fill="#ffb900" d="M23 16h9v9h-9z" />
      </svg>
      Sign In with Microsoft
    </Button>
  );
};
