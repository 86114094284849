import {
  ABATEMENT_COSTS,
  INDUSTRY_TYPE,
  TOTAL_ABATEMENT_COST_SIZE_TYPE,
  TOTAL_CO2_EMISSIONS,
  TOTAL_CO2_EMISSIONS_SIZE_TYPE,
} from '../utils';
import {
  GREEN_HIGH,
  GREEN_LOW,
  GREEN_MAX,
  GREEN_MEDIUM,
  GREEN_MIN,
  INDUSTRY_TYPE_COLORS,
  RED_HIGH,
  RED_LOW,
  RED_MAX,
  RED_MEDIUM,
  RED_MIN,
  TAN_HIGH,
  TAN_LOW,
  TAN_MAX,
  TAN_MEDIUM,
  TAN_MIN,
  WELL_COLORS,
  ZERO_VALUE_FILL,
} from '../../../../components/Map/Utils/colors';
import {
  ccusLayerItems,
  defaultSelectedCcusLayerItems,
} from '../../../../components/Map/Settings/constants';

import { MAP_LAYER_TYPES } from '../../../../components/Map/Utils/constants';

const closedMapSettingsStyle = { position: 'relative', top: '-75%', left: 'calc(100% - 40px)' };

const openMapSettingsStyle = {
  position: 'relative',
  top: '-75%',
  left: 'calc(100% - 400px)',
};

export const mapSettings = {
  includeMapStyle: true,
  includeLayers: true,
  styles: {
    handleStyle: {
      closedStyle: closedMapSettingsStyle,
      openStyle: openMapSettingsStyle,
    },
    rootStyle: {
      closedStyle: { ...closedMapSettingsStyle, top: 'calc(-75% - 300px)' },
      openStyle: openMapSettingsStyle,
    },
  },
};

export const layerProps = {
  defaultLayer: defaultSelectedCcusLayerItems,
  items: ccusLayerItems,
  label: 'LAYERS',
  multiple: true,
  disableQuery: true,
};

// layers to activate on map
export const BASE_LAYERS = [
  MAP_LAYER_TYPES.fields,
  MAP_LAYER_TYPES.blocks,
  MAP_LAYER_TYPES.leases,
  MAP_LAYER_TYPES.assets,
  MAP_LAYER_TYPES.pipelines,
  MAP_LAYER_TYPES.wells,
];
export const CCUS_PROJECT_LAYER = MAP_LAYER_TYPES.ccusProjects;
export const CO2_EMITTER_LAYER = MAP_LAYER_TYPES.co2Emitters;
export const STORAGE_SITE_LAYER = MAP_LAYER_TYPES.storageSites;
export const MAP_LAYERS = [
  ...BASE_LAYERS,
  STORAGE_SITE_LAYER,
  CCUS_PROJECT_LAYER,
  CO2_EMITTER_LAYER,
];

export const getMetricOptions = (metric) => {
  switch (metric) {
    case TOTAL_CO2_EMISSIONS.value:
      return {
        property: 'circle-color',
        value: [
          'step',
          ['get', 'totalCo2Emission'],
          ZERO_VALUE_FILL,
          0.0,
          TAN_MIN,
          0.1,
          TAN_LOW,
          0.2,
          TAN_MEDIUM,
          0.4,
          RED_MIN,
          0.6,
          TAN_HIGH,
          0.8,
          TAN_MAX,
          1.0,
          RED_LOW,
          5.0,
          RED_MEDIUM,
          10.0,
          RED_HIGH,
          15.0,
          RED_MAX,
        ],
      };
    case INDUSTRY_TYPE.value:
      return {
        property: 'circle-color',
        value: [
          'match',
          ['get', 'industryType'],
          'Agriculture',
          INDUSTRY_TYPE_COLORS.agriculture,
          'Ammonia',
          INDUSTRY_TYPE_COLORS.ammonia,
          'Automobile',
          INDUSTRY_TYPE_COLORS.automobile,
          'Cement',
          INDUSTRY_TYPE_COLORS.cement,
          'Chemicals',
          INDUSTRY_TYPE_COLORS.chemicals,
          'Direct Air Capture',
          INDUSTRY_TYPE_COLORS.directAirCapture,
          'Ethanol',
          INDUSTRY_TYPE_COLORS.ethanol,
          'Food and Beverage',
          INDUSTRY_TYPE_COLORS.foodAndBeverage,
          'Hydrogen',
          INDUSTRY_TYPE_COLORS.hydrogen,
          'Iron and Steel',
          INDUSTRY_TYPE_COLORS.ironAndSteel,
          'Mining',
          INDUSTRY_TYPE_COLORS.mining,
          'Natural Gas Processing',
          INDUSTRY_TYPE_COLORS.naturalGasProcessing,
          'Natural Gas Transmission Compression',
          INDUSTRY_TYPE_COLORS.naturalGasTransmissionCompression,
          'Oil and Gas Extraction',
          INDUSTRY_TYPE_COLORS.oilAndGasExtraction,
          'Other',
          INDUSTRY_TYPE_COLORS.other,
          'Power Plant',
          INDUSTRY_TYPE_COLORS.powerPlant,
          'Pulp and Paper',
          INDUSTRY_TYPE_COLORS.pulpAndPaper,
          'Refineries',
          INDUSTRY_TYPE_COLORS.refineries,
          'Waste',
          INDUSTRY_TYPE_COLORS.waste,
          /* other */ INDUSTRY_TYPE_COLORS.default,
        ],
      };
    case ABATEMENT_COSTS.value:
      return {
        property: 'circle-color',
        value: {
          property: 'abatementCost',
          stops: [
            [0, ZERO_VALUE_FILL],
            [50, GREEN_MAX],
            [100, GREEN_HIGH],
            [175, GREEN_MEDIUM],
            [250, GREEN_LOW],
            [300, GREEN_MIN],
          ],
        },
      };
    default: {
      return {
        property: 'circle-color',
        value: [
          'step',
          ['get', 'totalCo2Emission'],
          ZERO_VALUE_FILL,
          0.0,
          TAN_MIN,
          0.1,
          TAN_LOW,
          0.2,
          TAN_MEDIUM,
          0.4,
          RED_MIN,
          0.6,
          TAN_HIGH,
          0.8,
          TAN_MAX,
          1.0,
          RED_LOW,
          5.0,
          RED_MEDIUM,
          10.0,
          RED_HIGH,
          15.0,
          RED_MAX,
        ],
      };
    }
  }
};

export const getSizeOptions = (size) => {
  switch (size) {
    case TOTAL_CO2_EMISSIONS_SIZE_TYPE.value:
      return {
        property: 'circle-radius',
        value: [
          'step',
          ['get', 'totalCo2Emission'],
          6,
          0.1,
          7,
          0.2,
          8,
          0.4,
          9,
          0.6,
          10,
          0.8,
          11,
          1.0,
          12,
          5.0,
          13,
          10.0,
          14,
          15.0,
          15,
        ],
      };
    case TOTAL_ABATEMENT_COST_SIZE_TYPE.value:
      return {
        property: 'circle-radius',
        value: {
          property: 'abatementCost',
          stops: [
            [50, 6],
            [100, 8],
            [175, 10],
            [250, 12],
            [300, 14],
          ],
        },
      };
    default: {
      return {
        property: 'circle-radius',
        value: 6,
      };
    }
  }
};

// CO2 Emitters
export const getCo2EmitterStyleOptions = (metric, size) => {
  const metricOptions = getMetricOptions(metric);
  const sizeOptions = getSizeOptions(size);
  return {
    type: 'circle',
    paint: {
      'circle-stroke-width': 0.5,
      'circle-stroke-color': WELL_COLORS.boundary,
      [metricOptions.property]: metricOptions.value,
      [sizeOptions.property]: sizeOptions.value,
    },
  };
};

// CCUS Projects
export const getCcusProjectStyleOptions = () => {
  const metricOptions = getMetricOptions(INDUSTRY_TYPE.value);
  return {
    type: 'circle',
    paint: {
      'circle-stroke-width': 3,
      'circle-stroke-color': ZERO_VALUE_FILL,
      [metricOptions.property]: metricOptions.value,
      'circle-radius': 4,
    },
  };
};

// Legend
const INDUSTRY_LEGEND_ITEMS = [
  { title: 'Agriculture', color: INDUSTRY_TYPE_COLORS.agriculture },
  { title: 'Ammonia', color: INDUSTRY_TYPE_COLORS.ammonia },
  { title: 'Automobile', color: INDUSTRY_TYPE_COLORS.automobile },
  { title: 'Cement', color: INDUSTRY_TYPE_COLORS.cement },
  { title: 'Chemicals', color: INDUSTRY_TYPE_COLORS.chemicals },
  { title: 'Direct Air Capture', color: INDUSTRY_TYPE_COLORS.directAirCapture },
  { title: 'Ethanol', color: INDUSTRY_TYPE_COLORS.ethanol },
  { title: 'Food and Beverage', color: INDUSTRY_TYPE_COLORS.foodAndBeverage },
  { title: 'Hydrogen', color: INDUSTRY_TYPE_COLORS.hydrogen },
  { title: 'Iron and Steel', color: INDUSTRY_TYPE_COLORS.ironAndSteel },
  { title: 'Mining', color: INDUSTRY_TYPE_COLORS.mining },
  { title: 'Natural Gas Processing', color: INDUSTRY_TYPE_COLORS.naturalGasProcessing },
  {
    title: 'Natural Gas Transmission Compression',
    color: INDUSTRY_TYPE_COLORS.naturalGasTransmissionCompression,
  },
  { title: 'Oil and Gas Extraction', color: INDUSTRY_TYPE_COLORS.oilAndGasExtraction },
  { title: 'Other', color: INDUSTRY_TYPE_COLORS.other },
  { title: 'Power Plant', color: INDUSTRY_TYPE_COLORS.powerPlant },
  { title: 'Pulp and Paper', color: INDUSTRY_TYPE_COLORS.pulpAndPaper },
  { title: 'Refineries', color: INDUSTRY_TYPE_COLORS.refineries },
  { title: 'Waste', color: INDUSTRY_TYPE_COLORS.waste },
  { title: 'Unknown', color: INDUSTRY_TYPE_COLORS.default },
];

const CCUS_PROJECT_LEGEND_ITEM = {
  label: 'CCUS Project Industry Types',
  multiline: true,
  items: INDUSTRY_LEGEND_ITEMS,
  itemBorder: 'solid thin white',
};

const WELL_LEGEND_ITEM = {
  label: 'Wells',
  items: [
    { color: WELL_COLORS.exploration_and_appraisal, title: 'Exploration' },
    { color: WELL_COLORS.production, title: 'Production' },
    { color: WELL_COLORS.injection, title: 'Injection' },
    { color: WELL_COLORS.default, title: 'Other' },
  ],
};

const DEFAULT_LEGEND_ITEMS = [CCUS_PROJECT_LEGEND_ITEM, WELL_LEGEND_ITEM];

export const getLegendItems = (metric) => {
  let metricItem;
  switch (metric) {
    case TOTAL_CO2_EMISSIONS.value:
      metricItem = {
        label: 'CO2 Emissions (Mega Ton)',
        items: [
          { color: ZERO_VALUE_FILL, title: '= 0' },
          { color: TAN_MIN, title: '> 0 - 0.1' },
          { color: TAN_LOW, title: '> 0.1 - 0.2' },
          { color: TAN_MEDIUM, title: '> 0.2 - 0.4' },
          { color: RED_MIN, title: '> 0.4 - 0.6' },
          { color: TAN_HIGH, title: '> 0.6 - 0.8' },
          { color: TAN_MAX, title: '> 0.8 - 1.0' },
          { color: RED_LOW, title: '> 1.0 - 5.0' },
          { color: RED_MEDIUM, title: '> 5.0 - 10.0' },
          { color: RED_HIGH, title: '> 10.0 - 15.0' },
          { color: RED_MAX, title: '> 15' },
        ],
      };
      break;
    case INDUSTRY_TYPE.value:
      metricItem = {
        label: 'CO2 Emission Industry Types',
        multiline: true,
        items: INDUSTRY_LEGEND_ITEMS,
      };
      break;
    case ABATEMENT_COSTS.value:
      metricItem = {
        label: 'Abatement Costs (US$/t)',
        items: [
          { color: ZERO_VALUE_FILL, title: ' = Undefined' },
          { color: GREEN_MAX, title: '> 0 - 50' },
          { color: GREEN_HIGH, title: '> 50 - 100' },
          { color: GREEN_MEDIUM, title: '> 100 - 175' },
          { color: GREEN_LOW, title: '> 175 - 250' },
          { color: GREEN_MIN, title: '> 250' },
        ],
      };
      break;
    default:
      return DEFAULT_LEGEND_ITEMS;
  }

  return [metricItem, ...DEFAULT_LEGEND_ITEMS];
};

// Filter map layers based on dataset graphed
const createMapLayerFilter = (filterField, filterValue) =>
  filterValue.length === 0 ? null : ['match', ['get', filterField], filterValue, true, false];

export const getMapLayerFilters = (options) =>
  options.map(({ field, layers, value }) => ({
    entityTypes: layers,
    filter: createMapLayerFilter(field, value),
  }));
