import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, Divider, Skeleton } from '@mui/material';

/**
 * Component that renders an iframe for the rails admin pages.
 * @param {string} iframeSrc - The source url for the iframe (e.g. "/admins/clients").
 */
export const AdminsIFrame = ({ iframeSrc }) => {
  const classes = useStyles();
  const [iframeUrl, setIframeUrl] = useState(iframeSrc);
  const [isLoading, setIsLoading] = useState(true);

  // iframe -> browser - listen for iframe url changes and update the current url:
  useEffect(() => {
    const eventName = 'message';
    const handleMessage = (event) => {
      // Check if the message is from the correct origin:
      if (!event.origin.endsWith('welligence.com') && event.origin !== 'http://localhost:3000') {
        return;
      }

      const { url } = event.data;
      if (url) {
        // Replace the base path with a hash and update the browser URL:
        // e.g. url = "/admins/clients/new" => hash = "#new"
        const hash = url.replace(new RegExp(`^${iframeSrc}/?`), '#');
        window.location.hash = hash === '#' ? '' : hash;
      }
    };

    window.addEventListener(eventName, handleMessage);

    return () => {
      window.removeEventListener(eventName, handleMessage);
    };
  }, []);

  // Browser -> iframe - update the iframe URL based on the hash
  // e.g., if the hash is "#new", the iframe URL will be "/admins/clients/new"
  useEffect(() => {
    const updateIframeUrl = () => {
      const { hash } = window.location;
      setIframeUrl(`${iframeSrc}${hash.replace('#', '/')}`);
    };

    // Update the iframe URL on initial load:
    if (window.location.hash) {
      updateIframeUrl();
    }
  }, []);

  const LoadingState = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 3,
        width: '100%',
        maxWidth: '1000px',
        margin: '0 auto',
      }}
    >
      <Skeleton variant="text" width="40%" height={35} data-testid="loading-skeleton" />
      <Skeleton variant="text" width="40%" height={20} />
      <Divider />
      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="40%" height={40} />
      <Skeleton variant="rectangular" width="60%" height={40} />
    </Box>
  );

  return (
    <div className={classes.root}>
      {isLoading && <LoadingState />}
      <iframe
        src={iframeUrl}
        width="100%"
        height="100%"
        title="Admins"
        onLoad={() => setIsLoading(false)}
        style={{ display: isLoading ? 'none' : 'block' }}
      />
    </div>
  );
};
