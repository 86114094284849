import { BASIC_FILTERS_WRAPPER_ID } from './BasicFiltersWrapper.constant';
import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  filterSection: (p) => ({
    display: 'grid',
    width: '100%',
    gap: '1rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    gridTemplateColumns: `repeat(${p.numberOfFields}, 1fr)`,
    '& .advanced-autocomplete': {
      padding: '0',
    },
    '& .MuiAutocomplete-root': {
      margin: '0',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(${Math.ceil(p.numberOfFields / 2)}, 1fr)`,
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  }),
  twoRows: (p) => ({
    [theme.breakpoints.down(1500)]: {
      gridTemplateColumns: `repeat(${Math.ceil(p.numberOfFields / 2)}, 1fr)`,
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  }),
  minimized: {
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      overflow: 'hidden',
    },
  },
  growFirstFilter: {
    '& > *:first-child': {
      minWidth: '190px',
      [theme.breakpoints.between('md', 'lg')]: {
        gridRow: 'span 2',
      },
    },
  },
}));

export const BasicFiltersWrapper = ({ children, minimize, numberOfFields, growFirstFilter }) => {
  const classes = useStyles({ numberOfFields });

  return (
    <Box
      className={clsx(classes.filterSection, {
        [classes.minimized]: minimize,
        [classes.growFirstFilter]: growFirstFilter,
        [classes.twoRows]: numberOfFields > 6,
      })}
      id={BASIC_FILTERS_WRAPPER_ID}
    >
      {children}
    </Box>
  );
};
