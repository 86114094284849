import React, { useContext, useMemo, useState } from 'react';
import { getTickText, getTickVals } from '../../../../components/Portfolio/Charts/BarGraph/utils';

import BarGraph from '../../../../components/Graphs/barGraph';
import { CCUS_GOOGLE_ANALYTICS_TAG } from '../../constants';
import { CCUS_GRAPH_APP_BAR_ID } from '../../driver';
import GraphContainer from '../../../../components/Graphs/graphContainer';
import Grid from '@mui/material/Grid2';
import Modal from '../../../../components/Modals';
import { graphTitles } from '../utils';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  graph: {
    display: 'flex',
    flex: '1 1 auto',
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
}));

export default ({ context, excludeXAxisText, includeId }) => {
  const classes = useStyles();
  const {
    downloadCallback,
    graphType,
    loading,
    modalRange,
    onGraphUpdate,
    onRelayout,
    range,
    setModalRange,
    setOpenControlsModal,
    setShowPlotlyLegend,
    showPlotlyLegend,
    showPlotlyLegendToggle,
    trace,
    maxY,
    sortDirection,
  } = useContext(context);
  const [open, setOpen] = useState(false);
  const showToggle = false;

  const closeFullscreen = () => {
    setOpen(false);
    setModalRange({ x: [], y: [] });
  };

  const title = useMemo(
    () =>
      graphTitles[graphType.value]
        ? `${graphType.label} ${graphTitles[graphType.value]}`
        : graphType.label,
    [graphType],
  );
  const { xaxis, xaxisModal } = useMemo(() => {
    let xaxis = {};
    let xaxisModal = {};
    if (!excludeXAxisText) {
      const tickvals = getTickVals(trace);
      xaxis = {
        tickmode: 'array',
        ticktext: getTickText(trace),
        tickvals,
        categoryorder: sortDirection.plotlyCategoryOrder,
        range: range.x,
        automargin: true,
      };
      xaxisModal = { ...xaxis, ticktext: tickvals, range: modalRange.x };
    } else {
      xaxis = { tickangle: '0' };
      xaxisModal = { ...xaxis };
    }
    return { xaxis, xaxisModal };
  }, [trace, range, modalRange]);
  return (
    <>
      <GraphContainer
        appBarId={includeId ? CCUS_GRAPH_APP_BAR_ID : null}
        open={open}
        setOpen={() => setOpen(true)}
        setOpenControlsModal={setOpenControlsModal}
        setShowPlotlyLegend={setShowPlotlyLegend}
        showPlotlyLegend={showPlotlyLegend}
        showPlotlyLegendToggle={showPlotlyLegendToggle}
        title={title}
        loading={loading}
        downloadCallback={downloadCallback}
        googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
      >
        <BarGraph
          data={trace}
          layout={{
            barmode: 'stack',
            margin: { l: 50, r: 30, b: 30, t: 30 },
            xaxis,
            yaxis: {
              range: range.y,
            },
            showlegend: showPlotlyLegendToggle && showPlotlyLegend,
            legend: {
              x: 1,
              xanchor: 'right',
              y: 1,
              bgcolor: 'rgba(236, 240, 245, 0.8)',
            },
          }}
          loading={loading}
          onRelayout={onRelayout}
          maxY={maxY}
        />
      </GraphContainer>
      <Modal
        handleClose={closeFullscreen}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose={true}
        styles={classes}
        disablePortal
      >
        <Grid container className={classes.root}>
          <GraphContainer
            open={open}
            setClose={closeFullscreen}
            setOpenControlsModal={setOpenControlsModal}
            showToggle={showToggle}
            title={title}
            loading={loading}
            downloadCallback={downloadCallback}
            googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
          >
            <BarGraph
              data={trace}
              layout={{
                barmode: 'stack',
                margin: { l: 50, r: 30, b: 30, t: 30 },
                xaxis: xaxisModal,
                yaxis: {
                  range: modalRange.y,
                },
                showlegend: showPlotlyLegendToggle && showPlotlyLegend,
              }}
              loading={loading}
              onRelayout={onRelayout}
              onUpdate={onGraphUpdate}
              maxY={maxY}
            />
          </GraphContainer>
        </Grid>
      </Modal>
    </>
  );
};
