import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GasMeter, OilBarrel } from '@mui/icons-material';

export const COLUMNS = [
  { field: 'country', headerName: 'Country', flex: 1 },
  {
    field: 'asset',
    headerName: 'Asset',
    flex: 2,
    renderCell: (params) => {
      const { isoCode, assetLegacyId, asset: assetName } = params.row; // Destructure `params.row`
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/valuations/${isoCode}/${assetLegacyId}`;

      return (
        <Box display="flex" alignItems="center">
          <Link
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <Typography color="secondary" style={{ display: 'contents', alignItems: 'center' }}>
              {assetName}
            </Typography>
            <OpenInNewIcon fontSize="small" color="action" />
          </Link>
        </Box>
      );
    },
  },
  { field: 'company', headerName: 'Company', flex: 1.5 },
  { field: 'formattedNpv', headerName: 'NPV10', flex: 1 },
  {
    field: 'hydrocarbonType',
    headerName: 'Hydrocarbon Type',
    flex: 1,
    renderCell: (params) => {
      const titleizedValue =
        params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase();

      return (
        <Box display="flex" alignItems="center">
          {params.value === 'gas' ? (
            <GasMeter fontSize="small" color="secondary" />
          ) : (
            <OilBarrel fontSize="small" color="secondary" />
          )}
          <Typography ml={1} style={{ display: 'contents', alignItems: 'center' }}>
            {titleizedValue}
          </Typography>
        </Box>
      );
    },
  },
  { field: 'formattedRemainingCapex', headerName: 'Rem Capex', flex: 1 },
];
