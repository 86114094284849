import { FormControlLabel, FormGroup, Radio, Typography } from '@mui/material';
import React, { useState } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,

    '& > .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  item: {
    color: theme.palette.baseColors.skyBlue.c75,
    '&.Mui-checked': {
      color: theme.palette.baseColors.skyBlue.c75,
    },
  },
}));

const Adjustments = ({ adjustments, handleSetState, values }) => {
  const classes = useStyles();
  // We need a local state to manage the toggling of the adjustments
  const [state, setState] = useState(
    adjustments?.reduce(
      (acc, adjustment) => ({ ...acc, [adjustment.value]: values[adjustment.value] || false }),
      {},
    ),
  );

  const toggle = (adjustment) => {
    // Set the local state
    setState({ ...state, [adjustment]: !state[adjustment] });
    // Call the parent handler
    handleSetState(!state[adjustment], adjustment);
  };

  if (!adjustments || !adjustments.length) return null;

  return (
    <Box width={'100%'}>
      <Typography className={classes.label} variant="overline">
        Adjustments
      </Typography>
      <FormGroup>
        {adjustments.map((adjustment) => (
          <FormControlLabel
            className={classes.row}
            key={adjustment.value}
            control={
              <Radio
                className={classes.item}
                checked={state[adjustment.value]}
                onClick={() => toggle(adjustment.value)}
              />
            }
            label={adjustment.label}
            labelPlacement="start"
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default Adjustments;
