import * as Integrations from '@sentry/browser';
import * as Sentry from '@sentry/react';

import {
  FORGOT_PASSWORD_PATH,
  OAUTH2_SIGN_IN_PATH,
  RESET_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from './constants';
import { ResetPassword, SendResetLink } from '../components/ForgotPassword';
import { Route, Switch } from 'react-router-dom';

import { AppBar } from '../components/AppBar';
import ErrorBoundary from '../components/ErrorBoundary';
import LazySignUp from '../pages/LazySignUp';
import Login from '../pages/Login';
import { OAuth2Login } from '../pages/OAuth2Login';
import ProtectedRoute from '../components/ProtectedRoute';
import React from 'react';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';
import { useProtectedRoutes } from './useProtectedRoutes';
import { useAdminRoutes } from './useAdminRoutes';
import { AdminsBar } from '../components/AdminsBar';

export const getTestId = (name) => `${name}Page`;

export const ROUTES = [
  {
    path: SIGN_IN_PATH,
    Page: Login,
  },
  {
    path: OAUTH2_SIGN_IN_PATH,
    Page: OAuth2Login,
  },
  {
    path: SIGN_UP_PATH,
    Page: LazySignUp,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    Page: SendResetLink,
  },
  {
    path: RESET_PASSWORD_PATH,
    Page: ResetPassword,
  },
];

export const AppRoutes = () => {
  const { enableSentry } = useFeatureFlags();
  const protectedRoutes = useProtectedRoutes();
  const adminRoutes = useAdminRoutes();

  if (enableSentry) {
    Sentry.init({
      dsn: 'https://bebe9ddb38fc4e56971bd65f3b76f5b3@o1101134.ingest.sentry.io/6126882',
      environment: process.env.APP_ENV,
      integrations: [Integrations.browserTracingIntegration()],
      tracesSampleRate: 1.0,
    });
  }

  return (
    <Switch>
      {ROUTES.map(({ path, Page }) => {
        return (
          <Route
            exact
            key={path}
            path={path}
            component={() => (
              <div data-testid={getTestId(Page.name)}>
                <Page />
              </div>
            )}
          />
        );
      })}
      {adminRoutes.map(({ path, Page, iframeSrc }) => {
        return (
          <ProtectedRoute exact key={path} path={path}>
            <ErrorBoundary>
              <AdminsBar>
                <div data-testid={getTestId(Page.name)}>
                  <Page iframeSrc={iframeSrc} />
                </div>
              </AdminsBar>
            </ErrorBoundary>
          </ProtectedRoute>
        );
      })}
      {protectedRoutes.map(({ path, Page, forceNewTab }) => {
        return (
          <ProtectedRoute exact key={path} path={path}>
            <ErrorBoundary>
              <AppBar forceNewTab={forceNewTab} />
              <div data-testid={getTestId(Page.name)}>
                <Page />
              </div>
            </ErrorBoundary>
          </ProtectedRoute>
        );
      })}
    </Switch>
  );
};
