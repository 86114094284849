import { ASSET_TEMPLATE_CONTEXT, VALUATIONS_CONTEXT } from '../constants';
import { MAP_LAYER_OPTIONS, MAP_SOURCE_IDS } from '../../../components/Map/Utils/constants';
import React, { useEffect, useRef, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import AssetReport from './AssetReport';
import AssetSummary from './AssetSummary';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Fab from '@mui/material/Fab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MAPBOX_ACCESS_TOKEN } from '../../../utils/constants';
import Reports from './reports';
import Typography from '@mui/material/Typography';
import { getLatLongFromPoint } from '../../../utils/helpers';
import { hideBasinsLayer } from '../../../components/Map/Utils/layers';
import makeStyles from '@mui/styles/makeStyles';
import { mapStyles } from '../../../components/Map';
import mapboxgl from 'mapbox-gl';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  valuationAndReportsAppBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 1,
  },
  valuationAndReportsHeader: {
    color: '#2a2e49',
    padding: '12px 36px',
    [theme.breakpoints.down('lg')]: {
      padding: '12px 10px',
    },
  },
  valuationAndReportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 75px)',
    padding: '14px 25px',
  },
  valuationAndReportsMap: {
    width: '45%',
    cursor: 'pointer',
  },
  [`${VALUATIONS_CONTEXT}_valuationsAndReportsTop`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  [`${VALUATIONS_CONTEXT}_valuationsAndReportsBottom`]: {
    width: '100%',
    paddingTop: '20px',
  },
  [`${ASSET_TEMPLATE_CONTEXT}_valuationsAndReportsTop`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  [`${ASSET_TEMPLATE_CONTEXT}_valuationsAndReportsBottom`]: {
    width: '100%',
    paddingTop: '20px',
  },
  scrollButton: {
    position: 'fixed',
    right: '22%',
    bottom: '35px',
    width: 'auto',
    borderRadius: '30px',
    ...theme.palette.actionCustom,
    [theme.breakpoints.down('lg')]: {
      right: '24%',
    },
  },
}));

let reportsObserver = null;

export default ({ asset, context, setUpdateWatchList, valuationsRef, setSnack }) => {
  const classes = useStyles();
  const reportsRef = useRef();
  const { report } = useParams();

  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  const disabled = asset === null;

  const handleScroll = () => {
    reportsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [map, setMap] = useState(null);
  const [scrollButtonVisible, setScrollButtonVisible] = useState(true);
  const [reportsLoaded, setReportsLoaded] = useState(false);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setScrollButtonVisible(false);
    } else {
      setScrollButtonVisible(true);
    }
  };

  const goToMaps = () => {
    if (asset) {
      window.open(`/oil-and-gas-map/[${getLatLongFromPoint(asset.geom)}]`, '_blank');
    }
  };

  useEffect(() => {
    setMap(
      new mapboxgl.Map({
        container: 'mapContainer',
        style: mapStyles['monochrome'],
        center: [-90, 30],
        zoom: 4,
        interactive: false,
        attributionControl: false,
      }),
    );
  }, []);

  useEffect(() => {
    if (valuationsRef !== undefined) {
      // observer for scroll to report button
      let options = {
        root: valuationsRef.current,
        rootMargin: '0px',
        threshold: 0.05,
      };

      reportsObserver = new IntersectionObserver(handleObserver, options);
      reportsObserver.observe(reportsRef.current);
      if (report === 'report') {
        handleScroll();
      }
    }
  }, [reportsLoaded]);

  useEffect(() => {
    if (map) {
      let id,
        name,
        latLong = '';
      if (asset !== null) {
        latLong = getLatLongFromPoint(asset.geom);
        ({ id, name } = asset);
      }
      map.flyTo({
        center: latLong,
        zoom: 4,
      });
      map.on('styledata', function () {
        hideBasinsLayer(map);
      });
      const assetPoint = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            id: id,
            properties: {
              name: name,
              id: id,
            },
            geometry: {
              type: 'Point',
              coordinates: latLong,
            },
          },
        ],
      };
      const createSource = () => {
        map.addSource(MAP_SOURCE_IDS.assetPointSource, {
          type: 'geojson',
          data: assetPoint,
        });
        map.addLayer(MAP_LAYER_OPTIONS.assetPoint);
      };
      if (!map.getSource(MAP_SOURCE_IDS.assetPointSource)) {
        if (map.isStyleLoaded()) {
          createSource();
        } else {
          map.on('load', createSource);
        }
      } else {
        map.getSource(MAP_SOURCE_IDS.assetPointSource).setData(assetPoint);
      }
    }
  }, [asset]);

  return (
    <div className={classes.root}>
      <AppBar className={classes.valuationAndReportsAppBar} position="sticky">
        <Typography className={classes.valuationAndReportsHeader} variant="body1">
          VALUATIONS AND REPORTS
        </Typography>
      </AppBar>
      <div className={classes.valuationAndReportsContainer}>
        <div className={classes[`${context}_valuationsAndReportsTop`]}>
          <div
            className={classes.valuationAndReportsMap}
            id="mapContainer"
            onClick={goToMaps}
            target="_blank"
          />
          <ErrorBoundary>
            <Reports
              asset={asset}
              disabled={disabled}
              context={context}
              handleScroll={handleScroll}
              setUpdateWatchList={setUpdateWatchList}
              setSnack={setSnack}
            />
          </ErrorBoundary>
        </div>
        <div className={classes[`${context}_valuationsAndReportsBottom`]}>
          <ErrorBoundary>
            <AssetSummary asset={asset} context={context} disabled={disabled} />
          </ErrorBoundary>
          {context === ASSET_TEMPLATE_CONTEXT ? (
            <div ref={reportsRef}>
              <ErrorBoundary>
                <AssetReport
                  asset={asset}
                  setReportsLoaded={setReportsLoaded}
                  setSnack={setSnack}
                />
              </ErrorBoundary>
            </div>
          ) : null}
        </div>
      </div>
      {scrollButtonVisible && reportsLoaded ? (
        <Fab
          id="asset-page-view-asset-report-button"
          variant="extended"
          size="small"
          aria-label="scroll to report"
          onClick={handleScroll}
          className={classes.scrollButton}
        >
          Read Asset Report
          <KeyboardArrowDownIcon />
        </Fab>
      ) : null}
    </div>
  );
};
