import {
  BLOCK_LABEL_COLOR,
  BOUNDARY_COLOR,
  DEFAULT_BOUNDARY_COLOR,
  DEFAULT_LABEL_COLOR,
  SCREENING_BOUNDARY_DEFAULT_COLOR,
  SCREENING_BOUNDARY_HIGHLIGHT_COLOR,
  WELL_COLORS,
  WELL_LABEL_COLORS,
} from './colors';
import { MAP_LAYER_IDS, MAP_LAYER_OPTIONS, MAP_LAYER_TYPES } from './constants';
import {
  getCcusProjectStyleOptions,
  getCo2EmitterStyleOptions,
  getMetricOptions,
  getSizeOptions,
} from '../../../pages/CCUS/Charts/Map/constants';

import { getCurrentPath } from '../../../utils/constants';
import { mapLayerMatch } from './utils';

export default {
  monochrome: 'mapbox://styles/welligence/clhuwurjm042s01p8b0vghsif',
  satellite: 'mapbox://styles/welligence/cklbyjeqo0bi617q910fhtz6a',
};

// get map style local storage key
const getMapStyleSessionStorageKey = () => {
  const currentPath = getCurrentPath(window.location.pathname);
  return `${currentPath}_mapStyle`;
};

// get user preference map style
export const getUserPreferenceMapStyle = (defaultStyle) => {
  const mapStyle = sessionStorage.getItem(getMapStyleSessionStorageKey());
  return mapStyle || defaultStyle;
};

// set user preference map style
export const setUserPreferenceMapStyle = (newStyle) => {
  sessionStorage.setItem(getMapStyleSessionStorageKey(), newStyle);
};

// get map label text colors
export const getMapLabelTextColors = (layer) => {
  if (
    mapLayerMatch(layer, [
      MAP_LAYER_TYPES.assets,
      MAP_LAYER_TYPES.blocks,
      MAP_LAYER_TYPES.leases,
      MAP_LAYER_TYPES.storageSites,
    ])
  ) {
    return BLOCK_LABEL_COLOR;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.fields])) {
    return DEFAULT_LABEL_COLOR;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.wells])) {
    // Ignore prettier here so we can have a readable mapbox expression
    // for the well label colors:
    // prettier-ignore
    return [
      'match',
      ['get', 'weaClassification'],
      'Production', WELL_LABEL_COLORS.production,
      'Injection', WELL_LABEL_COLORS.injection,
      'Exploration', WELL_LABEL_COLORS.exploration_and_appraisal,
      'Appraisal', WELL_LABEL_COLORS.exploration_and_appraisal,
      /* other */ WELL_LABEL_COLORS.default,
    ];
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.eaWells])) {
    return WELL_LABEL_COLORS.wells_to_watch;
  }
};

// get entity type highlight properties
export const getPropertyHighlight = (layer, feature) => {
  if (mapLayerMatch(layer, [MAP_LAYER_TYPES.lng])) {
    return {
      property: 'icon-color',
      value: {
        property: 'id',
        type: 'categorical',
        stops: [[feature.id, BOUNDARY_COLOR]],
        default: DEFAULT_BOUNDARY_COLOR,
      },
    };
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.facilities])) {
    // need to look into this further
    return {
      property: 'icon-color',
      value: {
        property: 'legacyId',
        type: 'categorical',
        stops: [[feature.legacyId, BOUNDARY_COLOR]],
        default: DEFAULT_BOUNDARY_COLOR,
      },
    };
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.wells])) {
    return {
      property: 'circle-stroke-color',
      value: [
        'case',
        ['==', ['get', 'legacyId'], feature.legacyId],
        BOUNDARY_COLOR,
        WELL_COLORS.boundary,
      ],
    };
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.co2Emitters, MAP_LAYER_TYPES.ccusProjects])) {
    return {
      property: 'circle-stroke-color',
      value: ['case', ['==', ['get', 'id'], feature.id], BOUNDARY_COLOR, WELL_COLORS.boundary],
    };
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.eaWells])) {
    return {
      property: 'circle-stroke-color',
      value: ['case', ['==', ['get', 'id'], feature.id], BOUNDARY_COLOR, WELL_COLORS.boundary],
      layer: MAP_LAYER_IDS.eaWells,
    };
  } else if (
    mapLayerMatch(layer, [MAP_LAYER_TYPES.blocks, MAP_LAYER_TYPES.assets, MAP_LAYER_TYPES.leases])
  ) {
    return {
      screening: {
        colorProperty: 'line-color',
        colorValue: {
          property: 'legacyId',
          type: 'categorical',
          stops: [[`${feature.legacyId}`, SCREENING_BOUNDARY_HIGHLIGHT_COLOR]],
          default: SCREENING_BOUNDARY_DEFAULT_COLOR,
        },
        widthProperty: 'line-width',
        widthValue: {
          property: 'legacyId',
          type: 'categorical',
          stops: [[`${feature.legacyId}`, 4]],
          default: 2,
        },
      },
    };
  } else if (layer.match(/^screening$/g)) {
    return {
      property: 'line-color',
      value: {
        property: 'legacyId',
        type: 'categorical',
        stops: [[feature.legacyId, DEFAULT_BOUNDARY_COLOR]],
        default: BOUNDARY_COLOR,
      },
    };
  } else if (layer.match(/.*-map-boundaries/g)) {
    return {
      property: 'fill-outline-color',
      value: {
        property: 'iso_3166_1_alpha_3',
        type: 'categorical',
        stops: [[feature.iso_3166_1_alpha_3, BOUNDARY_COLOR]],
        default: DEFAULT_BOUNDARY_COLOR,
      },
    };
  }
};

// get entity type default properties (rever from highlight)
export const getPropertyDefault = (layer) => {
  if (mapLayerMatch(layer, [MAP_LAYER_TYPES.facilities, MAP_LAYER_TYPES.lng])) {
    return {
      property: 'icon-color',
      value: DEFAULT_BOUNDARY_COLOR,
    };
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.eaWells])) {
    return {
      property: 'circle-stroke-color',
      value: WELL_COLORS.boundary,
      layer: MAP_LAYER_IDS.eaWells,
    };
  } else if (
    mapLayerMatch(layer, [
      MAP_LAYER_TYPES.wells,
      MAP_LAYER_TYPES.co2Emitters,
      MAP_LAYER_TYPES.ccusProjects,
    ])
  ) {
    return {
      property: 'circle-stroke-color',
      value: WELL_COLORS.boundary,
    };
  } else if (
    mapLayerMatch(layer, [MAP_LAYER_TYPES.blocks, MAP_LAYER_TYPES.assets, MAP_LAYER_TYPES.leases])
  ) {
    return {
      screening: {
        colorProperty: 'line-color',
        colorValue: BOUNDARY_COLOR,
        widthProperty: 'line-width',
        widthValue: 1,
      },
    };
  } else if (layer.match(/^screening$/g)) {
    return {
      property: 'line-color',
      value: BOUNDARY_COLOR,
    };
  } else if (layer.match(/.*-map-boundaries/g)) {
    return {
      property: 'fill-outline-color',
      value: DEFAULT_BOUNDARY_COLOR,
    };
  }
};

// get entity type style options
export const getMapOptions = (layer, initialMetric, initialSizeType) => {
  if (mapLayerMatch(layer, [MAP_LAYER_TYPES.presaltPolygons])) {
    return MAP_LAYER_OPTIONS.presaltPolygon;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.formations])) {
    return MAP_LAYER_OPTIONS.formation;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.assets])) {
    return MAP_LAYER_OPTIONS.asset;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.blocks])) {
    return MAP_LAYER_OPTIONS.block;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.ccusProjects])) {
    return getCcusProjectStyleOptions();
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.co2Emitters])) {
    return getCo2EmitterStyleOptions(initialMetric, initialSizeType);
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.fields])) {
    return MAP_LAYER_OPTIONS.field;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.facilities])) {
    return MAP_LAYER_OPTIONS.facility;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.leases])) {
    return MAP_LAYER_OPTIONS.lease;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.pipelines])) {
    return MAP_LAYER_OPTIONS.pipeline;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.pipelineSegments])) {
    return MAP_LAYER_OPTIONS.pipelineSegment;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.storageSites])) {
    return MAP_LAYER_OPTIONS.storageSite;
  } else if (mapLayerMatch(layer, [MAP_LAYER_TYPES.wells])) {
    return MAP_LAYER_OPTIONS.well;
  }
};

// get style options when layer style changes via metric or size type change
export const getStyleOptions = (layer, metric, size) => {
  switch (layer) {
    case MAP_LAYER_TYPES.co2Emitters:
      return [getMetricOptions(metric), getSizeOptions(size)];
  }
};
