import { LineGraph } from '../../../../components/Graphs';
import { PIPELINE_THROUGHPUT_ASSET_VIEW_GRAPH_QUERY } from './operations';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles(() => ({
  graph: {
    backgroundColor: 'white',
    margin: '5px',
    padding: '5px',
  },
}));

export default ({ pipelineSegmentId }) => {
  const classes = useStyles();

  const { loading, data } = useQuery(PIPELINE_THROUGHPUT_ASSET_VIEW_GRAPH_QUERY, {
    variables: {
      id: pipelineSegmentId,
    },
  });

  return (
    <>
      {!loading && data.graphPipelineThroughputAssetView.throughputs.length > 0 ? (
        <div className={classes.graph}>
          <LineGraph
            key={`asset-view-graph-${pipelineSegmentId}`}
            csv={data.graphPipelineThroughputAssetView.throughputs}
            labels={data.graphPipelineThroughputAssetView.labels}
            title="Pipeline Throughput"
            ylabel={data.graphPipelineThroughputAssetView.ylabel}
            stackedGraph={true}
          />
        </div>
      ) : null}
    </>
  );
};
