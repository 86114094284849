import {
  DEFAULT_HIGHLIGHT_COLOR,
  OG_MAP_ASSET_COLORS,
  OG_MAP_ASSET_OTHER_COLOR,
} from '../../components/Map/Utils/colors';

import { useMemo } from 'react';

export const useAssetBorderColors = (variables) => {
  return useMemo(() => {
    const colors = [...OG_MAP_ASSET_COLORS];
    const result = variables.company.reduce(
      (acc, companyId, index) => {
        const colorAndTitle = {
          color: index > 4 ? OG_MAP_ASSET_OTHER_COLOR : colors.shift(),
          title:
            variables.companyNames[index]?.length > 22
              ? variables.companyNames[index].slice(0, 19) + '...'
              : variables.companyNames[index],
        };

        acc.assetBorderColorsByCompany['' + companyId] = colorAndTitle;

        if (index > 4) return acc;
        acc.assetBorderColorsArray = [...acc.assetBorderColorsArray, colorAndTitle];
        return acc;
      },
      { assetBorderColorsByCompany: {}, assetBorderColorsArray: [] },
    );
    result.assetBorderColorsArray.push({ color: DEFAULT_HIGHLIGHT_COLOR, title: 'Participant' });
    if (variables.company.length > 4) {
      // just 5 company names (related to assets where they are operators) are shown in secondary legend,
      // all others are grouped under 'Other'
      result.assetBorderColorsArray.push({ color: OG_MAP_ASSET_OTHER_COLOR, title: 'Other' });
    }
    return result;
  }, [variables]);
};
