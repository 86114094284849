import React, { useContext, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Logo from '../../../assets/images/FullLogo.svg';
import Paper from '@mui/material/Paper';
import PasswordRules from './passwordRules';
import { RESET_PASSWORD } from './operations';
import { ROOT_PATH } from '../../utils/constants';
import SnackbarContext from '../../contexts/SnackbarContext';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { joinGqlErrorMessage } from '../../utils/gqlErrors';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  form: {
    height: '540px',
    width: '450px',
    padding: '45px',
    boxSizing: 'border-box',
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);

  const params = new URLSearchParams(window.location.search);
  const resetPasswordToken = params.get('reset_password_token');
  const [variables, setVariables] = useState({
    password: '',
    passwordConfirmation: '',
    resetPasswordToken,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const newVariables = { ...variables };
    newVariables[name] = value;
    setVariables(newVariables);
  };
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    variables,
    onCompleted: () => {
      setSnack({
        open: true,
        message: 'Successfully changed password.',
        severity: 'success',
      });
      history.push(ROOT_PATH);
    },
    onError: ({ graphQLErrors }) => {
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.form}>
        <img id="logo" src={Logo} alt="Welligence-Logo" width="200px" height="75px" />
        <Typography variant="h4" style={{ margin: '30px 0 15px' }}>
          Password Reset
        </Typography>
        <PasswordRules />
        <Grid
          container
          direction="column"
          align="center"
          spacing={3}
          sx={{
            justifyContent: 'space-around',
          }}
        >
          <Grid align="center" size={12}>
            <TextField
              style={{ paddingBottom: '8px' }}
              className={classes.input}
              value={variables.password}
              onChange={handleChange}
              fullWidth
              name="password"
              label="Password"
              variant="filled"
              type="password"
            />
            <TextField
              style={{ paddingTop: '8px' }}
              className={classes.input}
              value={variables.passwordConfirmation}
              onChange={handleChange}
              fullWidth
              name="passwordConfirmation"
              label="Password Confirmation"
              variant="filled"
              type="password"
            />
          </Grid>
          <Grid size={12}>
            <Button variant="contained" color="secondary" onClick={resetPassword}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ResetPassword;
