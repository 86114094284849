import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));
