import {
  BIOGENIC_CO2_EMITTER_SUMMARY_ITEM,
  EMISSIONS_CO2_EMITTER_SUMMARY_ITEM,
  NAME_CO2_EMITTER_SUMMARY_ITEM,
  OWNERS_CO2_EMITTER_SUMMARY_ITEM,
  PROCESS_CO2_EMITTER_SUMMARY_ITEM,
  STATIONARY_CO2_EMITTER_SUMMARY_ITEM,
} from '../../../../../../components/SummaryList/SummaryItems/co2Emitter';
import {
  COUNTRY_NAME_SUMMARY_ITEM,
  INDUSTRY_SUMMARY_ITEM,
} from '../../../../../../components/SummaryList/SummaryItems/base';

export default [
  NAME_CO2_EMITTER_SUMMARY_ITEM,
  OWNERS_CO2_EMITTER_SUMMARY_ITEM,
  COUNTRY_NAME_SUMMARY_ITEM,
  INDUSTRY_SUMMARY_ITEM,
  EMISSIONS_CO2_EMITTER_SUMMARY_ITEM,
  STATIONARY_CO2_EMITTER_SUMMARY_ITEM,
  PROCESS_CO2_EMITTER_SUMMARY_ITEM,
  BIOGENIC_CO2_EMITTER_SUMMARY_ITEM,
];
