import {
  getLabelUnit,
  getLabelUnitValueFromTemplate,
} from '../../../../../../components/SummaryList/SummaryItems/utils';

import { MAP_ENTITY_TYPES } from '../../../../../../components/Map/Utils/constants';
import block from './block';
import ccusProject from './ccusProject';
import co2Emitter from './co2Emitter';
import field from './field';
import pipeline from './pipeline';
import storageSite from './storageSite';
import well from './well';

const getTemplate = (entityType) => {
  switch (entityType) {
    case 'asset':
    case 'block':
    case 'lease':
      return block(entityType);
    case 'ccus_project':
      return ccusProject;
    case 'co2_emitter':
      return co2Emitter;
    case 'field':
      return field;
    case 'pipeline':
      return pipeline;
    case 'storage_site':
      return storageSite;
    case 'well':
      return well;
    default:
      return;
  }
};

const getItemRow = (templateItem, itemData) => {
  const { label, unit, value } = getLabelUnitValueFromTemplate(templateItem, itemData);
  const displayedLabel = getLabelUnit(label, unit);
  return value ? `<p><span>${displayedLabel}:</span> ${value}</p>` : '';
};

const entityTypesHaveDownload = [MAP_ENTITY_TYPES.co2Emitter, MAP_ENTITY_TYPES.storageSite];

const getReportButtonLabel = (entityType) => {
  switch (entityType) {
    case MAP_ENTITY_TYPES.co2Emitter:
      return 'Abatement';
    case MAP_ENTITY_TYPES.storageSite:
      return 'Storage';
    default:
      return 'Download';
  }
};

export const createHtmlFromEntityType = (entityType, itemData, hasDownload, downloadId) => {
  let html = '';
  const template = getTemplate(entityType);
  template.forEach((templateItem) => {
    html += getItemRow(templateItem, itemData);
  });

  let downloadString = '';
  // create download button for excel models
  if (entityTypesHaveDownload.includes(entityType) && hasDownload) {
    const buttonLabel = getReportButtonLabel(entityType);
    downloadString += `<button class='ccus-mapbox-download-button' id='${downloadId}'"><span class="material-icons">download</span>&nbsp;&nbsp;${buttonLabel} Model</button>`;
  }

  return `<div class='ccus-mapbox-info-panel'>${html}${downloadString}</div>`;
};
