import { OAUTH2_LOGIN_MUTATION } from '../operations';
import { useMutation } from '@apollo/client';

export const useOAuth2Login = ({ provider, code }) => {
  const [oAuth2Login, { data, loading, error }] = useMutation(OAUTH2_LOGIN_MUTATION, {
    variables: {
      provider,
      code,
    },
    skip: !code || !provider,
  });

  return { oAuth2Login, data, loading, error };
};
