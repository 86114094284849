import {
  BASE_LAYERS,
  CCUS_PROJECT_LAYER,
  CO2_EMITTER_LAYER,
  MAP_LAYERS,
  STORAGE_SITE_LAYER,
  getLegendItems,
  getMapLayerFilters,
  layerProps,
  mapSettings,
} from './constants';
import {
  BarGraphContextEmissions,
  BarGraphContextProject,
  BarGraphContextStorage,
} from '../BarGraphs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { CCUS_GOOGLE_ANALYTICS_TAG } from '../../constants';
import { CCUS_SUBSCRIPTION } from '../../../../utils/constants';
import GraphContainer from '../../../../components/Graphs/graphContainer';
import { MAP_TILESET_TYPES } from '../../../../components/Map/Utils/constants';
import Map from '../../../../components/Map';
import { MapContext } from '.';
import { SECURE_CCUS_DOWNLOAD_QUERY } from '../operations';
import SnackbarContext from '../../../../contexts/SnackbarContext';
import { createHtmlFromEntityType } from './info/template';
import { getLatLongFromPoint } from '../../../../utils/helpers';
import { getQuery } from './info/operations';
import makeStyles from '@mui/styles/makeStyles';
import { openDownloadUrl } from '../../../../components/Downloads/helpers';
import uniq from 'lodash/uniq';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  legendContainer: {
    // 83.333333% matches the xs width of the map component when in fullscreen
    // so the legend max width is 75% of the map width
    maxWidth: 'calc(83.333333% *.75)',
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '1000px',
    maxWidth: '1400px',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
}));

export default ({
  filterIsEmpty,
  mapFilters,
  open,
  setOpen,
  transitionFinished,
  setTransitionFinished,
}) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const { graphType, mapLayers, setMapLayers, setOpenControlsModal, sizeType, title } =
    useContext(MapContext);
  const { facilityIsoCodes, facilityIds } = useContext(BarGraphContextEmissions);
  const { projectIsoCodes, projectIds } = useContext(BarGraphContextProject);
  const { storageIsoCodes, storageIds } = useContext(BarGraphContextStorage);

  const [showLegend, setShowLegend] = useState(true);
  const [map, setMap] = useState(null);
  const [queriedItem, setQueriedItem] = useState(null);
  const [prevCountryFilterIsoCodes, setPrevCountryFilterIsoCodes] = useState(
    mapFilters.countries.map((country) => country.isoCode),
  );

  // get the legend to render based on graph type
  const legendItems = useMemo(() => getLegendItems(graphType.value), [graphType]);

  // set the layerStyle object
  const layerStyle = useMemo(
    () => ({
      layer: CO2_EMITTER_LAYER,
      metric: graphType.value,
      sizeType,
    }),
    [graphType, sizeType],
  );

  // figure out which layers to hide when map is loaded
  const hideLayers = useMemo(
    () =>
      MAP_LAYERS.filter(
        (l) =>
          !mapLayers.find(
            (mapLayer) =>
              mapLayer.value === l ||
              ((l === 'assets' || l === 'leases') && mapLayer.value === 'blocks'),
          ),
      ),
    [mapLayers],
  );

  // LAYER FILTERS
  // if filters are empty, return empty array since we don't need to filter the map
  // iso codes are aggregated from facilities, projects, and storages to filter the basic layer types
  const isoCodes = useMemo(() => {
    if (filterIsEmpty) {
      return [];
    }
    const uniqueIsoCodes = uniq([...facilityIsoCodes, ...projectIsoCodes, ...storageIsoCodes]);

    // we have to add USA in order not to filter out assets, leases, fields, wells, etc. when viewing USA48
    if (uniqueIsoCodes.find((isoCode) => isoCode === 'USA48')) {
      uniqueIsoCodes.push('USA');
    }

    return uniqueIsoCodes.length > 0 ? uniqueIsoCodes : ['NOISO'];
  }, [filterIsEmpty, facilityIsoCodes, projectIsoCodes, storageIsoCodes]);

  // facility, project, and storage layers are filtered by the IDs of the entityType graphed
  // array of map layer filters are created to pass to map
  const mapLayerFilters = useMemo(() => {
    const mapFilterOptions = [
      { field: 'countryIsoCode', layers: BASE_LAYERS, value: isoCodes },
      {
        field: 'id',
        layers: [CO2_EMITTER_LAYER],
        // if the filter is empty, no filter needs to be applied
        // if the filter is being used but returns an empty set,
        // we need to apply a filter that will return nothing
        value: filterIsEmpty ? [] : facilityIds.length > 0 ? facilityIds : [0],
      },
      {
        field: 'id',
        layers: [CCUS_PROJECT_LAYER],
        value: filterIsEmpty ? [] : projectIds.length > 0 ? projectIds : [0],
      },
      {
        field: 'id',
        layers: [STORAGE_SITE_LAYER],
        value: filterIsEmpty ? [] : storageIds.length > 0 ? storageIds : [0],
      },
    ];
    return getMapLayerFilters(mapFilterOptions);
  }, [filterIsEmpty, isoCodes, facilityIds, projectIds, storageIds]);

  // LAYER ENTITY CLICK FUNCTIONS
  // query for getting info to diisplay on the map
  const { data } = useQuery(getQuery(queriedItem?.entityType), {
    skip: !(queriedItem?.id || queriedItem?.legacyId),
    variables: {
      id: queriedItem?.id,
      legacyId: queriedItem?.legacyId,
      countryIsoCode: queriedItem?.countryIsoCode,
      includeDownload: true,
      feature: CCUS_SUBSCRIPTION,
    },
    onError: (error) => {
      setSnack({
        message: error.message,
        severity: 'error',
        open: true,
      });
    },
  });

  // query for downloading excel model
  const [getReportDownload] = useLazyQuery(SECURE_CCUS_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureCcusDownload;
      if (url && filename) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        openDownloadUrl(url, filename);
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  // callback for when entity type is clicked on layer
  const layerClickCallback = ({ properties, coordinates }) => {
    setQueriedItem({ ...properties, coordinates });
  };

  // once data is loaded create info popup on map
  useEffect(() => {
    if (data) {
      let item = Object.values(data)[0];
      // If the item is a facility or storage site, we need key into the item data
      const { facility, hasDownload, storageSite } = item;
      if (hasDownload !== undefined) {
        item = facility || storageSite;
      }

      const { coordinates, entityType } = queriedItem || {};
      if (item && coordinates) {
        const downloadId = open ? 'ccus-download' : 'ccus-modal-download';
        const infoHtml = createHtmlFromEntityType(entityType, item, hasDownload, downloadId);
        map.setPopup(infoHtml, coordinates);
        // attach download function to button if exists
        document.getElementById(downloadId)?.addEventListener('click', () => {
          getReportDownload({ variables: { id: item.id, entityType: entityType } });
        });
      }
    }
  }, [data, queriedItem]);

  // resize map when fullscreen is toggled
  // dispatch a window resize event to trigger plotly to redraw charts to fit new size
  useEffect(() => {
    if (transitionFinished) {
      if (map) map.resize();
      window.dispatchEvent(new Event('resize'));
      setTransitionFinished(false);
    }
  }, [transitionFinished]);

  // pan to country when a new country is selected
  useEffect(() => {
    const currCountryFilterIsoCodes = mapFilters.countries.map((c) => c.isoCode);
    // check if we added a new country filter
    if (currCountryFilterIsoCodes.length > prevCountryFilterIsoCodes.length) {
      // find the country that was added
      const country = mapFilters.countries.find(
        (c) => !prevCountryFilterIsoCodes.includes(c.isoCode),
      );
      // get country geom and zoom level
      const { geom, zoom } = country;
      const center = getLatLongFromPoint(geom);
      // set map to new country
      map.flyTo({
        center,
        zoom,
      });
    }
    // set country iso code state to current country filters
    setPrevCountryFilterIsoCodes(currCountryFilterIsoCodes);
  }, [mapFilters]);

  return (
    <GraphContainer
      open={open}
      setOpen={() => setOpen(true)}
      setClose={() => setOpen(false)}
      setOpenControlsModal={setOpenControlsModal}
      setShowPlotlyLegend={setShowLegend}
      showPlotlyLegend={open && showLegend}
      showPlotlyLegendToggle={open}
      title={title}
      loading={false}
      downloadCallback={() => {}}
      googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
      disableDownload
    >
      <Map
        map={map}
        control
        enableInfoPopup
        layers={MAP_LAYERS}
        layerClickCallback={layerClickCallback}
        layerFilters={mapLayerFilters}
        layerStyle={layerStyle}
        hideLayers={hideLayers}
        legendProps={{
          enabled: open && showLegend,
          keepMounted: true,
          tabbed: true,
          tabbedItems: legendItems,
        }}
        metric={graphType.value}
        ruler
        setMap={setMap}
        settingsProps={{
          ...mapSettings,
          enabled: open,
          layerProps: {
            ...layerProps,
            toggleLayerCallback: setMapLayers,
          },
        }}
        sizeType={sizeType}
        style="monochrome"
        styles={classes}
        tilesetType={MAP_TILESET_TYPES.ccus}
      />
    </GraphContainer>
  );
};
