import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import { AdvancedFilters, FilterKeys } from '../../components/Filters';
import {
  BASIC_FILTER_TYPES,
  CCUS_CONTEXT,
  CCUS_GOOGLE_ANALYTICS_TAG,
  CHECK_LIST_FILTER_TYPES,
} from './constants';
import {
  BarGraphContextCosts,
  BarGraphContextEmissions,
  BarGraphContextProject,
  BarGraphContextStorage,
  BarGraphProviderCosts,
  BarGraphProviderEmissions,
  BarGraphProviderProject,
  BarGraphProviderStorage,
} from './Charts/BarGraphs';
import {
  DIM_CCUS_DEVELOPMENT_STATUS_QUERY,
  DIM_CCUS_PROJECT_SCALE_QUERY,
  DIM_CCUS_PROJECT_TYPE_QUERY,
  DIM_CCUS_SEPARATION_TYPE_QUERY,
  DIM_COMPANY_TARGET_QUERY,
  DIM_SHORE_STATUS_QUERY,
  DIM_STORAGE_BLOCK_STATUS_QUERY,
  DIM_STORAGE_TYPE_QUERY,
  FACT_FACILITY_EMISSION_MAX_VALUES_QUERY,
} from '../../components/Filters/operations';
import { FilterMaxKeys, defaultSliderFilterTypes } from '../../components/Filters/filterTypes';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { analytics, defaultDimensions, dimensions } from '../../utils/analytics';

import Charts from './Charts';
import Driver from 'driver.js';
import { FilterBarWrapper } from '../../components/FilterBarWrapper';
import GetAppIcon from '@mui/icons-material/GetApp';
import { PageActions } from '../../components/PageActions';
import WalkthroughContext from '../../contexts/WalkthroughContext';
import { ccusDriver } from './driver';
import { cloneDeep } from 'lodash';
import { downloadXlsx } from '../../components/Downloads/helpers';
import { getFilters } from './Charts/BarGraphs/constants';
import { getUserId } from '../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    ...theme.sizes.fullPage,
    flexDirection: 'column',
  },
  filterItemBasic: {
    minWidth: '1px',
    flexBasis: '16.66%',
    [theme.breakpoints.down('lg')]: {
      flexBasis: '33.33%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
  },
  filterItemAdvanced: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingBottom: '5px',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: 'unset',
    },
  },
}));

export const defaultFilters = {
  [FilterKeys.captureCost]: [],
  [FilterKeys.ccusDevelopmentStatuses]: [],
  [FilterKeys.ccusProjects]: [],
  [FilterKeys.ccusProjectScales]: [],
  [FilterKeys.ccusProjectTypes]: [],
  [FilterKeys.ccusSeparationTypes]: [],
  [FilterKeys.cO2Concentration]: [],
  [FilterKeys.companies]: [],
  [FilterKeys.companyTargets]: [],
  [FilterKeys.countries]: [],
  [FilterKeys.facilities]: [],
  [FilterKeys.facilityTotalCO2Emissions]: [],
  [FilterKeys.hasAbatement]: false,
  [FilterKeys.industries]: [],
  [FilterKeys.storageBlockStatuses]: [],
  [FilterKeys.storageCost]: [],
  [FilterKeys.storageShoreStatuses]: [],
  [FilterKeys.storageSites]: [],
  [FilterKeys.storageTypes]: [],
  [FilterKeys.totalAbatementCost]: [],
  [FilterKeys.transportationCost]: [],
};

const getDefaultFilters = () => {
  const filters = JSON.parse(sessionStorage.getItem(CCUS_CONTEXT));
  if (
    filters &&
    Object.keys(defaultFilters).every((filter) => Object.keys(filters).includes(filter))
  ) {
    return filters;
  }
  return defaultFilters;
};

const CcusPage = ({ filters, mapFilters, setFilterState }) => {
  const classes = useStyles();
  const { walkthrough, setWalkthrough, setSeenNewFeature } = useContext(WalkthroughContext);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(getDefaultFilters());

  useEffect(() => {
    if (walkthrough) {
      if (filtersModalOpen) {
        setFiltersModalOpen(false);
      }
      const startDriver = () => {
        const driver = new Driver({
          allowClose: false,
          onReset: () => {
            setWalkthrough(false);
            setSeenNewFeature(CCUS_GOOGLE_ANALYTICS_TAG);
          },
        });
        ccusDriver(driver);
      };
      startDriver();
    }
  }, [walkthrough]);

  useEffect(() => {
    sessionStorage.setItem(CCUS_CONTEXT, JSON.stringify(filters));
  }, [filters]);

  const clearFilters = () => {
    const newFilters = cloneDeep(defaultFilters);
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: CCUS_GOOGLE_ANALYTICS_TAG,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'clear_filter',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setFilterState(newFilters);
    setAdvancedFilters(newFilters);
  };

  const setFilters = (newFilters) => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: CCUS_GOOGLE_ANALYTICS_TAG,
      event_action: ANALYTICS_EVENT_ACTIONS.filter,
      event_label: analytics(newFilters),
      userId: getUserId(),
      ...dimensions(newFilters, CCUS_CONTEXT),
    });
    setFilterState(newFilters);
    setAdvancedFilters(newFilters);
  };

  const { variables: costsVariables } = useContext(BarGraphContextCosts);
  const { variables: emissionsVariables } = useContext(BarGraphContextEmissions);
  const { variables: projectVariables } = useContext(BarGraphContextProject);
  const { variables: storageVariables } = useContext(BarGraphContextStorage);

  const downloadAllChartsCallback = (errorHandler) => {
    const path = 'ccus/export-all-bar-graphs/';
    const body = {
      costsVariables,
      emissionsVariables,
      projectVariables,
      storageVariables,
    };
    const downloadName = `CCUS_Chart_Data.xlsx`;

    downloadXlsx(path, body, downloadName, errorHandler);
  };

  const downloadRawDataCallback = (errorHandler) => {
    const path = 'ccus/export-raw-data/';
    const body = {
      emissionsVariables,
      projectVariables,
      storageVariables,
    };
    const downloadName = `CCUS_Raw_Data.xlsx`;

    downloadXlsx(path, body, downloadName, errorHandler);
  };

  const handleAdvancedFiltersClick = () => {
    // Keep advanced filters in sync with basic filters
    const basicFilters = {
      [FilterKeys.ccusProjects]: filters[FilterKeys.ccusProjects],
      [FilterKeys.companies]: filters[FilterKeys.companies],
      [FilterKeys.countries]: filters[FilterKeys.countries],
      [FilterKeys.facilities]: filters[FilterKeys.facilities],
      [FilterKeys.industries]: filters[FilterKeys.industries],
      [FilterKeys.storageSites]: filters[FilterKeys.storageSites],
    };
    setAdvancedFilters({ ...advancedFilters, ...basicFilters });
    setFiltersModalOpen(true);
  };

  const { data: companyTargetData } = useQuery(DIM_COMPANY_TARGET_QUERY, {
    onCompleted: (data) => {
      defaultFilters.companyTargets = data.dimCompanyTarget;
    },
  });

  const { data: developmentStatusData } = useQuery(DIM_CCUS_DEVELOPMENT_STATUS_QUERY, {
    onCompleted: (data) => {
      defaultFilters.ccusDevelopmentStatuses = data.dimCcusDevelopmentStatus;
    },
  });

  const { data: projectTypeData } = useQuery(DIM_CCUS_PROJECT_TYPE_QUERY, {
    onCompleted: (data) => {
      defaultFilters.ccusProjectTypes = data.dimCcusProjectType;
    },
  });

  const { data: separationTypeData } = useQuery(DIM_CCUS_SEPARATION_TYPE_QUERY, {
    onCompleted: (data) => {
      defaultFilters.ccusSeparationTypes = data.dimCcusSeparationType;
    },
  });

  const { data: storageBlockStatusData } = useQuery(DIM_STORAGE_BLOCK_STATUS_QUERY, {
    onCompleted: (data) => {
      defaultFilters.storageBlockStatuses = data.dimStorageBlockStatus;
    },
  });

  const { data: projectScaleData } = useQuery(DIM_CCUS_PROJECT_SCALE_QUERY, {
    onCompleted: (data) => {
      defaultFilters.ccusProjectScales = data.dimCcusProjectScale;
    },
  });

  const { data: storageTypesData } = useQuery(DIM_STORAGE_TYPE_QUERY, {
    onCompleted: (data) => {
      defaultFilters.storageTypes = data.dimStorageType;
    },
  });

  const { data: shoreStatusData } = useQuery(DIM_SHORE_STATUS_QUERY, {
    onCompleted: (data) => {
      defaultFilters.storageShoreStatuses = data.dimShoreStatus;
    },
  });

  const { data: maxValueData, loading: sliderFilterLoading } = useQuery(
    FACT_FACILITY_EMISSION_MAX_VALUES_QUERY,
    {
      onCompleted: ({ factFacilityEmissionMaxValues }) => {
        defaultSliderFilterTypes.forEach((filter) => {
          if (defaultFilters[filter.key].length === 0) {
            defaultFilters[filter.key] = [0, factFacilityEmissionMaxValues[filter.maxKey]];
          }
        });
      },
    },
  );

  const checkIfFilterIsEmpty = () =>
    Object.keys(filters).every((filter) => {
      const filterValue = filters[filter];
      const filterValueLength = filterValue.length;
      // filter is empty
      if (filterValueLength === 0) {
        return true;
      }

      // check if defaults are selected
      switch (filter) {
        case FilterKeys.ccusDevelopmentStatuses:
          return filterValueLength === developmentStatusData.dimCcusDevelopmentStatus.length;
        case FilterKeys.ccusProjectScales:
          return filterValueLength === projectScaleData.dimCcusProjectScale.length;
        case FilterKeys.ccusProjectTypes:
          return filterValueLength === projectTypeData.dimCcusProjectType.length;
        case FilterKeys.ccusSeparationTypes:
          return filterValueLength === separationTypeData.dimCcusSeparationType.length;
        case FilterKeys.companyTargets:
          return filterValueLength === companyTargetData.dimCompanyTarget.length;
        case FilterKeys.storageBlockStatuses:
          return filterValueLength === storageBlockStatusData.dimStorageBlockStatus.length;
        case FilterKeys.storageShoreStatuses:
          return filterValueLength === shoreStatusData.dimShoreStatus.length;
        case FilterKeys.storageTypes:
          return filterValueLength === storageTypesData.dimStorageType.length;
        case FilterKeys.captureCost:
        case FilterKeys.facilityTotalCO2Emissions:
        case FilterKeys.storageCost:
        case FilterKeys.totalAbatementCost:
        case FilterKeys.transportationCost:
          return (
            filterValue[0] === 0 &&
            filterValue[1] === maxValueData.factFacilityEmissionMaxValues[FilterMaxKeys[filter]]
          );
        default:
          return !filterValue;
      }
    });

  const filterIsEmpty = useMemo(
    () =>
      // if all data hasn't returned yet, say filters are empty until verifiable
      !(
        companyTargetData &&
        developmentStatusData &&
        projectTypeData &&
        separationTypeData &&
        storageBlockStatusData &&
        projectScaleData &&
        storageTypesData &&
        shoreStatusData &&
        maxValueData
      )
        ? true
        : checkIfFilterIsEmpty(),
    [
      filters,
      companyTargetData,
      developmentStatusData,
      projectTypeData,
      separationTypeData,
      storageBlockStatusData,
      projectScaleData,
      storageTypesData,
      shoreStatusData,
      maxValueData,
    ],
  );

  return (
    <div className={classes.root}>
      <FilterBarWrapper
        filters={filters}
        applyFilters={setFilters}
        basicFilterTypes={BASIC_FILTER_TYPES}
        context={CCUS_CONTEXT}
        googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
      >
        <AdvancedFilters
          title="Advanced"
          context={CCUS_CONTEXT}
          googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
          open={filtersModalOpen}
          setOpen={setFiltersModalOpen}
          defaultFilters={advancedFilters}
          clearFilters={() => setAdvancedFilters(cloneDeep(defaultFilters))}
          apply={setFilters}
          filterItemClass={classes.filterItemAdvanced}
          filterTypes={BASIC_FILTER_TYPES}
          checkBoxFilterTypes={[]}
          checkListFilterTypes={CHECK_LIST_FILTER_TYPES}
          checkListItems={{
            companyTargets: companyTargetData?.dimCompanyTarget || [],
            ccusProjectScales: projectScaleData?.dimCcusProjectScale || [],
            ccusProjectTypes: projectTypeData?.dimCcusProjectType || [],
            ccusSeparationTypes: separationTypeData?.dimCcusSeparationType || [],
            ccusDevelopmentStatusesTypes: developmentStatusData?.dimCcusDevelopmentStatus || [],
            storageBlockStatuses: storageBlockStatusData?.dimStorageBlockStatus || [],
            storageShoreStatuses: shoreStatusData?.dimShoreStatus || [],
            storageTypes: storageTypesData?.dimStorageType || [],
          }}
          minMaxFilterTypes={[]}
          sliderFilterTypes={defaultSliderFilterTypes}
          sliderFiltersLabel="Point Source Emissions"
          sliderFilterMaxValues={maxValueData?.factFacilityEmissionMaxValues}
          sliderFilterLoading={sliderFilterLoading}
          checkListContainerGridColumns="1fr 2fr 1fr"
        />
        <PageActions
          onOpenFilters={handleAdvancedFiltersClick}
          onClearFilters={clearFilters}
          presets={{
            filters: filters,
            setFilters: setFilters,
            context: CCUS_CONTEXT,
            googleAnalyticsTag: CCUS_GOOGLE_ANALYTICS_TAG,
            includeShare: true,
          }}
          mainAction={{
            tooltip: 'Download Pre-Aggregated Data Returned By Filter Set',
            icon: <GetAppIcon />,
            onClick: downloadRawDataCallback,
            label: 'Raw Data',
          }}
          actions={[
            {
              icon: <GetAppIcon />,
              label: 'All Chart Data',
              onClick: downloadAllChartsCallback,
            },
          ]}
        />
      </FilterBarWrapper>
      <Charts filters={filters} filterIsEmpty={filterIsEmpty} mapFilters={mapFilters} />
    </div>
  );
};

const CCUS = () => {
  const [filters, setFilterState] = useState(getDefaultFilters());

  useEffect(() => {
    sessionStorage.setItem(CCUS_CONTEXT, JSON.stringify(filters));
  }, [filters]);

  const { emissionFilters, mapFilters, projectFilters, storageFilters } = useMemo(
    () => getFilters(filters),
    [filters],
  );

  return (
    <BarGraphProviderEmissions filters={emissionFilters}>
      <BarGraphProviderProject filters={projectFilters}>
        <BarGraphProviderStorage filters={storageFilters}>
          <BarGraphProviderCosts filters={emissionFilters}>
            <CcusPage filters={filters} mapFilters={mapFilters} setFilterState={setFilterState} />
          </BarGraphProviderCosts>
        </BarGraphProviderStorage>
      </BarGraphProviderProject>
    </BarGraphProviderEmissions>
  );
};

export default CCUS;
