import React, { useEffect } from 'react';

import { DATA_DOWNLOADS_QUERY } from './operations';
import { DataGrid } from '@mui/x-data-grid';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { Grid2 as Grid } from '@mui/material';
import Loading from '../../../components/Loading';
import makeStyles from '@mui/styles/makeStyles';
import template from './template';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // overflow: 'auto',
    // minHeight: '100%',
    width: '80%',
    ...theme.palette.scrollbar,
  },
}));

export default ({ filters }) => {
  const classes = useStyles();
  const [dataGridColumns, setDataGridColumns] = React.useState([]);
  const [dataGridRows, setDataGridRows] = React.useState([]);

  const downloadType = filters?.downloadType?.name;
  const { data, loading } = useQuery(DATA_DOWNLOADS_QUERY(downloadType), {
    variables: {
      downloadType: downloadType,
      countryIsoCodes: filters?.countries.map((country) => country.isoCode),
    },
  });

  useEffect(() => {
    if (!loading) {
      let { columns, rows } = template(downloadType, data?.dataDownloads);
      setDataGridColumns(columns);
      setDataGridRows(rows);
    }
  }, [downloadType, data]);

  return (
    <ErrorBoundary>
      <Grid className={classes.root} container size={12}>
        {downloadType ? (
          loading ? (
            <Loading />
          ) : (
            <DataGrid rows={dataGridRows} columns={dataGridColumns} />
          )
        ) : null}
      </Grid>
    </ErrorBoundary>
  );
};
