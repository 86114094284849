import React, { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import { LOGIN_MUTATION } from '../Login/operations';
import Modal from '../../components/Modals';
import PasswordRules from '../../components/ForgotPassword/passwordRules';
import SnackbarContext from '../../contexts/SnackbarContext';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { UPDATE_ME_MUTATION } from './operations';
import makeStyles from '@mui/styles/makeStyles';
import { persistUserCredentials } from '../../utils/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.primaryBackground,
    padding: theme.spacing(10),
    boxSizing: 'border-box',
  },
  button: {
    ...theme.palette.button,
  },
  contents: {
    backgroundColor: 'white',
    padding: theme.spacing(6),
    minWidth: '500px',
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  field: {
    '& root': {
      boxShadow:
        '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    },
    '& input': {
      backgroundColor: '#fafafa',
      fontSize: '14px',
      borderRadius: '5px',
    },
  },
  modal: {
    height: '400px',
    width: '650px',
  },
  modalContent: {
    justifyContent: 'space-between',
    paddingLeft: '100px',
    paddingRight: '100px',
    height: '185px',
  },
  requestTokenButton: {
    ...theme.palette.actionCustom,
  },
}));

const defaultState = {
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  downloadMessage: '',
  password: '',
  newPassword: '',
  newPasswordConf: '',
  resetToken: '',
};

const Settings = () => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const { setSnack } = useContext(SnackbarContext);

  const [state, setState] = useState(defaultState);
  const [passwordModal, setModal] = useState(false);
  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const newState = { ...state };
    newState[name] = value;
    setState(newState);
  };

  const [updateMe] = useMutation(UPDATE_ME_MUTATION, {
    variables: {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      newPassword: state.newPassword || null,
      newPasswordConf: state.newPasswordConf || null,
    },
    onCompleted: () => {
      setSnack({
        severity: 'success',
        open: true,
        message: 'Successfully updated account!',
      });
    },
    onError: ({ graphQLErrors }) => {
      let errMsgs = [];
      graphQLErrors.forEach((gqlErr) => {
        errMsgs.push(
          gqlErr.extensions?.detailed_errors
            ? gqlErr.extensions.detailed_errors.join(' ')
            : gqlErr.message,
        );
      });
      setSnack({
        open: true,
        message: `${errMsgs.join(' ')}`,
        severity: 'error',
      });
    },
  });

  // TODO: change so that it is only the original email that is passed to the login mutation
  const [userLogin] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: state.email,
      password: state.password,
    },
    onCompleted: ({ userLogin }) => {
      persistUserCredentials(
        userLogin.credentials.accessToken,
        userLogin.credentials.client,
        userLogin.credentials.expiry,
        userLogin.tokenType,
        userLogin.authenticatable.email,
      );

      updateMe();
    },
    onError: () => {
      setSnack({
        severity: 'error',
        open: true,
        message: 'Password is incorrect',
      });
    },
  });

  useEffect(() => {
    if (currentUser.firstName && currentUser.lastName && currentUser.email) {
      setState({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        downloadMessage: currentUser.downloadMessage,
      });
    }
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Grid
          className={classes.contents}
          container
          direction="row"
          spacing={4}
          size={7}
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          <Grid
            container
            size={12}
            sx={{
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4" gutterBottom>
              Account Info
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid style={{ paddingBottom: '0px' }} size={8}>
            <Typography variant="h6" gutterBottom>
              Update Personal Information
            </Typography>
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.field}
              fullWidth
              label="First Name"
              name="firstName"
              variant="filled"
              onChange={handleChange}
              value={state.firstName}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.field}
              fullWidth
              label="Last Name"
              name="lastName"
              variant="filled"
              onChange={handleChange}
              value={state.lastName}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.field}
              fullWidth
              label="Company Name"
              name="Company Name"
              variant="filled"
              value={currentUser.company ? currentUser.company : ''}
              disabled
            />
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.field}
              fullWidth
              label="Email"
              name="email"
              variant="filled"
              onChange={handleChange}
              value={state.email}
              disabled
              slotProps={{
                htmlInput: {
                  form: {
                    autocomplete: 'off',
                  },
                },
              }}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.field}
              fullWidth
              label="Account Type"
              name="accountType"
              variant="filled"
              disabled
            />
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.field}
              fullWidth
              label="Account Access"
              name="accountAccess"
              variant="filled"
              disabled
            />
          </Grid>
          <Grid style={{ paddingBottom: '0px' }} size={8}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <PasswordRules />
          </Grid>
          <Grid size={5}>
            <TextField
              className={classes.field}
              fullWidth
              label="New Password"
              name="newPassword"
              variant="filled"
              type="password"
              onChange={handleChange}
              slotProps={{
                htmlInput: {
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                },
              }}
            />
          </Grid>
          <Grid size={5}>
            <TextField
              className={classes.field}
              fullWidth
              label="Retype Password"
              name="newPasswordConf"
              variant="filled"
              type="password"
              onChange={handleChange}
              slotProps={{
                htmlInput: {
                  form: {
                    autocomplete: 'off',
                  },
                },
              }}
            />
          </Grid>
          {state.downloadMessage !== '' ? (
            <Grid style={{ paddingBottom: '0px' }} size={8}>
              <Typography variant="h6" gutterBottom>
                Downloads
              </Typography>
              <div>
                <Typography variant="body1">{state.downloadMessage}</Typography>
              </div>
            </Grid>
          ) : null}
          <Grid size={9} />
          <Grid container size={3}>
            <Button onClick={handleOpen} fullWidth variant="contained" className={classes.button}>
              save
            </Button>
          </Grid>
        </Grid>
        <Modal
          title="Enter Current Password"
          open={passwordModal}
          handleClose={handleClose}
          styles={classes}
        >
          <Grid
            className={classes.modalContent}
            container
            direction="column"
            align="center"
            spacing={2}
          >
            <Typography variant="subtitle1">
              Please confirm you would like to make these changes by typing in your current
              password.
            </Typography>
            <TextField
              className={classes.field}
              fullWidth
              label="Password"
              name="password"
              variant="filled"
              type="password"
              onChange={handleChange}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                userLogin();
                handleClose();
              }}
              fullWidth
              variant="contained"
              color="secondary"
            >
              confirm
            </Button>
          </Grid>
        </Modal>
      </div>
    </div>
  );
};

export default Settings;
