import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import { Button, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';

import GetAppIcon from '@mui/icons-material/GetApp';
import { SECURE_REGION_DOWNLOAD_QUERY } from '../../../components/Downloads/operations';
import SnackbarContext from '../../../contexts/SnackbarContext';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { openDownloadUrl } from '../../../components/Downloads/helpers';
import { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    ...theme.palette.mapInfoSection,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  button: {
    ...theme.palette.button,
    width: '50%',
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
  },
  orangeIcon: {
    ...theme.palette.accentText,
  },
  label: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
  },
}));

export default ({ eaWell }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const dimensions = {
    asset: eaWell.name,
    country: eaWell.country.name,
    company: 'NULL',
  };

  const [getSecureRegionDownload] = useLazyQuery(SECURE_REGION_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, downloadMessage } = data.secureRegionDownload;
      setSnack({
        open: true,
        message: `Your download should begin shortly.${downloadMessage}`,
        severity: 'success',
      });
      url && openDownloadUrl(url);
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const getEaWellTrackerDownloadLink = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'Maps',
      event_action: ANALYTICS_EVENT_ACTIONS.download,
      event_label: 'ea_well_tracker',
      userId: getUserId(),
      ...dimensions,
    });
    getSecureRegionDownload({
      variables: {
        downloadType: 'EXPLORATION AND APPRAISAL WELL TRACKER',
        region: eaWell.region,
      },
    });
  };

  const region = useMemo(() => {
    switch (eaWell.region) {
      case 'Africa':
        return 'Sub-Saharan Africa';
      default:
        // APAC, Americas, MENA, North Sea:
        return eaWell.region;
    }
  }, [eaWell.region]);

  return (
    <div className={classes.buttonContainer}>
      <Tooltip title="E&A Well Tracker" aria-label="exploration and appraisal well tracker">
        <Button
          className={classes.button}
          onClick={getEaWellTrackerDownloadLink}
          variant="contained"
        >
          <GetAppIcon fontSize="small" color="primary" className={classes.orangeIcon} />
          <div className={classes.label}>
            {region}
            <br />
            E&A WELL TRACKER
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};
