import React from 'react';
import { Slider } from '../../basic/Slider';
import { discountRateDisabled } from '../../Portfolio/Charts/utils';

export default ({ discountRate, graphType, handleSetState, value }) => {
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  return (
    <>
      {(discountRate || discountRate === 0) && (
        <div style={{ width: '100%' }}>
          <Slider
            disabled={discountRateDisabled(graphType)}
            marks={marks}
            onChange={(e, value) => handleSetState(value, 'discountRate')}
            value={value}
            title="Discount Rate (%)"
            tooltip="Enabled only for discounted and cumulative discounted cash flow"
          />
        </div>
      )}
    </>
  );
};
