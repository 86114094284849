import { SHORE_STATUS_QUERY } from '../../../../../components/Filters/operations';
import { useQuery } from '@apollo/client';

export const useGetShoreStatuses = () => {
  const { data, loading, error } = useQuery(SHORE_STATUS_QUERY);

  return {
    shoreStatuses: data?.shoreStatus || [],
    loading,
    error,
  };
};
