import {
  CCUS_COUNTRIES,
  CCUS_PROJECTS,
  CCUS_PROJECTS_CHECK_LIST_GROUP,
  CCUS_STORAGE_GROUP,
  COMPANY_TARGETS_CHECK_LIST,
  DIM_COMPANIES,
  DIM_FACILITIES,
  INDUSTRIES,
  STORAGE_SITES,
} from '../../components/Filters/filterTypes';

export const CCUS_CONTEXT = 'CCUS';
export const CCUS_GOOGLE_ANALYTICS_TAG = 'CCUS';

export const BASIC_FILTER_TYPES = [
  CCUS_COUNTRIES,
  INDUSTRIES,
  DIM_FACILITIES,
  CCUS_PROJECTS,
  STORAGE_SITES,
  DIM_COMPANIES,
];

export const CHECK_LIST_FILTER_TYPES = [
  COMPANY_TARGETS_CHECK_LIST,
  CCUS_PROJECTS_CHECK_LIST_GROUP,
  CCUS_STORAGE_GROUP,
];
