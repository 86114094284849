import {
  COUNTRY_AGGREGATE,
  STORAGE_ACREAGE_NET,
  storageAggregateTypes,
  storageGraphTypes,
  storageStackTypes,
} from '../utils';
import { DEFAULT_TRACE_LIMIT, TOP_SORT_DIRECTION } from '../../../../components/Graphs/constants';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { CCUS_GOOGLE_ANALYTICS_TAG } from '../../constants';
import { GRAPH_CCUS_QUERY } from '../operations';
import GraphControls from '../../../../components/Graphs/GraphControls';
import { NO_VALUE } from '../../../../components/Graphs/constants';
import { cloneDeep } from 'lodash';
import { downloadXlsx } from '../../../../components/Downloads/helpers';
import { useQuery } from '@apollo/client';

const BarGraphContextStorage = createContext();

export const BarGraphProviderStorage = ({ children, filters }) => {
  const [openControlsModal, setOpenControlsModal] = useState(false);

  const [aggregate, setAggregate] = useState(COUNTRY_AGGREGATE.name);
  const [graphType, setGraphType] = useState(STORAGE_ACREAGE_NET);
  const [limit, setLimit] = useState(DEFAULT_TRACE_LIMIT);
  const [sortDirection, setSortDirection] = useState(TOP_SORT_DIRECTION);
  const [range, setRange] = useState({ x: [], y: [] });
  const [modalRange, setModalRange] = useState({ x: [], y: [] });
  const [showPlotlyLegend, setShowPlotlyLegend] = useState(true);
  const [stackType, setStackType] = useState(NO_VALUE);

  const resetRange = () => {
    setRange({ x: [], y: [] });
    setModalRange({ x: [], y: [] });
  };

  const onRelayout = (e) => {
    const x = [e['xaxis.range[0]'], e['xaxis.range[1]']];
    const y = [e['yaxis.range[0]'], e['yaxis.range[1]']];
    setRange({ x, y });
  };

  const onGraphUpdate = () => {
    if (
      (range.x[0] !== modalRange.x[0] && range.x[1] !== modalRange.x[1]) ||
      (range.y[0] !== modalRange.y[0] && range.y[1] !== modalRange.y[1])
    ) {
      setModalRange(range);
    }
  };

  const variables = useMemo(
    () => ({
      aggregate,
      graphType: graphType.value,
      filters,
      stackType,
      limit: parseInt(limit),
      sortDirection: sortDirection.value,
    }),
    [aggregate, graphType, filters, stackType, limit, sortDirection],
  );

  const { data, loading } = useQuery(GRAPH_CCUS_QUERY, {
    variables,
  });

  useEffect(() => {
    resetRange();
  }, [data]);

  const downloadCallback = (errorHandler) => {
    const path = 'ccus/export-bar-graph/';
    const downloadName = `CCUS_Storage_Data_by_${aggregate}.xlsx`;

    downloadXlsx(path, variables, downloadName, errorHandler);
  };

  return (
    <BarGraphContextStorage.Provider
      value={{
        downloadCallback,
        graphType,
        loading,
        modalRange,
        onGraphUpdate,
        onRelayout,
        range,
        setModalRange,
        setOpenControlsModal,
        setShowPlotlyLegend,
        showPlotlyLegend,
        showPlotlyLegendToggle: stackType !== NO_VALUE,
        sortDirection,
        trace: cloneDeep(data?.graphCcus?.analytics || []),
        maxY: data?.graphCcus?.maxY || [],
        storageIsoCodes: data?.graphCcus?.metaData?.iso_codes || [],
        storageIds: data?.graphCcus?.metaData?.ids || [],
        variables,
      }}
    >
      {children}
      <GraphControls
        aggregate={aggregate}
        aggregateTypes={storageAggregateTypes}
        googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
        graphType={graphType}
        limit={limit}
        open={openControlsModal}
        setAggregate={setAggregate}
        setGraphType={setGraphType}
        setLimit={setLimit}
        setOpen={setOpenControlsModal}
        setSortDirection={setSortDirection}
        setStackType={setStackType}
        sortDirection={sortDirection}
        stackType={stackType}
        stackTypes={storageStackTypes}
        types={storageGraphTypes}
      />
    </BarGraphContextStorage.Provider>
  );
};

export const BarGraphConsumerLeft = BarGraphContextStorage.Consumer;

export default BarGraphContextStorage;
