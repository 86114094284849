import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import {
  GET_AVAILABLE_DOWNLOADS_QUERY,
  SECURE_COUNTRY_DOWNLOAD_QUERY,
} from '../../../components/Downloads/operations';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import { REPORT_TYPES } from '../../Valuations/constants';
import { SECURE_REPORT_DOWNLOAD_QUERY } from './operations';
import SnackbarContext from '../../../contexts/SnackbarContext';
import Tooltip from '@mui/material/Tooltip';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { openDownloadUrl } from '../../../components/Downloads/helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
    width: '33%',
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
  },
  buttonContainer: {
    ...theme.palette.mapInfoSection,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  label: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
  },
  orangeIcon: {
    ...theme.palette.accentText,
  },
}));

export default ({ displayedItem }) => {
  const classes = useStyles();
  const [reportType, setReportType] = useState('');
  const [skipQuery, setSkipQuery] = useState(true);
  const { setSnack } = useContext(SnackbarContext);
  const { legacyId: assetLegacyId, countryIsoCode } = displayedItem;
  const showValuationAndAssetReports = displayedItem.entityType === 'asset';
  const dimensions = displayedItem
    ? { asset: displayedItem.name, country: displayedItem.countryName, company: 'NULL' }
    : {};

  const getReportDownloadLink = (reportType) => {
    const gaReportType =
      reportType === REPORT_TYPES.VALUATIONS_MODEL ? 'valuation_model' : 'asset_report';
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'Maps',
      event_action: ANALYTICS_EVENT_ACTIONS.download,
      event_label: gaReportType,
      userId: getUserId(),
      ...dimensions,
    });
    setReportType(reportType);
    setSkipQuery(false);
  };

  let { loading: download_loading, data: download_data } = useQuery(SECURE_REPORT_DOWNLOAD_QUERY, {
    variables: { assetLegacyId, isoCode: countryIsoCode, reportType },
    fetchPolicy: 'network-only',
    skip: skipQuery,
    onCompleted: (data) => {
      const { url, downloadMessage } = data.secureReportDownload;

      if (url) {
        setSnack({
          open: true,
          message: downloadMessage,
          severity: 'success',
        });
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (download_loading || !download_data) return null;
    const { url, filename } = download_data.secureReportDownload;
    if (url && filename) {
      openDownloadUrl(url, filename);
    }
    setSkipQuery(true);
    setReportType('');
  }, [download_loading, download_data]);

  const { loading, data } = useQuery(GET_AVAILABLE_DOWNLOADS_QUERY, {
    variables: {
      countryIsoCode,
    },
  });

  const hasCoreReport = () => {
    return (
      !loading &&
      data?.availableCountryDownloads?.titles?.includes('COUNTRY OVERVIEW REPORT (CORE)')
    );
  };

  const [getSecureCountryDownload] = useLazyQuery(SECURE_COUNTRY_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    variables: { downloadType: 'COUNTRY OVERVIEW REPORT (CORE)', countryIsoCode },
    onCompleted: (data) => {
      const { url, downloadMessage } = data.secureCountryDownload;
      setSnack({
        open: true,
        message: `Your download should begin shortly.${downloadMessage}`,
        severity: 'success',
      });
      window.location.href = url;
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  return (
    <div className={classes.buttonContainer}>
      {!loading && showValuationAndAssetReports ? (
        <>
          <Tooltip title="Valuation Model" aria-label="valuation model report">
            <Button
              className={classes.button}
              onClick={() => {
                getReportDownloadLink(REPORT_TYPES.VALUATIONS_MODEL);
              }}
              variant="contained"
            >
              <GetAppIcon fontSize="small" color="primary" className={classes.orangeIcon} />
              <div className={classes.label}>
                valuation
                <br />
                model
              </div>
            </Button>
          </Tooltip>
          <Tooltip title="Asset Report" aria-label="asset report">
            <Button
              className={classes.button}
              onClick={() => {
                getReportDownloadLink(REPORT_TYPES.ASSET_REPORT);
              }}
              variant="contained"
            >
              <GetAppIcon fontSize="small" color="primary" className={classes.orangeIcon} />
              <div className={classes.label}>
                asset
                <br />
                report
              </div>
            </Button>
          </Tooltip>
        </>
      ) : null}
      {!loading && hasCoreReport() ? (
        <Tooltip title="Country Overview" aria-label="country overview report">
          <Button
            className={classes.button}
            onClick={() => getSecureCountryDownload()}
            variant="contained"
          >
            <GetAppIcon fontSize="small" color="primary" className={classes.orangeIcon} />
            <div className={classes.label}>
              country
              <br />
              overview
            </div>
          </Button>
        </Tooltip>
      ) : null}
    </div>
  );
};
