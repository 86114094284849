import {
  AccountBalance,
  Build,
  CloudSync,
  Domain,
  EnergySavingsLeaf,
  Explore,
  Factory,
  Insights,
  People,
  Subscriptions,
} from '@mui/icons-material';
import {
  ADMINS_CCUS_PATH,
  ADMINS_CLIENTS_PATH,
  ADMINS_DIT_PATH,
  ADMINS_EA_WELLS_PATH,
  ADMINS_FEATURES_PATH,
  ADMINS_INDUSTRY_TRACKERS_PATH,
  ADMINS_LEAD_ANALYSTS_PATH,
  ADMINS_LNG_FACILITIES_PATH,
  ADMINS_SUBSCRIBER_DOWNLOADS_PATH,
  ADMINS_USERS_PATH,
} from '../../../utils/constants';
import { AdminsIFrame } from '../components/AdminsIFrame';
import { useMemo } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext';

/**
 * Available pages for the Admins portal.
 * @type {Array} Array of objects with the following properties:
 *  @property {string} label - used for displaying on the dashboard and in the sidebar.
 *  @property {Icon} icon - used for displaying on the dashboard and in the sidebar.
 *  @property {string} description - used for displaying on the dashboard.
 *  @property {string} route - used for routing to the React page.
 *  @property {React.Component} pageComponent - used for rendering the page.
 *  @property {string} iframeSrc - used for rendering the iframe. Only used (and required) if pageComponent is AdminsIFrame.
 *  @property {boolean} superAdminOnly - used to determine if the page should be displayed only to super admins.
 *  @property {string} featureFlagKey - used to determine if the page should be displayed based on the feature flag.
 *    The feature flag must be defined in the FeatureFlagsContext.
 */
const adminsPageOptions = [
  {
    label: 'Clients',
    icon: AccountBalance,
    description:
      'Manage companies, including contract and renewal dates, download limits, subscribed features, and contact information.',
    route: ADMINS_CLIENTS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/clients',
  },
  {
    label: 'Users',
    icon: People,
    description: 'Manage individual users, their download limits, and regions of interest.',
    route: ADMINS_USERS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/users',
  },
  {
    label: 'Subscriber Downloads',
    icon: Subscriptions,
    description:
      'Organize and manage downloadable files by region and country, including CORE reports.',
    route: ADMINS_SUBSCRIBER_DOWNLOADS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/subscriber_downloads',
  },
  {
    label: 'Industry Trackers',
    icon: Domain,
    description:
      'Upload industry trackers, such as Potential M&A Opportunities, E&A Wells, Licensing Rounds & DRO, Capex Guidance, and Energy Transition.',
    route: ADMINS_INDUSTRY_TRACKERS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/industry_tracker_downloads',
  },
  {
    label: 'E&A Wells',
    icon: Explore,
    description: 'Update Exploration & Appraisal Wells data displayed on the oil and gas map.',
    route: ADMINS_EA_WELLS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/ea_well_tracker',
  },
  {
    label: 'Lead Analysts',
    icon: Insights,
    description: 'Assign and manage analysts responsible for each country.',
    route: ADMINS_LEAD_ANALYSTS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/lead_analysts',
  },
  {
    label: 'LNG Facilities',
    icon: Factory,
    description: 'Update LNG Facilities data displayed on the oil and gas map.',
    route: ADMINS_LNG_FACILITIES_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/lng_facilities',
  },
  {
    label: 'Features',
    icon: Build,
    description: 'Manage the functionalities available to clients.',
    route: ADMINS_FEATURES_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/features',
    superAdminOnly: true,
  },
  {
    label: 'Data Ingestion Tool',
    icon: CloudSync,
    description: 'Import source files for database updates and synchronization.',
    route: ADMINS_DIT_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/data_ingestion_tool',
    featureFlagKey: 'inDevelopment',
  },
  {
    label: 'CCUS Data Import',
    icon: EnergySavingsLeaf,
    description: 'Update CCUS data, manage company names, and refresh map tiles.',
    route: ADMINS_CCUS_PATH,
    pageComponent: AdminsIFrame,
    iframeSrc: '/admins/ccus_data_import',
  },
];

/**
 * Hook to filter the Admins portal options based on the current user's role.
 */
export const useAdminsPageOptions = () => {
  const { currentUser } = useCurrentUser();
  const featureFlags = useFeatureFlags();

  const filteredOptions = useMemo(() => {
    if (!currentUser?.admin) {
      return [];
    }

    return adminsPageOptions.filter((option) => {
      if (option.superAdminOnly) {
        return currentUser?.isSuperAdmin;
      }

      if (option.featureFlagKey) {
        return featureFlags[option.featureFlagKey];
      }

      return true;
    });
  }, [currentUser, featureFlags]);

  return filteredOptions;
};
