import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    flexDirection: 'row',
  },
  landingPageContainer: {
    margin: 0,
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: '15px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
      paddingLeft: '35px',
      paddingRight: '35px',
    },
  },
  header: {
    padding: '30px 30px 20px 30px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '26px',
    },
  },
  icon: {
    width: 50,
    height: 50,
  },
  item: {
    padding: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[4],
    },
    '&.active': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[6],
      backgroundColor: theme.palette.action.selected,
    },
  },
}));
