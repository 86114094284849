import { MA_TRANSACTIONS_SUMMARY_QUERY } from '../operations';
import { useMaContext } from '../../../MaContext';
import { useQuery } from '@apollo/client';

export const useGetMaTransactionsSummary = () => {
  const { filterVariables } = useMaContext();

  const { data, loading, error } = useQuery(MA_TRANSACTIONS_SUMMARY_QUERY, {
    variables: { filters: filterVariables },
  });

  return { data: data?.maTransactionsSummary || {}, loading, error };
};
