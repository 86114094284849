import React, { useEffect, useState } from 'react';

import { GLOBAL_ASSUMPTIONS_QUERY } from '../../../../components/Modals/ConsolidateAssets/operations';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles(() => ({
  assumptions: {
    width: '50%',
    margin: '5%',
    marginTop: 0,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default () => {
  const classes = useStyles();
  const [baseYear, setBaseYear] = useState('2024');
  const [discountRate, setDiscountRate] = useState('10');
  const [costInflation, setCostInflation] = useState('2');
  const [brentPriceBaseYear, setBrentPriceBaseYear] = useState('85');
  const [brentPriceBaseYear1, setBrentPriceBaseYear1] = useState('80');
  const [brentPriceBaseYear2, setBrentPriceBaseYear2] = useState('72.63');

  const { data: globalAssumptionsData } = useQuery(GLOBAL_ASSUMPTIONS_QUERY);

  useEffect(() => {
    if (globalAssumptionsData && globalAssumptionsData.globalAssumptions) {
      let baseYear = parseFloat(globalAssumptionsData.globalAssumptions['baseYear']);
      let discountRate = parseFloat(globalAssumptionsData.globalAssumptions['discountRate']);
      let costInflation = parseFloat(globalAssumptionsData.globalAssumptions['costInflation']);
      let brentPriceBaseYear = parseFloat(
        globalAssumptionsData.globalAssumptions['brentPriceBaseYear'],
      );
      let brentPriceBaseYear1 = parseFloat(
        globalAssumptionsData.globalAssumptions['brentPriceBaseYearPlusOne'],
      );
      let brentPriceBaseYear2 = parseFloat(
        globalAssumptionsData.globalAssumptions['brentPriceBaseYearPlusTwo'],
      );
      !Number.isNaN(baseYear) && setBaseYear(baseYear);
      !Number.isNaN(discountRate) && setDiscountRate(discountRate);
      !Number.isNaN(costInflation) && setCostInflation(costInflation);
      !Number.isNaN(brentPriceBaseYear) && setBrentPriceBaseYear(brentPriceBaseYear);
      !Number.isNaN(brentPriceBaseYear1) && setBrentPriceBaseYear1(brentPriceBaseYear1);
      !Number.isNaN(brentPriceBaseYear2) && setBrentPriceBaseYear2(brentPriceBaseYear2);
    }
  }, [globalAssumptionsData]);

  return (
    <div>
      <h1>Assumptions / Glossary</h1>
      <div className={classes.flex}>
        <div className={classes.assumptions}>
          <h2>Assumptions</h2>
          <ul>
            <li>Base Date: 1/1/{baseYear}</li>
            <li>
              Most Recent Production: The production shown is actual if reported within the last 6
              months; otherwise we show our most recent estimate.
            </li>
            <li>Discount Rate: {discountRate}%</li>
            <li>Long Term Inflation: {costInflation}%</li>
            <li>
              Brent Price Assumption: {baseYear} = US${brentPriceBaseYear}/bbl; {baseYear + 1} = US$
              {brentPriceBaseYear1}/bbl, {baseYear + 2} = US${brentPriceBaseYear2}/bbl, inflated at{' '}
              {costInflation}% per annum thereafter - this is equivalent to a long-term Brent price
              (real) of US$70/bbl
            </li>
            <li>1,000 boe = 6 MMcf</li>
          </ul>
        </div>

        <div className={classes.assumptions}>
          <h2>Glossary</h2>
          <ul>
            <li>PDP = Proved Developed Produced</li>
            <li>UD = Undeveloped Produced</li>
            <li>Gas Production = Sales Production</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
