import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    margin: 'auto',
    marginBottom: '20px',
  },
  root: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  login: {
    height: '520px',
    width: '500px',
    paddingTop: '45px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
