import { CompanyAnalysis } from './components/CompanyAnalysis';
import { Opportunities } from './components/Opportunities';
import { Overview } from './components/Overview';
import { TransactionAnalytics } from './components/TransactionAnalytics';
import { TransactionTable } from './components/TransactionTable';

export const TABS = [
  {
    label: 'Overview',
    component: Overview,
    tag: 'overview',
  },
  {
    label: 'Transactions Table',
    component: TransactionTable,
    tag: 'transactions',
  },
  {
    label: 'Transaction Analytics',
    component: TransactionAnalytics,
    tag: 'analytics',
  },
  {
    label: 'Company Analysis',
    component: CompanyAnalysis,
    tag: 'analysis',
  },
  {
    label: 'Opportunities',
    component: Opportunities,
    tag: 'opportunities',
  },
];
