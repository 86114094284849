import {
  ASSOCIATED_ASSETS_SUMMARY_ITEM,
  DESTINATION_SUMMARY_ITEM,
  ORIGIN_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/base';
import {
  DIAMETER_PIPELINE_SEGMENT_SUMMARY_ITEM,
  GRAPH_THROUGHPUT_PIPELINE_SEGMENT_SUMMARY_ITEM,
  HYDROCARBON_PIPELINE_SEGMENT_SUMMARY_ITEM,
  OPERATOR_PIPELINE_SEGMENT_SUMMARY_ITEM,
  PIPELINE_NAME_PIPELINE_SEGMENT_SUMMARY_ITEM,
  PRODUCTION_DATE_PIPELINE_SEGMENT_SUMMARY_ITEM,
  STATUS_PIPELINE_SEGMENT_SUMMARY_ITEM,
  THROUGHPUT_PIPELINE_SEGMENT_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/pipelineSegment';

export default [
  PIPELINE_NAME_PIPELINE_SEGMENT_SUMMARY_ITEM,
  ORIGIN_SUMMARY_ITEM,
  DESTINATION_SUMMARY_ITEM,
  OPERATOR_PIPELINE_SEGMENT_SUMMARY_ITEM,
  STATUS_PIPELINE_SEGMENT_SUMMARY_ITEM,
  DIAMETER_PIPELINE_SEGMENT_SUMMARY_ITEM,
  ASSOCIATED_ASSETS_SUMMARY_ITEM,
  HYDROCARBON_PIPELINE_SEGMENT_SUMMARY_ITEM,
  PRODUCTION_DATE_PIPELINE_SEGMENT_SUMMARY_ITEM,
  THROUGHPUT_PIPELINE_SEGMENT_SUMMARY_ITEM,
  GRAPH_THROUGHPUT_PIPELINE_SEGMENT_SUMMARY_ITEM,
];
