import React, { useMemo } from 'react';

import { Button } from '@mui/material';
import Logo from '../../../assets/images/OktaLogo.svg';
import { OKTA_PROVIDER_ID } from '../../contexts/OAuth2Context/OAuth2Context';
import { useOAuth2 } from '../../contexts/OAuth2Context';

export const OktaLoginButton = (props) => {
  const { state, providers } = useOAuth2();

  const oktaProvider = providers?.find((provider) => provider.id === OKTA_PROVIDER_ID);

  const clientId = oktaProvider?.clientId;
  const redirectUri = oktaProvider?.redirectUri;
  const scope = oktaProvider?.scope;
  const authUrl = oktaProvider?.authUrl;
  const params = useMemo(
    () =>
      new URLSearchParams({
        client_id: clientId,
        response_type: 'code',
        redirect_uri: redirectUri,
        scope: scope,
        state: state,
      }),
    [clientId, redirectUri, scope, state],
  );

  const onClick = () => {
    window.location.href = `${authUrl}?${params.toString()}`;
  };

  if (!oktaProvider) return null;

  return (
    <Button {...props} onClick={onClick}>
      <img id="logo" src={Logo} alt="Okta-Logo" width="50" height="25" />
      Sign In with Okta
    </Button>
  );
};
