export const OPPORTUNITIES_AGGREGATE_FIELDS_ENUM = {
  VALUATIONS: 'valuationsSum',
  RESERVES: 'reservesSum',
  PRODUCTIONS: 'productionsSum',
  ACQUISITION_COST: 'acquisitionCostSum',
};

export const OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS = Object.values(
  OPPORTUNITIES_AGGREGATE_FIELDS_ENUM,
);

export const OPPORTUNITIES_AGGREGATE_BY_TITLE = 'NPV10 Valuation (US$MM)';

export const OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM = {
  YEAR: 'year',
  COUNTRY: 'country',
  COMPANY: 'seller',
};

export const OPPORTUNITIES_AGGREGATE_GRAPH_BY_OPTIONS = [
  {
    label: 'Year',
    value: OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.YEAR,
  },
  {
    label: 'Country',
    value: OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  },
  {
    label: 'Company',
    value: OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.COMPANY,
  },
];

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM = {
  RESERVES: 'reserves',
  PRODUCTIONS: 'productions',
  ACQUISITION_COST: 'acquisitionCost',
};

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM = {
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.RESERVES]: OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.RESERVES,
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.PRODUCTIONS]:
    OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.PRODUCTIONS,
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.ACQUISITION_COST]:
    OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.ACQUISITION_COST,
};

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM = {
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.RESERVES]: 'Reserves (MMboe)',
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.PRODUCTIONS]: 'Production (boe/d)',
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.ACQUISITION_COST]: 'Acquisition Cost (US$/boe)',
};

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_OPTIONS = Object.values(
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM,
).map((name) => ({
  label: OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM[name],
  value: name,
}));
