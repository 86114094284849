import { ADVANCED_FILTER_BUTTON_ID, PRESET_BUTTON_ID } from '../../components/PageActions';

import { BASIC_FILTERS_WRAPPER_ID } from '../../components/BasicFiltersWrapper/BasicFiltersWrapper.constant';

export const portfolioAnalysisDriver = (driver) => {
  driver.defineSteps([
    {
      element: `#portfolio-${BASIC_FILTERS_WRAPPER_ID}`,
      popover: {
        title: 'Basic Filters',
        description:
          'Filter the returned data based on country, asset, asset units, or company. Select one or multiple choices from each drop down filter.',
        position: 'bottom-right',
      },
    },
    {
      element: '#portfolio-analysis-advanced-filters-button',
      popover: {
        title: 'All Filters',
        description:
          'Clicking this will open up the advanced filters options. There you will have access to all the filters including basins, resource themes, shore statuses, development statuses, npv, reserves, and estimated current production.',
        position: 'bottom-right',
      },
    },
    {
      element: `#${ADVANCED_FILTER_BUTTON_ID}`,
      popover: {
        title: 'Build Portfolio',
        description:
          'Clicking this will open up the multi filter options. There you will have access to all the filters including basins, resource themes, shore statuses, development statuses, npv, reserves, and estimated current production. You will be able to add multiple filter sets (max 5) to view together in the charts below.',
        position: 'bottom-right',
      },
    },
    {
      element: `#${PRESET_BUTTON_ID}`,
      popover: {
        title: 'Save / Load Portfolio',
        description:
          'Clicking this will open up the dialog where you can Save, Load and Share selected filters as a custom portfolio.',
        position: 'left-top',
      },
    },
    {
      element: '#portfolio-analysis-treemap',
      popover: {
        title: 'Treemap',
        description: 'View a breakdown of data by each aggregated slice.',
        position: 'right',
      },
    },
    {
      element: '#portfolio-analysis-production-line-graphs',
      popover: {
        title: 'Production Line Graph',
        description:
          'View time based production data. You can view a myriad of hydrocarbon type values for total production, pdp, or undeveloped production.<br /><br /><b>Note -</b> Aggregating by "all" metrics can lead to a decrease in performance if the filter set returns too many rows.',
        position: 'bottom',
      },
    },
    {
      element: '#portfolio-analysis-graph-app-bar',
      popover: {
        title: 'Graph App Bar',
        description:
          "Every graph's app bar contains useful tools and information. Every chart will have a button for a fullscreen mode, downloading raw data, and accessing more of the chart's settings via the hamburger icon. In addition to those buttons, the line charts contain toggles for viewing the minified graph legend and viewing stacked vs normal line plot.",
        position: 'bottom',
      },
    },
    {
      element: '#portfolio-analysis-cashflow-line-graphs',
      popover: {
        title: 'Cashflow Line Graph',
        description:
          'View time based cashflow data.<br /><br /><b>Note -</b> Aggregating by "all" metrics can lead to a decrease in performance if the filter set returns too many rows.',
        position: 'bottom',
      },
    },
    {
      element: '#portfolio-analysis-bar-graph',
      popover: {
        title: 'Bar Graph',
        description:
          'A view of total data by a selected aggregate. Both bar graphs provide the same options.',
        position: 'top',
      },
    },
  ]);

  // Start the introduction
  driver.start(0);
};
