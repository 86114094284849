import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MuiPopper from '@mui/material/Popover';
import { Popover } from './components/Popover';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { useStyles } from './AutoCompleteExcelLike.style';

const AutoCompleteButton = ({
  isOpen,
  setIsOpen,
  setAnchorEl,
  numberOfChosenOptions,
  label,
  classes,
  setOffsetPopper,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      onClick={(e) => {
        setIsOpen(!isOpen);
        setAnchorEl(e.currentTarget);

        /* This fixes the popper alignment issue on portfolio analysis once the anchor is placed
        too much on the left side (less than left: 16px)*/
        const buttonOffsetLeft = e.currentTarget.getBoundingClientRect().left;
        setOffsetPopper(buttonOffsetLeft === 8);
      }}
      className={clsx(classes.box, { [classes.boxOpen]: isOpen })}
    >
      <div className={classes.label} disabled={disabled}>
        {label}
      </div>
      <div className={classes.rightPart}>
        {!!numberOfChosenOptions && <span className={classes.chosen}>{numberOfChosenOptions}</span>}
        <ArrowDropDown className={clsx({ [classes.arrowOpen]: isOpen })} />
      </div>
    </button>
  );
};

const AutoCompletePopper = ({
  isOpen,
  anchorEl,
  offsetPopper,
  top,
  popoverHeightReduction,
  handleClose,
  options,
  setOptions,
  isLoading,
  groupOptionKey,
  label,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
  entitiesSorter,
  classes,
}) => {
  return (
    <MuiPopper
      className={clsx(classes.popper, { [classes.popperAlignment]: !!offsetPopper })}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      open={isOpen}
      onClose={() => handleClose(true)}
    >
      <Popover
        top={top}
        popoverHeightReduction={popoverHeightReduction}
        onClose={() => handleClose(false)}
        options={options || []}
        setOptions={setOptions}
        loading={isLoading}
        groupOptionKey={groupOptionKey}
        label={label}
        groupsOpenedByDefault={groupsOpenedByDefault}
        groupByName={groupByName}
        groupSorter={groupSorter}
        entitiesSorter={entitiesSorter}
      />
    </MuiPopper>
  );
};

const AutoCompleteExcelLikeInner = ({
  isLoading,
  options,
  setOptions,
  popoverHeightReduction,
  groupOptionKey,
  label,
  styles = {},
  setOptionsOnModalClose,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
  entitiesSorter,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [top, setTop] = useState(null);
  const [offsetPopper, setOffsetPopper] = useState(false);
  const heightSizingRef = useRef(null);

  useEffect(() => {
    if (heightSizingRef.current) {
      const { top, height } = heightSizingRef.current.getBoundingClientRect();
      setTop(top + height);
    }
  }, [heightSizingRef.current]);

  const handleClose = useCallback((shouldSetOptionsOnModalClose = false) => {
    setIsOpen(false);
    if (shouldSetOptionsOnModalClose && setOptionsOnModalClose) {
      setOptionsOnModalClose();
    }
  }, []);

  const classes = useStyles();

  const numberOfChosenOptions = useMemo(() => {
    return (options || []).filter(({ isChosen }) => !!isChosen).length;
  }, [options]);

  return (
    <>
      <div
        ref={heightSizingRef}
        className={clsx(classes.wrapperAutoCompleteExcelLike, 'advanced-autocomplete')}
        style={styles}
      >
        <AutoCompleteButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setAnchorEl={setAnchorEl}
          numberOfChosenOptions={numberOfChosenOptions}
          label={label}
          classes={classes}
          setOffsetPopper={setOffsetPopper}
          disabled={disabled}
        />
      </div>
      <AutoCompletePopper
        isOpen={isOpen}
        anchorEl={anchorEl}
        offsetPopper={offsetPopper}
        top={top}
        popoverHeightReduction={popoverHeightReduction}
        handleClose={handleClose}
        options={options || []}
        setOptions={setOptions}
        isLoading={isLoading}
        groupOptionKey={groupOptionKey}
        label={label}
        groupsOpenedByDefault={groupsOpenedByDefault}
        groupByName={groupByName}
        groupSorter={groupSorter}
        entitiesSorter={entitiesSorter}
        classes={classes}
      />
    </>
  );
};

export const AutoCompleteExcelLike = ({
  getData,
  mapQueryData,
  groupOptionKey,
  propVariables,
  onChange,
  value,
  popoverHeightReduction,
  label,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
  entitiesSorter,
  disabled,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [options, setOptions] = useState(null);

  const setOptionsLocal = (v) => {
    setOptions(v);
    setTimeout(() => onChange(v.filter(({ isChosen }) => isChosen)), 0);
  };

  const { data } = useQuery(getData, {
    variables: propVariables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      const entitiesChosenFromProp = value.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const entityData = mapQueryData(data);
      const mergedFetchedEntitiesWithProp = entityData.map((entity) => {
        const isChosen = !!entitiesChosenFromProp[entity.id];
        delete entitiesChosenFromProp[entity.id];
        return {
          displayName: entity.displayName || entity.name,
          ...entity,
          isChosen,
        };
      });
      const entitiesWithAllChosen = mergedFetchedEntitiesWithProp.concat(
        Object.values(entitiesChosenFromProp),
      );
      setOptions(entitiesWithAllChosen);
      setIsLoading(false);
    }
  }, [data, value]);

  return (
    <AutoCompleteExcelLikeInner
      isLoading={isLoading}
      options={options}
      setOptions={setOptionsLocal}
      popoverHeightReduction={popoverHeightReduction}
      label={label}
      groupsOpenedByDefault={groupsOpenedByDefault}
      groupByName={groupByName}
      groupSorter={groupSorter}
      entitiesSorter={entitiesSorter}
      groupOptionKey={groupOptionKey}
      disabled={disabled}
    />
  );
};

export const AutoCompleteExcelLikeLocalData = ({
  options,
  setOptions,
  isLoading,
  groupOptionKey,
  popoverHeightReduction,
  inModal,
  label,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
  entitiesSorter,
  disabled,
}) => {
  const [values, setValues] = useState(options);
  useEffect(() => setValues(options), [options]);

  const setOptionsOnModalClose = () => setOptions(values);

  return (
    <AutoCompleteExcelLikeInner
      isLoading={isLoading}
      options={values}
      setOptions={setValues}
      popoverHeightReduction={popoverHeightReduction}
      inModal={inModal}
      groupOptionKey={groupOptionKey}
      label={label}
      styles={{ width: '10rem' }}
      setOptionsOnModalClose={setOptionsOnModalClose}
      groupsOpenedByDefault={groupsOpenedByDefault}
      groupByName={groupByName}
      groupSorter={groupSorter}
      entitiesSorter={entitiesSorter}
      disabled={disabled}
    />
  );
};
