import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import BasicFilters from './basicFilters';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ExpandedFilters from './expandedFilters';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { defaultFilterTypes } from './filterTypes';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  portfolioItem: {
    padding: '10px 20px',
    borderBottom: 'solid thin lightgrey',
  },
  filterLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 117px',
    columnGap: '15px',
    rowGap: '10px',
    padding: '10px 0',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    '& .MuiAutocomplete-root': {
      margin: '0',
    },
  },
  removable: {
    gridTemplateColumns: '50px 1fr 1fr 1fr 1fr 1fr 117px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr  117px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  accordion: {
    width: '100%',
    '&.MuiAccordion-root': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
  },
  alternateColor: {
    backgroundColor: '#F5F5F5',
  },
  button: {
    ...theme.palette.button,
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  buttonAlongWithRemovable: {
    [theme.breakpoints.between('sm', 'md')]: {
      gridColumn: 4,
      gridRow: 2,
    },
  },
  deletePortfolioWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.between('sm', 'md')]: {
      gridColumn: 4,
      gridRow: 1,
      justifyContent: 'flex-end',
      width: '100%',
    },
  },
  deletePortfolio: {
    ...theme.palette.actionButton,
    color: 'grey',
    alignSelf: 'center',
  },
  details: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      width: '100%',
      padding: 0,
    },
  },
}));

export default ({
  applyBasicFilters,
  basicFilterTypes = defaultFilterTypes,
  checkListItems,
  context,
  deletePortfolio,
  googleAnalyticsTag,
  idx,
  portfolio,
  removeable,
  inModal,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={clsx(classes.portfolioItem, { [classes.alternateColor]: idx % 2 === 1 })}>
      <div className={clsx(classes.filterLine, { [classes.removable]: removeable })}>
        {removeable && (
          <div className={classes.deletePortfolioWrapper}>
            <Tooltip title="Remove portfolio">
              <CloseIcon className={classes.deletePortfolio} onClick={() => deletePortfolio(idx)} />
            </Tooltip>
          </div>
        )}
        <BasicFilters
          applyFilters={(filters) => applyBasicFilters(filters, idx)}
          basicFilterTypes={basicFilterTypes}
          context={context}
          filterItemClass={classes.filterItem}
          filters={portfolio.filters}
          googleAnalyticsTag={googleAnalyticsTag}
          inModal={inModal}
        />
        <Button
          startIcon={<FilterListIcon />}
          id="portfolio-analysis-advanced-filters-button"
          className={clsx(classes.button, { [classes.buttonAlongWithRemovable]: removeable })}
          variant="contained"
          onClick={() => setExpanded(!expanded)}
        >
          All Filters
        </Button>
      </div>
      <Accordion square className={classes.accordion} expanded={expanded}>
        <div />
        <AccordionDetails className={classes.details}>
          <ExpandedFilters
            filters={portfolio.filters}
            applyFilters={(filters) => applyBasicFilters(filters, idx)}
            checkListItems={checkListItems}
            context={context}
            googleAnalyticsTag={googleAnalyticsTag}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
