import {
  BarGraphContextLeft,
  BarGraphContextRight,
  BarGraphProviderLeft,
  BarGraphProviderRight,
} from './BarGraphs';
import TreemapContext, { TreemapProvider } from './Treemap/TreemapContext';

import BarGraph from '../../../components/Portfolio/Charts/BarGraph/barGraph';
import Grid from '@mui/material/Grid2';
import { LineGraphProviderLeft } from './LineGraphs/LineGraphContextLeft';
import { LineGraphProviderRight } from './LineGraphs/LineGraphContextRight';
import LineGraphs from './LineGraphs';
import React from 'react';
import Treemap from '../../../components/Portfolio/Charts/Treemap';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.primaryBackground,
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  graphContainer: {
    height: `calc(50% - ${theme.spacing(1)})`,
  },
}));

export default ({ portfolios }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      spacing={1}
      columnSpacing={{ xs: 2 }}
      sx={{
        justifyContent: 'space-evenly',
      }}
    >
      <Grid id="ghg-treemap" size={3}>
        <TreemapProvider portfolios={portfolios}>
          <Treemap context={TreemapContext} />
        </TreemapProvider>
      </Grid>
      <Grid container spacing={2} size={9}>
        <LineGraphProviderLeft portfolios={portfolios}>
          <LineGraphProviderRight portfolios={portfolios}>
            <Grid container spacing={2} size={6} direction="column">
              <LineGraphs portfolios={portfolios} />
            </Grid>
          </LineGraphProviderRight>
        </LineGraphProviderLeft>
        <Grid container size={6} direction="column">
          <Grid id="ghg-bar-graph" size={12} className={classes.graphContainer}>
            <BarGraphProviderLeft portfolios={portfolios}>
              <BarGraph includeId context={BarGraphContextLeft} />
            </BarGraphProviderLeft>
          </Grid>
          <Grid size={12} className={classes.graphContainer}>
            <BarGraphProviderRight portfolios={portfolios}>
              <BarGraph context={BarGraphContextRight} />
            </BarGraphProviderRight>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
