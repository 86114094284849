import {
  OPPORTUNITIES_AGGREGATE_BY_TITLE,
  OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM,
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM,
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM,
} from '../../../types';

import { titleize } from '../../../../../../../utils/helpers';
import { useGetMaOpportunitiesAggregates } from '../../../hooks/useGetMaOpportunitiesAggregates';
import { useMemo } from 'react';

const getAltYAxisChartData = (data, aggregateBy, compareTo) => {
  const aggregateByTitle = titleize(aggregateBy);
  const altYAxisTitle = OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM[compareTo];

  return [
    {
      x: data?.map((d) => d.group) || [],
      y: data?.map((d) => d[OPPORTUNITIES_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM[compareTo]]) || [],
      name: altYAxisTitle,
      yaxis: 'y2',
      type: 'scatter',
      mode: 'markers',
      hovertemplate: `${aggregateByTitle}: %{x}<br>${altYAxisTitle}: %{y:,.2f}<extra></extra>`,
    },
  ];
};

const getFlatChartData = (data, aggregateBy, compareTo) => {
  const aggregateByTitle =
    aggregateBy === OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.COMPANY
      ? 'Company' // convert Buyer to Company
      : titleize(aggregateBy);
  const yAxisTitle = OPPORTUNITIES_AGGREGATE_BY_TITLE;

  return [
    {
      x: data?.map((d) => d.group) || [],
      y: data?.map((d) => d.valuationsSum) || [],
      name: yAxisTitle,
      type: 'bar',
      hovertemplate: `${aggregateByTitle}: %{x}<br>${yAxisTitle}: %{y:,.2f}<extra></extra>`,
    },
    ...getAltYAxisChartData(data, aggregateBy, compareTo),
  ];
};

export const useGetChartData = ({ aggregateBy, compareTo, fields }) => {
  const { data, loading, error } = useGetMaOpportunitiesAggregates(aggregateBy, fields);

  const chartData = useMemo(() => {
    if (!data) return [];

    return getFlatChartData(data, aggregateBy, compareTo);
  }, [data]);

  return { data: chartData, loading: loading, error: error };
};
