import {
  BASIN_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  PARTICIPATION_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/base';
import {
  BLOCK_NAME_EA_WELL_SUMMARY_ITEM,
  COMMITMENT_EA_WELL_SUMMARY_ITEM,
  COORDINATES_EA_WELL_SUMMARY_ITEM,
  DRILL_START_EA_WELL_SUMMARY_ITEM,
  ESTIMATED_COST_EA_WELL_SUMMARY_ITEM,
  EXPECTED_SPUD_EA_WELL_SUMMARY_ITEM,
  FIELD_NAME_EA_WELL_SUMMARY_ITEM,
  GEOLOGICAL_TARGET_EA_WELL_SUMMARY_ITEM,
  HOST_FACILITY_EA_WELL_SUMMARY_ITEM,
  HYDROCARBON_TARGET_EA_WELL_SUMMARY_ITEM,
  IMPACT_EA_WELL_SUMMARY_ITEM,
  NAME_EA_WELL_SUMMARY_ITEM,
  PROJECT_NAME_EA_WELL_SUMMARY_ITEM,
  PROSPECT_SIZE_EA_WELL_SUMMARY_ITEM,
  RIG_NAME_EA_WELL_SUMMARY_ITEM,
  TARGET_DEPTH_EA_WELL_SUMMARY_ITEM,
  WATER_DEPTH_EA_WELL_SUMMARY_ITEM,
  WELL_TYPE_EA_WELL_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/eaWell';

export default () => {
  return [
    NAME_EA_WELL_SUMMARY_ITEM,
    WELL_TYPE_EA_WELL_SUMMARY_ITEM,
    PROJECT_NAME_EA_WELL_SUMMARY_ITEM,
    FIELD_NAME_EA_WELL_SUMMARY_ITEM,
    BLOCK_NAME_EA_WELL_SUMMARY_ITEM,
    PARTICIPATION_SUMMARY_ITEM,
    {
      type: 'group',
      template: [
        HYDROCARBON_TARGET_EA_WELL_SUMMARY_ITEM,
        PROSPECT_SIZE_EA_WELL_SUMMARY_ITEM,
        IMPACT_EA_WELL_SUMMARY_ITEM,
        GEOLOGICAL_TARGET_EA_WELL_SUMMARY_ITEM,
        HOST_FACILITY_EA_WELL_SUMMARY_ITEM,
        COMMITMENT_EA_WELL_SUMMARY_ITEM,
        COORDINATES_EA_WELL_SUMMARY_ITEM,
      ],
    },
    {
      type: 'group',
      template: [
        COUNTRY_SUMMARY_ITEM,
        BASIN_SUMMARY_ITEM,
        DRILL_START_EA_WELL_SUMMARY_ITEM,
        EXPECTED_SPUD_EA_WELL_SUMMARY_ITEM,
        ESTIMATED_COST_EA_WELL_SUMMARY_ITEM,
        TARGET_DEPTH_EA_WELL_SUMMARY_ITEM,
        WATER_DEPTH_EA_WELL_SUMMARY_ITEM,
        SHORE_STATUS_SUMMARY_ITEM,
        RIG_NAME_EA_WELL_SUMMARY_ITEM,
      ],
    },
  ];
};
