import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import { EntityRow } from './components/EntityRow';
import { Group } from './components/Group';
import { SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE } from '../../../../../utils/constants';
import clsx from 'clsx';
import { useStyles } from './Popover.style';

const cleanAlfNumChars = (v) => v?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

const sortGroupsAsc = (a, b) => {
  const aName = a?.group?.displayName || '';
  const bName = b?.group?.displayName || '';

  return aName.localeCompare(bName);
};

const sortEntitiesAsc = (a, b) => {
  const aDisplayName = a.displayName || '';
  const bDisplayName = b.displayName || '';

  return aDisplayName.localeCompare(bDisplayName);
};

const filterGroupsAndEntities = (entities, search) => {
  const entitiesAreOptions = entities.every((entity) => entity.displayName || entity.name);
  if (entitiesAreOptions) {
    return entities.filter(
      (option) =>
        cleanAlfNumChars(option.displayName || option.name).indexOf(cleanAlfNumChars(search)) !==
        -1,
    );
  }

  const entitiesAreGroups = entities.every((entity) => entity.entities);
  if (entitiesAreGroups) {
    return entities.filter((group) => {
      group.entities = filterGroupsAndEntities(group.entities, search);
      return group.entities.length > 0;
    });
  }

  return [];
};

const sortGroupedEntities = (searchCleared, groupSorter, entitiesSorter, entitiesGrouped) => {
  const result = [];

  Object.values(entitiesGrouped).forEach(({ entities, group }) => {
    const chosenCount = entities.filter(
      (option) => option.isChosen || option.groupMetaData?.allChosen,
    ).length;
    const groupMetaData = {
      allChosen: chosenCount === entities.length,
      chosenCount,
      itemsCount: entities.length,
    };

    const entitiesFiltered = searchCleared
      ? filterGroupsAndEntities(entities, searchCleared)
      : entities;

    if (entitiesFiltered.length === 0) return;

    result.push({
      groupMetaData,
      group: group,
      entities: entitiesFiltered.sort(entitiesSorter),
    });
  });

  return result.sort(groupSorter);
};

const groupEntities = (entities, groupOptionKey) => {
  const entitiesGrouped = entities.reduce((acc, curr) => {
    let group = curr[groupOptionKey];
    const groupIsString = typeof group === 'string';
    const id = (groupIsString ? group : group.id) || 'other';
    const displayName = (groupIsString ? group : group.displayName || group.name) || 'Other';
    group = {
      id,
      displayName,
      key: `${groupOptionKey}-${id}`,
      optionKey: groupOptionKey,
    };

    acc[group.id] = {
      group: group,
      entities: [...((acc[group.id] || {}).entities || []), curr],
    };
    return acc;
  }, {});

  return entitiesGrouped;
};

const nestedGroupEntities = (
  entities,
  searchCleared,
  groupSorter,
  entitiesSorter,
  groupOptionKeys,
) => {
  return groupOptionKeys.reduce((acc, groupOptionKey, index) => {
    if (index === 0) {
      acc = groupEntities(entities, groupOptionKey);
    } else {
      Object.keys(acc).forEach((key) => {
        acc[key].entities = sortGroupedEntities(
          searchCleared,
          groupSorter,
          entitiesSorter,
          groupEntities(acc[key].entities, groupOptionKey),
        );
      });
    }
    return acc;
  }, {});
};

const groupBy = (entities, search, groupSorter, entitiesSorter, groupOptionKey) => {
  groupSorter = groupSorter || sortGroupsAsc;
  entitiesSorter = entitiesSorter || sortEntitiesAsc;

  const searchCleared = cleanAlfNumChars(search);
  const entitiesGrouped = Array.isArray(groupOptionKey)
    ? nestedGroupEntities(entities, searchCleared, groupSorter, entitiesSorter, groupOptionKey)
    : groupEntities(entities, groupOptionKey);

  return sortGroupedEntities(searchCleared, groupSorter, entitiesSorter, entitiesGrouped);
};

export const Popover = ({
  loading,
  options,
  setOptions,
  top,
  popoverHeightReduction = '30px',
  groupOptionKey,
  label,
  groupsOpenedByDefault,
  groupByName,
  groupSorter,
  entitiesSorter,
}) => {
  const hasGrouping = !!groupOptionKey;

  const [search, setSearch] = useState();
  const [isGroupedBy, setIsGroupedBy] = useState(() => {
    if (!hasGrouping) return false;
    const sessionPreference = sessionStorage.getItem(
      SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE + label,
    );
    return sessionPreference !== 'false';
  });
  const classes = useStyles();

  const openGroupWhileTyping = !!search;
  const setIsGroupedByLocal = (v) => {
    setIsGroupedBy(v);
    sessionStorage.setItem(SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE + label, v);
  };

  const clearSearch = useCallback(() => setSearch(''), []);

  const updateSearch = ({ target }) => {
    setSearch(target.value);
  };

  const setOptionIsChosen = (id, { target }) =>
    setOptions(
      options.map((option) =>
        option.id === id ? { ...option, isChosen: target.checked } : option,
      ),
    );

  const setGroupIsChosen = (group, { target }) => {
    const { id, optionKey } = group;
    setOptions(
      options.map((option) =>
        option[optionKey]?.id === id || option[optionKey] === id
          ? { ...option, isChosen: target.checked }
          : option,
      ),
    );
  };

  const clearAll = () => setOptions(options.map((option) => ({ ...option, isChosen: false })));
  const selectAll = () => setOptions(options.map((option) => ({ ...option, isChosen: true })));

  const OptionList = useMemo(() => {
    const NoResult = () => <div className={classes.noResult}>no result</div>;

    if (isGroupedBy) {
      const optionsGrouped = groupBy(options, search, groupSorter, entitiesSorter, groupOptionKey);

      return (
        <div className={classes.optionList}>
          {optionsGrouped.length === 0 ? (
            <NoResult />
          ) : (
            optionsGrouped.map(({ group, entities, groupMetaData }) => {
              return (
                <Group
                  key={'group-' + group.id}
                  group={group}
                  groupMetaData={groupMetaData}
                  groupDepth={0}
                  setGroupIsChosen={setGroupIsChosen}
                  entitiesGrouped={entities}
                  setOptionIsChosen={setOptionIsChosen}
                  openGroupWhileTyping={openGroupWhileTyping}
                  groupsOpenedByDefault={groupsOpenedByDefault}
                />
              );
            })
          )}
        </div>
      );
    } else {
      const entitiesFiltered = search
        ? options.filter(
            (option) =>
              cleanAlfNumChars(option.displayName).indexOf(cleanAlfNumChars(search)) !== -1,
          )
        : options;

      return (
        <div className={classes.optionList}>
          {entitiesFiltered.length === 0 ? (
            <div className={classes.noResult}>no result</div>
          ) : (
            entitiesFiltered.map(({ displayName, isChosen, id }) => (
              <EntityRow
                displayName={displayName}
                isChosen={!!isChosen}
                key={id}
                setOptionIsChosen={(e) => setOptionIsChosen(id, e)}
              />
            ))
          )}
        </div>
      );
    }
  }, [isGroupedBy, options, search]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Box
        className={classes.popover}
        style={{ maxHeight: `calc(100vh - ${top}px - ${popoverHeightReduction})` }}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} className={classes.loader} />
        ) : (
          <>
            <TextField
              className={classes.input}
              inputRef={(input) => input && input.focus()}
              placeholder={'Search ' + label}
              variant="outlined"
              value={search}
              onChange={updateSearch}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: search ? (
                    <Button className={classes.close} onClick={clearSearch}>
                      <ClearIcon />
                    </Button>
                  ) : undefined,
                },
              }}
            />
            {OptionList}
            <div className={classes.sectionBottom}>
              {hasGrouping && (
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{ root: classes.checkBox }}
                      checked={isGroupedBy}
                      onChange={({ target }) => setIsGroupedByLocal(target.checked)}
                    />
                  }
                  label={
                    <Typography className={classes.label}>
                      Group by {Array.isArray(groupByName) ? groupByName.join(', ') : groupByName}
                    </Typography>
                  }
                />
              )}
              <Button
                className={clsx(classes.button, {
                  [classes.divide]: hasGrouping,
                })}
                variant="text"
                onClick={selectAll}
              >
                Select All
              </Button>
              <Button className={classes.button} variant="text" onClick={clearAll}>
                Clear All
              </Button>
            </div>
          </>
        )}
      </Box>
    </div>
  );
};
