import {
  BASIN_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  DEVELOPMENT_STATUS_SUMMARY_ITEM,
  DEVELOPMENT_TYPE_SUMMARY_ITEM,
  DISCOVERY_DATE_SUMMARY_ITEM,
  FISCAL_REGIME_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/base';
import {
  EMISSIONS_ASSET_SUMMARY_ITEM,
  FIRST_PRODUCTION_ASSET_SUMMARY_ITEM,
  GAS_PRODUCTION_ASSET_SUMMARY_ITEM,
  HPHT_ASSET_SUMMARY_ITEM,
  LICENSE_EXPIRATION_ASSET_SUMMARY_ITEM,
  LIQUIDS_PRODUCTION_ASSET_SUMMARY_ITEM,
  NOTES_ASSET_SUMMARY_ITEM,
  OIL_PRICE_BREAKEVEN_SUMMARY_ITEM,
  OPERATORS_ASSET_SUMMARY_ITEM,
  PARTNERS_ASSET_SUMMARY_ITEM,
  PEARK_PRODUCTION_ASSET_SUMMARY_ITEM,
  PRIMARY_HYDROCARBON_ASSET_SUMMARY_ITEM,
  PV_10_ASSET_SUMMARY_ITEM,
  PV_REMAINING_CAPEX_ASSET_SUMMARY_ITEM,
  REMAINING_GAS_RESERVES_ASSET_SUMMARY_ITEM,
  REMAINING_LIQUIDS_RESERVES_ASSET_SUMMARY_ITEM,
  WELL_EURS_ASSET_SUMMARY_ITEM,
  WELL_RATES_ASSET_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/asset';

export const PROJECT_KPI_LABEL = 'PROJECT KPIs';

export default {
  'Key Information': {
    COUNTRY_SUMMARY_ITEM,
    OPERATORS_ASSET_SUMMARY_ITEM,
    PARTNERS_ASSET_SUMMARY_ITEM,
    BASIN_SUMMARY_ITEM,
    PRIMARY_HYDROCARBON_ASSET_SUMMARY_ITEM,
    HPHT_ASSET_SUMMARY_ITEM,
    SHORE_STATUS_SUMMARY_ITEM,
    DEVELOPMENT_STATUS_SUMMARY_ITEM,
    DEVELOPMENT_TYPE_SUMMARY_ITEM,
  },
  Timeline: {
    DISCOVERY_DATE_SUMMARY_ITEM,
    FIRST_PRODUCTION_ASSET_SUMMARY_ITEM,
    PEARK_PRODUCTION_ASSET_SUMMARY_ITEM,
    LICENSE_EXPIRATION_ASSET_SUMMARY_ITEM,
  },
  'Production & Reserves': {
    LIQUIDS_PRODUCTION_ASSET_SUMMARY_ITEM,
    GAS_PRODUCTION_ASSET_SUMMARY_ITEM,
    EMISSIONS_ASSET_SUMMARY_ITEM,
    REMAINING_LIQUIDS_RESERVES_ASSET_SUMMARY_ITEM,
    REMAINING_GAS_RESERVES_ASSET_SUMMARY_ITEM,
    WELL_EURS_ASSET_SUMMARY_ITEM,
    WELL_RATES_ASSET_SUMMARY_ITEM,
    NOTES_ASSET_SUMMARY_ITEM,
  },
  'Financial Considerations': {
    PV_10_ASSET_SUMMARY_ITEM,
    PV_REMAINING_CAPEX_ASSET_SUMMARY_ITEM,
    FISCAL_REGIME_SUMMARY_ITEM,
    subColumns: {
      [PROJECT_KPI_LABEL]: {
        OIL_PRICE_BREAKEVEN_SUMMARY_ITEM,
      },
    },
  },
};
