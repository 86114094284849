import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    wrapperAutoCompleteExcelLike: {
      width: '100%',
      height: '36px',
      padding: '8px',
      lineHeight: 1,
      fontSize: theme.fontSizes.smaller2,
    },
    box: {
      position: 'relative',
      width: '100%',
      height: '100%',
      boxShadow:
        '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      textAlign: 'left',
      border: 'none',
      padding: '0',
      fontSize: theme.fontSizes.smaller2,

      '&:hover': {
        cursor: 'pointer',
      },

      '&:disabled': {
        cursor: 'auto',
        backgroundColor: theme.palette.primaryBackground.disabled,
      },
    },
    boxOpen: {
      backgroundColor: theme.palette.baseColors.white.c100,
      border: '1px solid ' + theme.palette.baseColors.deepSeaNavy.c100,
      boxShadow: `0 1px 4px 0px ${theme.palette.baseColors.deepSeaNavy.c100}, 0 3px 1px -2px ${theme.palette.baseColors.deepSeaNavy.c100}, 0 2px 2px 0 ${theme.palette.baseColors.deepSeaNavy.c100}`,
    },
    label: {
      flexShrink: 1,
      flexGrow: 1,
      fontFamily: theme.fonts.base,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      paddingLeft: '12px',
      color: theme.palette.text.primary,

      '&[disabled]': {
        color: `${theme.palette.text.disabled}`,
      },
    },
    rightPart: {
      display: 'flex',
      flexShrink: 0,
      flexGrow: 0,
      alignItems: 'center',
      paddingRight: '8px',
      paddingLeft: '8px',
    },
    arrowOpen: {
      transform: 'rotate(180deg)',
    },
    popper: {
      minWidth: '400px',
    },
    popperAlignment: {
      '& > .MuiPaper-root': {
        transform: 'translateX(-8px) !important',
      },
    },
    chosen: {
      backgroundColor: '#2A2E49',
      color: 'white',
      borderRadius: '25px',
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: theme.fonts.base,
      fontSize: theme.fontSizes.smallest,
    },
    labelText: {
      display: 'inline-block',
    },
  };
});
