import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Modal from '../Modals';
import PortfolioItem from './portfolioItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useFilterSetsContext } from '../FilterBarWrapper';
import { CloseButtons } from '../Modal/CloseButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  addPortfolio: {
    padding: '10px 0 10px 30px',
  },
  addPortfolioButton: {
    ...theme.palette.actionCustom,
    marginRight: '10px',
    cursor: 'pointer',
  },
  apply: {
    ...theme.palette.button,
    height: '100%',
    width: '128px',
  },
  button: {
    ...theme.palette.button,
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  buttonsWrapper: {
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    padding: '10px 20px',
    borderTop: 'solid rgba(0, 0, 0, 0.12) thin',
  },
  clear: {
    ...theme.palette.altButton,
  },
  filtersContainer: {
    ...theme.palette.altScrollbarBlue,
    height: 'calc(100% - 1px)',
    overflowY: 'scroll',
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
  portfoliosRoot: {
    width: '100%',
    height: 'calc(100% - 100px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 76px)',
    },
  },
  summary: {
    justifyContent: 'space-around',
  },
}));

export default ({
  addPortfolio,
  apply,
  basicFilterTypes,
  checkListItems,
  clearPortfolios,
  deletePortfolio,
  context,
  googleAnalyticsTag,
  isModalOpen = false,
  defaultPortfolios,
  open,
  setOpen,
}) => {
  const classes = useStyles();
  const [portfolios, setPortfolios] = useState(defaultPortfolios);
  const { setCurrentFilterSetIndex } = useFilterSetsContext();

  const applyLocal = useCallback(() => {
    apply(portfolios);
    setOpen(false);
    setCurrentFilterSetIndex(0);
  }, [portfolios, apply, setOpen, setCurrentFilterSetIndex]);

  const applyBasicFilters = (filters, idx) => {
    const newPortfolio = {
      ...portfolios[idx],
      filters,
    };
    const newPortfolios = [...portfolios];
    newPortfolios[idx] = newPortfolio;
    setPortfolios(newPortfolios);
  };

  useEffect(() => {
    if (isModalOpen !== open) {
      setOpen(isModalOpen);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setPortfolios(defaultPortfolios);
  }, [defaultPortfolios]);

  return (
    <>
      <Modal
        handleClose={() => setOpen(false)}
        open={open}
        title="Portfolio"
        titleAlignedWithClose={true}
        styles={classes}
      >
        <div className={classes.portfoliosRoot}>
          <Divider />
          <div className={classes.filtersContainer}>
            <div className={classes.topFilters}>
              {portfolios.map((p, idx) => (
                <PortfolioItem
                  key={`${context}-${p.name}-${idx}-basic-filters`}
                  applyBasicFilters={applyBasicFilters}
                  basicFilterTypes={basicFilterTypes}
                  checkListItems={checkListItems}
                  context={context}
                  deletePortfolio={() => deletePortfolio(portfolios, idx)}
                  googleAnalyticsTag={googleAnalyticsTag}
                  idx={idx}
                  portfolio={p}
                  removeable={portfolios.length > 1}
                  inModal={true}
                />
              ))}
            </div>
            {portfolios.length < 5 && (
              <Typography className={classes.addPortfolio}>
                <IconButton
                  aria-label="add portfolio"
                  edge="start"
                  className={classes.addPortfolioButton}
                  onClick={() => addPortfolio(portfolios)}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
                Add Portfolio
              </Typography>
            )}
          </div>
        </div>
        <CloseButtons apply={applyLocal} clear={clearPortfolios} />
      </Modal>
    </>
  );
};
