import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid2';
import Loading from '../Loading';
import { Slider } from '../basic/Slider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: { width: '95%', padding: '10px 0' },
  slider: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    '& .MuiSlider-valueLabel': {
      '& > *': {
        '& > *': {
          color: theme.palette.baseColors.offWhite.c100,
        },
      },
    },
  },
}));

export default ({ item, loading, onChange, maxValue, value }) => {
  const classes = useStyles();

  const displayedMarks = useMemo(() => {
    const { marks } = item;
    if (Array.isArray(marks)) {
      // update the last mark with the given max value
      marks[marks.length - 1] = {
        value: maxValue,
        label: `${new Intl.NumberFormat('en-US').format(maxValue)}`,
      };
    }

    return marks || [];
  }, [item, maxValue]);

  const onSliderChange = (e, value) => {
    // if the slider is at the min and max value,
    // we can set the value to an empty array
    // this accounts for null values in the database
    const min = displayedMarks[0].value;
    if (value[0] === min && value[1] === maxValue) {
      onChange([], item.key);
    } else {
      onChange(value, item.key);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Grid className={classes.root} container>
      <Slider
        className={classes.slider}
        label={item.label}
        value={
          value?.length !== 2
            ? [displayedMarks[0].value, displayedMarks[displayedMarks.length - 1].value]
            : value
        }
        onChangeCommitted={onSliderChange}
        valueLabelDisplay="auto"
        marks={displayedMarks}
        max={maxValue}
      />
    </Grid>
  );
};
