import {
  OPPORTUNITIES_AGGREGATE_BY_TITLE,
  OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM,
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM,
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_OPTIONS,
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM,
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM,
  OPPORTUNITIES_AGGREGATE_FIELDS_ENUM,
  OPPORTUNITIES_AGGREGATE_GRAPH_BY_OPTIONS,
} from '../../types';
import React, { useEffect, useMemo, useState } from 'react';

import { MaGraph } from '../../../MaGraph';
import { getGraphLayout } from '../../../../helpers';
import { titleize } from '../../../../../../utils/helpers';
import { useGetChartData } from './hooks/useGetChartData';

const SESSION_STORAGE_KEY = 'MaOverviewOpportunitiesChart';
const getInitialChartSettings = () => {
  const sessionSettings = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (sessionSettings) {
    return JSON.parse(sessionSettings);
  }

  return {
    aggregateBy: OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
    compareTo: OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.RESERVES,
  };
};

export const OpportunitiesChart = () => {
  const { aggregateBy: defaultAggregateBy, compareTo: defaultCompareTo } =
    getInitialChartSettings();

  const [aggregateBy, setAggregateBy] = useState(defaultAggregateBy);
  const [compareTo, setCompareTo] = useState(defaultCompareTo);
  const fields = useMemo(
    () => [
      OPPORTUNITIES_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM[compareTo],
      OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.VALUATIONS,
    ],
    [compareTo],
  );

  useEffect(() => {
    // Save the current state to sessionStorage
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({ aggregateBy, compareTo }));
  }, [aggregateBy, compareTo]);

  const xConfig = {
    value: aggregateBy,
    setValue: setAggregateBy,
    options: OPPORTUNITIES_AGGREGATE_GRAPH_BY_OPTIONS,
  };
  const yConfig = {
    value: compareTo,
    setValue: setCompareTo,
    options: OPPORTUNITIES_AGGREGATE_COMPARE_TO_OPTIONS,
  };

  const yAxisTitle = OPPORTUNITIES_AGGREGATE_BY_TITLE;
  const altYAxisTitle = OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM[compareTo];
  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: yAxisTitle,
    altYAxisTitle: altYAxisTitle,
  });

  const { data, loading, error } = useGetChartData({
    aggregateBy,
    compareTo,
    fields,
  });

  if (error) return null;

  return (
    <MaGraph
      data={data}
      title={`NPV10 Valuation by ${titleize(aggregateBy)}`}
      loading={loading}
      xConfig={xConfig}
      yConfig={yConfig}
      graphLayout={graphLayout}
      maxXAxisTicks={aggregateBy === OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.YEAR ? 0 : 50}
    />
  );
};
