import {
  BASINS_WITH_FILTER_QUERY,
  COMPANIES_WITH_FILTER_QUERY,
  COUNTRIES_WITH_FILTER_QUERY,
} from '../../../../components/Filters/operations';
import { FilterKeys, FilterLabels } from '../../../../components/Filters';
import { MA_ASSETS_BY_NAME_WITH_FILTER_QUERY, MA_EVENT_TYPES_QUERY } from '../../operations';

import { ASSETS as BASIC_ASSETS } from '../../../../components/Filters/filterTypes';
import { M_A_SUBSCRIPTION } from '../../../../utils/constants';

export const GOOGLE_ANALYTICS_TAG = 'MA_Analytics';

export const MaFilterKeys = {
  valuation: 'valuation',
  reserves: 'reserves',
  production: 'production',
  emissions: 'emissions',
  dates: FilterKeys.dates,
  includeNegativeValuations: 'includeNegativeValuations',
};

export const MaFilterMaxKeys = {
  valuation: 'maxValuation',
  reserves: 'maxReserves',
  production: 'maxProduction',
  emissions: 'maxEmissions',
};

export const MaFilterLabels = {
  valuation: 'NPV10 (US$MM)',
  reserves: 'Reserves (MMboe)',
  production: 'Production (boe/d)',
  emissions: 'Emissions (kgCO2e/boe)',
  dates: 'EFFECTIVE DATE (YEARS)',
  includeNegativeValuations: 'Include Negative Valuations',
};

const getMaPropVariables = (filters) => ({
  filters: {
    countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
    eventTypes: filters[FilterKeys.eventType]?.map((e) => e.id) || [],
    buyers: filters[FilterKeys.companiesBuyer]?.map((c) => Number(c.id)) || [],
    sellers: filters[FilterKeys.companiesSeller]?.map((c) => Number(c.id)) || [],
    startYear: filters[MaFilterKeys.dates]?.[0] || null,
    endYear: filters[MaFilterKeys.dates]?.[1] || null,
  },
});

const getBasicAssetsPropVariables = (filters) => ({
  countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
  companies: filters[FilterKeys.companiesSeller]?.map((c) => c.name) || [],
  basins: filters[FilterKeys.basins]?.map((b) => b.name) || [],
  operator: false,
  includeHistoricalParticipants: false,
  isMAndAOpportunity: true,
  feature: M_A_SUBSCRIPTION,
});

const getOpportunitiesCompaniesPropVariables = (filters) => ({
  assets: [],
  countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
  companies: filters[FilterKeys.companiesSeller]?.map((c) => c.name) || [],
  basins: filters[FilterKeys.basins]?.map((b) => b.name) || [],
  operator: false,
  includeHistoricalParticipants: false,
  activeInBlocks: false, // we are only fetching assets M&A opportunities
  activeInLeases: false,
  isMAndAOpportunity: true,
  feature: M_A_SUBSCRIPTION,
  maBuyers: false,
  maSellers: false,
});

const getCompaniesPropVariables = (filters) => ({
  assets: [],
  countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
  basins: filters[FilterKeys.basins]?.map((b) => b.name) || [], // the query expects an array of basin names instead of ids
  activeInBlocks: true,
  activeInLeases: true,
  includeHistoricalParticipants: true,
  operator: false,
  isMAndAOpportunity: false,
});

const getCountriesPropVariables = (filters) => {
  // merge buyer and seller companies into one array
  const companiesList = filters[FilterKeys.companiesBuyer]
    ?.map((c) => c.name)
    .concat(filters[FilterKeys.companiesSeller]?.map((c) => c.name));

  return {
    companies: [...new Set(companiesList)] || [], // remove duplicates
    assets: [],
    basins: filters[FilterKeys.basins]?.map((b) => b.name) || [], // the query expects an array of basin names instead of ids
    operator: false,
    hasAssets: true,
    hasBlocks: true,
    hasLeases: true,
    hasGhgEmissions: false,
    hasMaTransactions: true,
    includeHistoricalParticipants: true,
    feature: M_A_SUBSCRIPTION,
  };
};

const getBasinsPropVariables = (filters) => ({
  countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
  // merge buyer and seller companies into one array
  companies:
    filters[FilterKeys.companiesBuyer]?.map((c) => c.name) +
      filters[FilterKeys.companiesSeller]?.map((c) => c.name) || [],
  assets: [],
  operator: false,
  hasMaTransactions: true,
  isMAndAOpportunity: false,
  feature: M_A_SUBSCRIPTION,
});

const SELLER_COMPANIES = {
  key: FilterKeys.companiesSeller,
  multiple: true,
  name: FilterLabels.companiesSeller,
  optionKey: 'name',
  propVariables: (filters) => ({
    ...getCompaniesPropVariables(filters),
    maBuyers: false,
    maSellers: true,
  }),
  query: COMPANIES_WITH_FILTER_QUERY,
  runQueryAfterOnChange: false,
};

const BUYER_COMPANIES = {
  key: FilterKeys.companiesBuyer,
  multiple: true,
  name: FilterLabels.companiesBuyer,
  optionKey: 'name',
  propVariables: (filters) => ({
    ...getCompaniesPropVariables(filters),
    maBuyers: true,
    maSellers: false,
  }),
  query: COMPANIES_WITH_FILTER_QUERY,
  runQueryAfterOnChange: false,
};

const EVENT_TYPES = {
  excelLike: true,

  groupByName: 'Type',
  groupOptionKey: 'group',
  key: FilterKeys.eventType,
  mapQueryData: (data) => data?.maDealTypes,
  multiple: true,
  name: FilterLabels.eventType,
  optionKey: 'name',
  propVariables: getMaPropVariables,
  query: MA_EVENT_TYPES_QUERY,
  runQueryAfterOnChange: false,
  groupSorter: (a, b) => {
    // The 'Transactions' group should always be first
    // The 'Other' group should always be last
    // All other groups should be sorted alphabetically
    const order = { Transactions: -1, Other: 1 };
    const getOrder = (name) => order[name] ?? 0;

    const aName = a?.group?.displayName || '';
    const bName = b?.group?.displayName || '';

    const diff = getOrder(aName) - getOrder(bName);
    return diff !== 0 ? diff : aName.localeCompare(bName);
  },
};

const ASSETS = {
  key: FilterKeys.assets,
  multiple: true,
  name: FilterLabels.assets,
  optionKey: 'menuName',
  propVariables: getMaPropVariables,
  query: MA_ASSETS_BY_NAME_WITH_FILTER_QUERY,
  queryVariable: 'search',
  runQueryAfterOnChange: true,
  responseCallback: (res) => res?.assets || [],
};

export const BASINS = {
  name: FilterLabels.basins,
  key: FilterKeys.basins,
  query: BASINS_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'name',
  multiple: true,
  propVariables: getBasinsPropVariables,
  queryVariable: 'search',
  runQueryAfterOnChange: true,
  responseCallback: (res) => res?.basins || [],
};

const COUNTRIES = {
  excelLike: true,
  groupByName: 'Region',
  groupOptionKey: 'region',
  key: FilterKeys.countries,
  mapQueryData: (data) => data?.countriesWithFilter,
  multiple: true,
  name: FilterLabels.countries,
  optionKey: 'displayName',
  propVariables: getCountriesPropVariables,
  query: COUNTRIES_WITH_FILTER_QUERY,
  queryVariable: null,
  runQueryAfterOnChange: false,
};

export const EFFECTIVE_DATE = {
  type: 'slider',
  key: FilterKeys.dates,
  name: FilterLabels.effectiveDate,
  getAriaLabel: () => FilterLabels.effectiveDate,
  step: 1,
};

export const MA_BASE_FILTER_TYPES = [
  COUNTRIES,
  EVENT_TYPES,
  BUYER_COMPANIES,
  SELLER_COMPANIES,
  ASSETS,
  BASINS,
];

// In the Opportunities tab, the filter types are slightly different,
// specially the queries use to fetch the data.
export const OPPORTUNITIES_FILTER_TYPES = [
  {
    ...COUNTRIES,
    propVariables: (filters) => ({
      ...getCountriesPropVariables(filters),
      hasMaTransactions: false,
    }),
  },
  { ...EVENT_TYPES, disabled: () => true },
  { ...BUYER_COMPANIES, disabled: () => true },
  {
    ...SELLER_COMPANIES, // Companies
    propVariables: getOpportunitiesCompaniesPropVariables,
  },
  {
    ...BASIC_ASSETS,
    propVariables: getBasicAssetsPropVariables,
  },
  {
    ...BASINS,
    propVariables: (filters) => ({
      ...getBasinsPropVariables(filters),
      isMAndAOpportunity: true,
      hasMaTransactions: false,
    }),
  },
];
