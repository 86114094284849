import {
  COUNTRY_AGGREGATE,
  EMISSION_SOURCE_TYPES,
  LINE_GRAPH_AGGREGATES,
  LINE_GRAPH_TYPES,
  TOTAL_EMISSIONS,
  graphTitles,
} from '../utils';
import { DEFAULT_TRACE_LIMIT, TOP_SORT_DIRECTION } from '../../../../components/Graphs/constants';
import React, { createContext, useEffect, useState } from 'react';

import { GHG_GOOGLE_ANALYTICS_TAG } from '../../constants';
import { GRAPH_ANALYTICS } from '../operations';
import GraphControls from '../../../../components/Graphs/GraphControls';
import { cloneDeep } from 'lodash';
import { downloadXlsx } from '../../../../components/Downloads/helpers';
import { shouldUpdateLegend } from '../../../../components/Portfolio/Charts/utils';
import { useQuery } from '@apollo/client';

const LineGraphContextLeft = createContext();
let useDefaultRange = true;

export const LineGraphProviderLeft = ({ children, portfolios }) => {
  const [openControlsModal, setOpenControlsModal] = useState(false);

  const [aggregate, setAggregate] = useState(COUNTRY_AGGREGATE.name);
  const [stacked, setStacked] = useState(true);
  const [graphType, setGraphType] = useState(TOTAL_EMISSIONS);
  const [discountRate, setDiscountRate] = useState(10);
  const [emissionsSource, setEmissionsSource] = useState([...EMISSION_SOURCE_TYPES]);
  const [limit, setLimit] = useState(DEFAULT_TRACE_LIMIT);
  const [sortDirection, setSortDirection] = useState(TOP_SORT_DIRECTION);
  const [legendItems, setLegendItems] = useState([]);
  const [range, setRange] = useState({ x: [], y: [] });
  const [modalRange, setModalRange] = useState({ x: [], y: [] });
  const [trace, setTrace] = useState([]);

  const resetRange = () => {
    setRange({ x: [], y: [] });
    setModalRange({ x: [], y: [] });
  };

  const setDefaultRange = () => {
    setRange({ x: [2012, 2050], y: [] });
    useDefaultRange = false;
  };

  const onRelayout = (e) => {
    const x = [e['xaxis.range[0]'], e['xaxis.range[1]']];
    const y = [e['yaxis.range[0]'], e['yaxis.range[1]']];
    setRange({ x, y });
  };

  const onGraphUpdate = (figure, graphDiv) => {
    const { shouldUpdate, newLegend } = shouldUpdateLegend(legendItems, graphDiv);
    if (shouldUpdate) {
      setLegendItems(newLegend);
    }
    if (
      (range.x[0] !== modalRange.x[0] && range.x[1] !== modalRange.x[1]) ||
      (range.y[0] !== modalRange.y[0] && range.y[1] !== modalRange.y[1])
    ) {
      setModalRange(range);
    }
  };

  const onInitialized = (figure, graphDiv) => {
    if (range.x.length === 0) {
      setDefaultRange();
    }
    onGraphUpdate(figure, graphDiv);
  };

  const onAfterPlot = () => {
    if (useDefaultRange) {
      setDefaultRange();
    }
  };

  // Currently used for all line charts since we limit the plotly legend to only 5 items
  // When we double click a trace in the legend the expected behavior is to hide all other traces
  // and only show the trace that was double clicked
  // The problem is since we only show 5 items, the default function only hides the other 4 traces
  // This function is used to toggle all the other traces
  const onLegendDoubleClick = (data) => {
    // get the index of the trace that was double clicked
    const clickedIdx = data.curveNumber;
    // if the trace that was double clicked is not visible then we want to show all
    // if the trace that was double clicked is visible then
    // a: hide all others if any others are visible
    // b: show all if all others are hidden
    const clickedTraceVisible = data.fullData[clickedIdx].visible;
    const hideAll =
      clickedTraceVisible === true &&
      !!data.fullData.find((t, idx) => clickedIdx !== idx && t.visible === true);
    // toggle the traces
    const newTrace = trace.map((t, idx) =>
      hideAll && clickedIdx !== idx ? { ...t, visible: 'legendonly' } : { ...t, visible: true },
    );
    setTrace(newTrace);
    if (hideAll) {
      setRange({ x: range.x, y: [0, trace[clickedIdx].y_max] });
      setModalRange({ x: range.x, y: [0, trace[clickedIdx].y_max] });
    } else {
      setDefaultRange();
    }
  };

  const { data, loading } = useQuery(GRAPH_ANALYTICS, {
    variables: {
      aggregate,
      graphType: graphType.value,
      hydrocarbonType: 'All',
      discountRate,
      emissionsSource,
      portfolios,
      stacked: stacked && !graphType.disableStackedChart,
      limit: parseInt(limit),
      sortDirection: sortDirection.value,
    },
  });

  useEffect(() => {
    resetRange();
    // Only use the default range if graph type does not dictate a default range
    if (!graphType.defaultRange) {
      useDefaultRange = true;
    }
    if (data?.graphAnalytics) {
      let analytics = data.graphAnalytics.analytics || [];
      // Workaround for bug where the chart will not display if the marker color is set:
      setTrace(cloneDeep(analytics));
    }
  }, [data]);

  useEffect(() => {
    // Check if graph type has a default range and sets it
    if (graphType.defaultRange) {
      setRange(cloneDeep(graphType.defaultRange));
      setModalRange(cloneDeep(graphType.defaultRange));
    }
  }, [graphType]);

  const toggleTrace = (traceName) => {
    const idx = trace.findIndex((t) => t.text === traceName);
    const newTrace = [...trace];
    const toggledTrace = { ...trace[idx] };
    toggledTrace.visible = toggledTrace.visible === true ? 'legendonly' : true;
    newTrace[idx] = toggledTrace;
    setTrace(newTrace);
  };

  const toggleLegendItem = (traceName) => {
    const idx = legendItems.findIndex((i) => i.title === traceName);
    const newLegend = [...legendItems];
    const toggledItem = { ...legendItems[idx] };
    toggledItem.visible = !toggledItem.visible;
    newLegend[idx] = toggledItem;
    setLegendItems(newLegend);
  };

  const handleTraceToggle = (traceName) => {
    toggleTrace(traceName);
    toggleLegendItem(traceName);
  };

  const downloadCallback = (errorHandler, successHandler, dateType) => {
    const path = 'portfolio-analysis/export-asset-line-graph-data/';
    const body = {
      aggregate,
      graphType: graphType.value,
      hydrocarbonType: 'All',
      discountRate,
      emissionsSource,
      portfolios,
      limit,
      dateType,
      sortDirection: sortDirection.value,
    };
    const downloadName = `${portfolios[0].name}_Asset_Data_by_${aggregate}.xlsx`;

    downloadXlsx(path, body, downloadName, errorHandler, successHandler);
  };

  const title = `${graphType.label} ${graphTitles[graphType.value]}`;

  return (
    <LineGraphContextLeft.Provider
      value={{
        downloadCallback,
        handleTraceToggle,
        legendItems,
        loading,
        modalRange,
        onAfterPlot,
        onGraphUpdate,
        onInitialized,
        onLegendDoubleClick,
        onRelayout,
        range,
        setModalRange,
        setOpenControlsModal,
        setStacked,
        stacked,
        title,
        trace,
        discountRate,
        setDiscountRate,
        maxY: data?.graphAnalytics?.maxY || [],
        googleAnalyticsTag: GHG_GOOGLE_ANALYTICS_TAG,
        graphType: graphType,
      }}
    >
      {children}
      <GraphControls
        aggregate={aggregate}
        aggregateTypes={LINE_GRAPH_AGGREGATES}
        discountRate={discountRate}
        googleAnalyticsTag={GHG_GOOGLE_ANALYTICS_TAG}
        graphType={graphType}
        emissionSourceTypes={EMISSION_SOURCE_TYPES}
        emissionsSource={emissionsSource}
        limit={limit}
        open={openControlsModal}
        setAggregate={setAggregate}
        setDiscountRate={setDiscountRate}
        setGraphType={setGraphType}
        setEmissionsSource={setEmissionsSource}
        setLimit={setLimit}
        setOpen={setOpenControlsModal}
        setSortDirection={setSortDirection}
        sortDirection={sortDirection}
        types={LINE_GRAPH_TYPES}
      />
    </LineGraphContextLeft.Provider>
  );
};

export const LineGraphConsumerLeft = LineGraphContextLeft.Consumer;

export default LineGraphContextLeft;
