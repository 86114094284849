import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import { NO_TRACE_LIMIT, TOP_SORT_DIRECTION } from '../constants';
import React, { useEffect, useState } from 'react';

import Adjustments from './adjustments';
import Aggregates from './aggregates';
import Button from '@mui/material/Button';
import Control from './control';
import DiscountRate from './discountRate';
import Divider from '@mui/material/Divider';
import EmissionsSource from './emissionsSource';
import Grid from '@mui/material/Grid2';
import Hydrocarbon from './hydrocarbon';
import Metrics from './metrics';
import Modal from '../../Modals';
import { defaultDimensions } from '../../../utils/analytics';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';

// this will remove any unnecessary keys from state
const createState = (state) => {
  const newState = {};
  Object.keys(state).forEach((key) => {
    if (state[key] !== undefined) {
      newState[key] = state[key];
    }
  });
  return newState;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 48px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 36px)',
    },
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
  button: {
    ...theme.palette.button,
    width: '128px',
  },
  controls: {
    ...theme.palette.altScrollbarBlue,
    height: '100%',
    overflow: 'auto',
    padding: '20px',
    backgroundColor: '#f4f4f4f4',
  },
  controlsContainer: {
    height: 'calc(100% - 54.8px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 48.8px)',
    },
  },
}));

export default ({
  aggregate,
  aggregateTypes,
  aggregateLayers,
  checkboxAggregates,
  compareTo,
  compareToOptions = [],
  adjustments,
  setAdjustments,
  adjustmentsOptions,
  discountRate,
  emissionSourceTypes,
  emissionsSource,
  googleAnalyticsTag,
  graphType,
  graphTypeChildren,
  graphTypeOptions = [],
  hydrocarbonType,
  includeOptions,
  limit,
  open,
  setAggregate,
  setCheckboxAggregates,
  setCompareTo,
  setDiscountRate,
  setEmissionsSource,
  setGraphType,
  setGraphTypeOptions = () => {},
  setHydrocarbonType,
  setLimit,
  setOpen,
  setSizeType,
  setSortDirection,
  setStackType,
  sizeType,
  sizeTypes,
  sortDirection,
  stackType,
  stackTypes,
  types,
  typesLabel = 'Select Metric',
}) => {
  const classes = useStyles();
  const [state, setState] = useState(
    createState({
      // any props that are undefined will not be included in state
      aggregate,
      checkboxAggregates,
      compareTo,
      discountRate,
      emissionsSource,
      graphType,
      graphTypeOptions,
      hydrocarbonType,
      limit,
      sizeType,
      sortDirection,
      stackType,
    }),
  );

  const handleSetAggregate = (
    aggregate,
    limit = NO_TRACE_LIMIT,
    sortDirection = TOP_SORT_DIRECTION,
  ) => {
    setState({ ...state, aggregate, limit, sortDirection });
  };

  const handleSetState = (value, name, layer) => {
    if (layer) {
      setState({ ...state, [name]: { ...state[name], [layer]: value } });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleApply = () => {
    const chartSettings = {
      aggregate: state.aggregate,
      graphType: state.graphType,
      graphTypeOptions: state.graphTypeOptions,
    };
    if (aggregate) {
      setAggregate(state.aggregate);
    }
    if (compareTo) {
      setCompareTo(state.compareTo);
    }

    if (adjustments && adjustmentsOptions?.length > 0) {
      const adjustmentValues = adjustmentsOptions.reduce(
        (acc, adjustment) => ({ ...acc, [adjustment.value]: state[adjustment.value] || false }),
        {},
      );
      setAdjustments(adjustmentValues);
    }

    setGraphType(state.graphType);
    if (graphTypeOptions) {
      setGraphTypeOptions(state.graphTypeOptions);
    }
    if (checkboxAggregates) {
      setCheckboxAggregates(state.checkboxAggregates);
    }
    if (discountRate || discountRate === 0) {
      chartSettings.discountRate = state.discountRate;
      setDiscountRate(state.discountRate);
    }
    if (emissionsSource) {
      setEmissionsSource(state.emissionsSource);
    }
    if (hydrocarbonType) {
      chartSettings.hydrocarbonType = state.hydrocarbonType;
      setHydrocarbonType(state.hydrocarbonType);
    }
    if (limit || limit === '') {
      chartSettings.limit = state.limit;
      setLimit(state.limit);
    }
    if (sizeType) {
      setSizeType(state.sizeType);
    }
    if (sortDirection) {
      chartSettings.sortDirection = state.sortDirection;
      setSortDirection(state.sortDirection);
    }
    if (stackType) {
      setStackType(state.stackType);
    }
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.chartSettings,
      event_label: JSON.stringify(chartSettings),
      userId: getUserId(),
      ...defaultDimensions,
    });

    setOpen(false);
  };

  // we need to sync discount rate with other component while keeping this component's state
  useEffect(() => {
    handleSetState(discountRate, 'discountRate');
  }, [discountRate]);

  return (
    <Modal
      handleClose={() => setOpen(false)}
      open={open}
      title="Chart Settings"
      titleAlignedWithClose={true}
      styles={classes}
      keepMounted
    >
      <Grid container className={classes.root} direction="column">
        <Divider />
        <Grid className={classes.controlsContainer} container direction="row">
          <Grid style={{ padding: '10px 20px' }} container size={6}>
            <Metrics
              label={typesLabel}
              graphTypes={types}
              graphTypeChildren={graphTypeChildren}
              handleSetState={handleSetState}
              includeOptions={includeOptions}
              itemIsDisabledParams={{
                aggregate: state.aggregate,
                stackType: state.stackType,
                checkboxAggregates: state.checkboxAggregates,
                sizeType: state.sizeType,
              }}
              selectedAggregate={state.aggregate}
              selectedGraphType={state.graphType}
              selectedGraphTypeOptions={state.graphTypeOptions}
            />
          </Grid>
          <Grid className={classes.controls} container size={6}>
            <Control
              accessor="sizeType"
              handleSetState={handleSetState}
              itemIsDisabledParams={{ graphType: state.graphType }}
              label="Size"
              type={sizeType}
              types={sizeTypes}
              value={state.sizeType}
            />
            <EmissionsSource
              graphType={state.graphType}
              handleSetState={handleSetState}
              emissionSourceTypes={emissionSourceTypes}
              itemIsDisabledParams={{
                aggregate: state.aggregate,
              }}
              value={state.emissionsSource}
            />
            <DiscountRate
              discountRate={discountRate}
              graphType={state.graphType.label}
              handleSetState={handleSetState}
              value={state.discountRate}
            />
            <Hydrocarbon
              handleSetState={handleSetState}
              hydrocarbonType={hydrocarbonType}
              selectedCheckboxAggregates={state.checkboxAggregates}
              value={state.hydrocarbonType}
            />
            <Aggregates
              aggregateLayers={aggregateLayers}
              aggregateTypes={aggregateTypes}
              handleSetAggregate={handleSetAggregate}
              handleSetState={handleSetState}
              itemIsDisabledParams={{
                checkboxAggregates: state.checkboxAggregates,
                emissionsSource: state.emissionsSource,
                graphType: state.graphType,
                graphTypeOptions: state.graphTypeOptions,
                hydrocarbonType: state.hydrocarbonType,
                stackType: state.stackType,
              }}
              selectedAggregate={state.aggregate}
              selectedCheckboxAggregates={state.checkboxAggregates}
              selectedSortDirection={state.sortDirection}
            />
            <Control
              accessor="stackType"
              handleSetState={handleSetState}
              itemIsDisabledParams={{ aggregate: state.aggregate, graphType: state.graphType }}
              label="Stack Data"
              type={stackType}
              types={stackTypes}
              value={state.stackType}
            />
            <Control
              accessor="compareTo"
              handleSetState={handleSetState}
              itemIsDisabledParams={{ graphType: state.graphType }}
              label="Secondary Y Axis"
              type={compareTo}
              types={compareToOptions}
              value={state.compareTo}
            />
            <Adjustments
              values={adjustments}
              adjustments={adjustmentsOptions}
              handleSetState={handleSetState}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid
          style={{ padding: '14px' }}
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button variant="contained" className={classes.button} onClick={() => handleApply()}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
