import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ApolloProvider } from '@apollo/client';
import { AppRoutes } from '../utils/routes';
import { CurrentUserProvider } from '../contexts/CurrentUserContext';
import { FeatureFlagsProvider } from '../contexts/FeatureFlagsContext';
import Footer from '../components/Footer';
import { OAuth2Provider } from '../contexts/OAuth2Context';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from '../contexts/SnackbarContext';
import { WalkthroughProvider } from '../contexts/WalkthroughContext';
import { createClient } from '../utils/apollo';
import { render } from 'react-dom';
import theme from '../utils/theme';

const App = () => {
  return (
    <ApolloProvider client={createClient()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <SnackbarProvider>
              <OAuth2Provider>
                <CurrentUserProvider>
                  <FeatureFlagsProvider>
                    <WalkthroughProvider>
                      <AppRoutes />
                    </WalkthroughProvider>
                  </FeatureFlagsProvider>
                </CurrentUserProvider>
              </OAuth2Provider>
            </SnackbarProvider>
            <Footer />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
};

render(<App />, document.querySelector('#root'));
