import { MA_BASE_FILTER_TYPES, OPPORTUNITIES_FILTER_TYPES } from '../types';

import { useMaContext } from '../../../MaContext';
import { useMemo } from 'react';

export const useGetBasicFilters = () => {
  const { currentTab } = useMaContext();

  const basicFilters = useMemo(() => {
    return currentTab === 'opportunities' ? OPPORTUNITIES_FILTER_TYPES : MA_BASE_FILTER_TYPES;
  }, [currentTab]);

  return { basicFilters };
};
