import {
  ACREAGE_SUMMARY_ITEM,
  AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM,
  BLOCK_DISPLAY_NAME_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  FISCAL_REGIME_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
} from '../../../../components/SummaryList/SummaryItems/base';

export default [
  BLOCK_DISPLAY_NAME_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  ACREAGE_SUMMARY_ITEM,
  AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
  FISCAL_REGIME_SUMMARY_ITEM,
];
